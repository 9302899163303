import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CiEdit } from "react-icons/ci";
import { MdCalendarMonth } from "react-icons/md";
import { formatterDateFrench,formatHour } from "../../../../utils/Utils";
import moment from "moment";



function DetailHistoriqueModal({ show, setShow, historique }: { show: any; setShow: any, historique:any }) {
  const handleClose = () => setShow(false);
  const today = moment();
  const yesterday = moment().subtract(1, 'day');

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Détail de l’élément</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-body-modal-historique-admin mb-3">
          <p className="text-detail-historique-admin-modal">
            <span style={{ color: "#22BBC0", paddingRight: 5 }}>
              <CiEdit />
            </span>
            <span>Modification</span>
          </p>
          <p className="text-detail-historique-admin">
            
              {historique?.content}
              {historique?.medecin && (
                <>
                  <span style={{ fontWeight: 600, paddingRight: 5 }} >
                    Dr {historique?.medecin?.prenom} {historique?.medecin?.nom}
                  </span>
                  <span>et la patiente</span>
                </>
              )}
              <span style={{ fontWeight: 800, paddingRight: 5 }}>
                {" "+historique?.user?.prenom} {historique?.user?.nom}
              </span>
          </p>
        </div>
        <div className="container-body-modal-historique-admin mb-3">
          <p className="text-detail-historique-admin-modal">
            <span>Détails</span>
          </p>
          <p className="text-detail-historique-admin">
            <span>
              <MdCalendarMonth />
            </span>
            <span style={{ fontWeight: 600, paddingLeft: 5 }}>
            { moment(historique?.created_at).isSame(today , 'day') ?  
                  <span>Aujourd’hui</span>
                :(
                  moment(historique?.created_at).isSame( yesterday , 'day') ? 
                  <span>Hiere</span>
                  :
                  <span>{formatterDateFrench(historique?.created_at)}</span>
                ) }, {formatHour(historique?.created_at)}
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DetailHistoriqueModal;
