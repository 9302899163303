import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import {UseResetPasswordForm} from "./requestForm/UseResetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";

const ResetPassword = () => {
  const { register, errors, isLoading, onSubmit, passwordHandleChange } =
  UseResetPasswordForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4">
              <div className="content-logo-auth pb-2 pt-5">
              <NavLink
                  className="nav-link forget-password-link text-center"
                  to="/"
                >
                <img src={Logo} alt="logo" className="logo-auth-page" />
              </NavLink>
              </div>
              <h2 className="title-auth-form-text pt-5">Réinitialisation mot de passe</h2>
              <p  className="text-message-auth w-50 ms-auto me-auto">Créer un nouveau mot de passe</p>
              <div className="mt-5">
                <form id="auth-form" onSubmit={onSubmit}>
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <label className="form-label form-label-auth-login">
                          Adresse email
                        </label>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          aria-label="Username"
                          placeholder="Email"
                          {...register("email")}
                        />
                      </div>
                      <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <label className="form-label form-label-auth-login">
                          Code 
                        </label>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="code-or-username"
                          aria-label="Username"
                          placeholder="Code"
                          {...register("code")}
                        />
                      </div>
                      <ErrorMessage
                          message={errors?.code && errors?.code?.message}
                        />
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group position-relative">
                        <label className="form-label form-label-auth-login">
                          Nouveau mot de passe
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="new_password"
                          placeholder="Mot de passe"
                          aria-label="Password"
                          aria-placeholder="***********"
                          name="new_password"
                          onChange={passwordHandleChange}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.new_password && errors?.new_password?.message}
                      />
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group position-relative">
                        <label className="form-label form-label-auth-login">
                          Confirmer mot de passe
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="new_password_confirm"
                          placeholder="Mot de passe"
                          aria-label="Password"
                          aria-placeholder="***********"
                          {...register("new_password_confirm")}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.new_password_confirm && errors?.new_password_confirm?.message}
                      />
                    </div>
                    <div className="col-md-12 auth-submit-btn-container mt-5">
                      <button
                        className="btn auth-submit-btn trans-0-2"
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>En cours...</span>
                          </>
                        ) : (
                          "Envoyer"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
