/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { UserFormData , IUser , AdminFormData } from "../../../../utils/api/user/user.type";
import { useAddOrEditUserMutation , useArchiveUserMutation , useUnArchiveUserMutation, useDeleteUserMutation, useAddOrEditAdminMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";


function UseAddOrUpdateUserForm(user?: AdminFormData) {

  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Le prenom est obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    email: yup.string().email("L'email est invalide").required("L'email est obligatoire"),
    telephone: yup.string().required("Le téléphone est obligatoire"),
    nationnalite: yup.string().required("La nationnalité est obligatoire"),
   // user_type: yup.string().required("Le téléphone est obligatoire"),
    // specialite: yup.string().required("Le specialité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<AdminFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
  useAddOrEditAdminMutation();

  const handleChangeAvatar = (e) => { 
    let files = e.target.files[0];
    setValue("avatar" , files)
  }


  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Admin ${user ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/parametres')
      });
    }
    const err = error as any;

    if (isError) {

      console.log('isError',err)
    
      if (err?.data?.email) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else if (err?.data?.telephone) {
        Swal.fire({
          icon: "error",
          title: "l'utilisateur avec ce téléphone existe déjà.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (user?.slug) {
      let fields: (keyof AdminFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "specialite",
        "slug",
        "nom",
        "password",	
        "last_login",		
        "is_archive",	
        "longitude",	
        "latitude",
        "password_reset_count",	
        "user_type",	
        "civilite",	
        "first_connexion",
        "token",	
        "bio",	
        "hopital",	
        "maladie",	
        "delais",	
        "deletion_id",	
        "deletion_type",	
        "condition",	
        "specialite",
        "nationnalite",
        "boite_postal",
        "dashboard",
        "patients",
        "medecins",
        "rdvs",
        "paiements",
        "remboursements",
        "messages",
        "formats",
        "parametres",
      ];
      for (let field of fields) {
          register(field);
          setValue(field, user[field])
      }

    }
  }, [user]);

  const onSubmit = async (data: AdminFormData) => {

    data['user_type'] = 'admin'
    await addOrUpdateUser({ slug: user?.slug, data: data });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeAvatar
  };
}

export function DeleteUser({ item }: { item: IUser }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteUserMutation();
  const onDelete = useDelete<IUser>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet administrateur",
    successMessage: "Administrateur supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export function useToggleArchiveAdmin(user: IUser) {
  const [editData] = user?.is_archive ? useUnArchiveUserMutation() : useArchiveUserMutation();
  const onArchive = async () => {
    let data: UserFormData = {
      is_archive: !user.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet admin ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Admin ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}

export default UseAddOrUpdateUserForm;
