import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineCheckCircle, MdPhone } from "react-icons/md";
import TableSkeleton from '../../../common/TableSkeleton';
import { RdvAdminTableDatas } from './RdvAdminTableDatas';
import { NavLink } from "react-router-dom";
import { BsFillChatDotsFill } from 'react-icons/bs';
import { FaTrash } from "react-icons/fa";
import { AiOutlineClockCircle, AiFillEye } from 'react-icons/ai';
import { PiVideoCameraFill } from "react-icons/pi";
import { RxCrossCircled } from "react-icons/rx";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { DeleteRendezVous } from "../../RdvAdmin/requestForm/UseAddOrUpdateRendezVousForm"
import { formatDate, getName , formatTime } from "../../../../utils/Utils";
import moment from 'moment';
import './Table.css';
import { CustomPagination } from '../../../common/CustomPagination';



function RdvAdminTable({rdvs,isLoading,limit,page,onChange, count}) {
  const [pages, setPages] = useState(RdvAdminTableDatas)

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/rendez-vous/${row?.slug}`}
              className="btn btn-action-icon-see with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            {/* <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
            >
              <FaTrash />
            </button> */}
            <DeleteRendezVous item={row} />
          </div>
        </div>
      </>
    );
  };

  const patientFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          {getName(row?.patient)}
        </div>
      </div>
    );
  };
  const praticienFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          {getName(row?.medecin)}
        </div>
      </div>
    );
  };
  const heureFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          {formatTime(row?.heure_de_debut)+"-"+formatTime(row?.heure_de_fin) }
        </div>
      </div>
    );
  };

  const tarificationFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          { row?.format?.prix } FCFA
        </div>
      </div>
    );
  };

  const typeFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          { row?.format?.choix_consultation }
        </div>
      </div>
    );
  };

  const statutFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="statut-rdv-content">
          {!!cell && cell === "en_attente" && (
            <p className="d-flex align-items-center gap-2">
              <AiOutlineClockCircle style={{ color: '#D0775B', fontSize: 20 }} />
              <span>
                En attente <br /> 
                <em style={{ color: '#D0775B' }}>
                { moment(row?.date).isSame(moment().format('YYYY-MM-DD')) ? formatTime(row?.heure_de_debut) : "" }
                </em>
              </span>
            </p>
             )
          }
          {!!cell && cell === "terminer" && ( 
            <div className="d-flex align-items-center gap-2 programmed">
              <MdOutlineCheckCircle style={{ fontSize: 20 }} />
              Terminé
            </div>
            )
          }
          {!!cell && cell === "accepter" && ( 
            <div className="d-flex align-items-center gap-2 visited">
              <MdOutlineCheckCircle style={{ fontSize: 20 }} />
              Programmé
            </div>
             )
          }
          {!!cell && cell === "nouveau" && (
            <p className="d-flex align-items-center gap-2 nouveau">
              <IoAddCircleOutline style={{ color: '#22BBC0', fontSize: 20 }} />
              <span>
                Nouveau <br /> 
              </span>
            </p>
            )
          }
          {!!cell && cell === "annuler" && ( 
            <div className="d-flex align-items-center gap-2 annuler">
              <RxCrossCircled style={{ color: '#D0775B',fontSize: 20 }} />
              Annulé
            </div>
             )
          }
          {!!cell && cell === "rejeter" && ( 
            <div className="d-flex align-items-center gap-2 annuler">
              <RxCrossCircled style={{ color: '#D0775B',fontSize: 20 }} />
              Rejeté
            </div>
             )
          } 
        </div>
      </div>
    );
  };
  const formatFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="format-rdv-content">
          {!!row?.format && row?.format?.type_format === "audio" && (
            <MdPhone style={{ color: '#22BBC0', fontSize: 22 }} />)
          }
          {!!row?.format && row?.format?.type_format === "video" && (
            <PiVideoCameraFill style={{ color: '#22BBC0', fontSize: 22 }} />)
          }
          {!!row?.format && row?.format?.type_format === "texto" && (
            <BsFillChatDotsFill style={{ color: '#F76F39', fontSize: 22 }} />)
          }
          {!!row?.format && row?.format?.type_format === "domicile" && (
            <IoHomeOutline style={{ color: '#22BBC0', fontSize: 22 }} />)
          }
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: "Date du rdv",
      formatter: (cell: any, row: any) => formatDate(row?.date),
    },
    {
      dataField: "time",
      text: "Heure",
      formatter: (cell: any, row: any) => heureFormatter(cell, row), 
    },
    {
      dataField: "patient",
      text: "Patient",
      formatter: (cell: any, row: any) => patientFormatter(cell, row),
    },
    {
      dataField: "praticien",
      text: "Praticien",
      formatter: (cell: any, row: any) => praticienFormatter(cell, row),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: typeFormatter,
    },
    {
      dataField: "tarification",
      text: "Tarification",
      formatter: tarificationFormatter,
    },
    {
      dataField: "id",
      text: "Date création",
      formatter: (cell: any, row: any) => formatDate(row?.created_at),
    },
    {
      dataField: "format",
      text: "Format",
      formatter: formatFormatter,
    },
    {
      dataField: "status",
      text: "Statut",
      formatter: statutFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <>
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={rdvs || []}
              columns={columns}
              // selectRow={selectRow}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            
              <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={count}
                  page={page}
                  onChange={onChange}
                  perPage={limit}
              />
              </div>
          </>
        )}
      </>
    );
}

export default RdvAdminTable;