import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IFormat } from "./format.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  FormatFormData,
} from "./format.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const FormatApi = createApi({
  reducerPath: "format",
  tagTypes: [
    "format",
    "formatList",
    "formatBySlug",
    "allFormatsList"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    getAllFormatsList: builder.query<any, void>({
      providesTags: ["allFormatsList"],
      query: () => {
        return "formats/";
      },
    }),
    // get format pratricien 
      getFormat: builder.query<PaginationResults<IFormat>, TypeQuery>({
        query: (query) => QueryUrl(`formats/`, query),
        providesTags: ["format"],
      }),
      formatBySlug: builder.query<IFormat | any, string>({
        query: (slug) => `formats/${slug}/`,
        providesTags: ["formatBySlug"],
      }),
      addOrUpdateFormat: builder.mutation<IFormat, { slug?: string; data: IFormat | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `formats/${slug}/` : `formats/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["format","formatList"],
      }),
      deleteFormat: builder.mutation<FormatFormData, string>({
        query: (slug) => ({
          url: `formats/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["format","formatList"],
      }),


  }),
});

export const {
  useGetAllFormatsListQuery,
  useGetFormatQuery,
  useLazyFormatBySlugQuery,
  useAddOrUpdateFormatMutation,
  useDeleteFormatMutation,
} = FormatApi;
