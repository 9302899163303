/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  ICertificat,
  CertificatFormData
} from "./certificat.type";

export const CertificatApi = createApi({
  reducerPath: "certificatApi",
  tagTypes: ["certificatList", "certificatBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListCertificatsAdmin: builder.query<PaginationResults<ICertificat>, TypeQuery>({
      providesTags: ["certificatList"],
      query: (query) => QueryUrl("certificat_medical/", query),
    }),

    addOrEditCertificat: builder.mutation<ICertificat, { slug?: string; data: ICertificat | FormData }>({
      query: ({ slug, data }) => ({
        url: slug ? `certificat_medical/${slug}/` : `certificat_medical/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["certificatList"],
    }),
    deleteCertificat: builder.mutation<ICertificat, string>({
      query: (slug) => ({
        url: `certificat_medical/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["certificatList"],
    }),
    certificatBySlug: builder.query<ICertificat | any, string>({
      query: (slug) => `certificat_medical/${slug}/`,
      providesTags: ["certificatBySlug"],
    }),
  
  }),
});

export const {
  useAddOrEditCertificatMutation,
  useGetListCertificatsAdminQuery,
  useLazyCertificatBySlugQuery,
  useDeleteCertificatMutation,
} = CertificatApi;
