import React from 'react';
import { SnippetsItemData } from '../tables';
import { NavLink } from 'react-router-dom';
import AproposItem from '../items/AproposItem';
import { useStepType } from '../../../../utils/type';
import { useStep } from 'react-hooks-helper';
import {
  useGetListAproposAdminQuery,
} from "../../../../utils/api/apropos/apropos.api";
import { useDelete, usePagination, useSearch } from '../../../../utils/hooks';


const Apropos = () => {
  const { limit, page, onChange } = usePagination(10);

  const { data, isLoading } = useGetListAproposAdminQuery({
    page: page,
    limit: limit,
  });

  return (
    <div className="custom-tab-content">
      {data?.results?.length == 0 && (
        <div className="top-curved-container mb-1">
            <div className="col-md-2">
                <NavLink
                    className="btn btn-theme-admin"
                    to={"/admin/parametres/ajouter-apropos"}
                >
                  Ajouter
                </NavLink>
            </div>
        </div>
      )}
        <div className="bg-colgate">
          <div className="list-all-politiques">
          {!!data?.results &&
            data?.results.slice(0, 1).map((about, key) => {
              return(
                <div className="wrapper" key={key}>
                  <AproposItem 
                    about={about}
                    key={key}
                  />
                </div>
              )
            })
          }
          </div>
        </div>
    </div>
  )
}

export default Apropos