import React , {useState , useEffect} from "react";
import {
  useGetNotificationsAdminQuery,
} from "../../../../utils/api/notification/notification.api";
import { formatDateHour , isSuperAdmin , isAdmin , onShow } from "../../../../utils/Utils";
import ShowNotificationModal from "../modal/ShowNotificationModal";
import { useLazyNotificationsByUserQuery } from "../../../../utils/api/notification/notification.api";
import { useAppSelector } from "../../../../redux/hook";
import { INotification } from "../../../../utils/api/notification/notification.type";
import TableSkeleton from '../../../common/TableSkeleton';


function Notification(props) {
  // const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  const { data:notificationsAdmin , isLoading } = useGetNotificationsAdminQuery();
  const [modalNotification, setModalNotification] = useState(false);
  const [notification, setNotification] = useState<any>();
  const { user, token } = useAppSelector((state) => state.user);


  useEffect(() => {
    setNotifications(notificationsAdmin?.results?.filter((x)=>(x.admins)));
  }, []);
  

  function openModalNotification(item : any) {
    setNotification(item)
    onShow("ShowNotificationModal");
  }

  const columns = [
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "tarification",
      text: "Tarification",
    },
    {
      dataField: "format",
      text: "Format",
    },
    {
      dataField: "status",
      text: "Statut",
    },
    {
      dataField: "id",
      text: "Action",
    },
  ];

  return (
    <div className="custom-tab-content">
      <h4 className="custom-tab-title">
        Notifications
      </h4>
      <div className="list-group mt-4">
        {isLoading && <TableSkeleton headers={columns} />}
        {(!isLoading && notifications?.length > 0) ? ( 
        notifications?.map((data) => (
          <button
            type="button"
            onClick={(e) => openModalNotification(data)}
            className={`list-group-item list-group-item-action mb-2 ${
              data?.read === false ? "non lue" : "non-lue"
            }`}
            key={data?.id}
          >
            <div className="row">
              <div className="col-md-7">
                <div className="custom-list-group-item">
                  {/* <span>{data?.data?.prenom} {data?.data?.nom}</span> vient d’ajouter{" "}
                  <span>{data?.data?.user_type}</span> */}
                  {data?.content}
                </div>
              </div>
              <div className="col-md-3">
                <div className="list-group-item-heure pt-1">{formatDateHour(data?.data?.created_at)}</div>
              </div>
              <div className="col-md-2">
                {data?.read === false && (
                  <span className="list-group-item-badge">Non lue</span>
                )}
              </div>
            </div>
          </button>
        )))
        :
        <p>Vous avez pas de notifications </p>
      }
      </div>
      <ShowNotificationModal
          notification={notification}
        />
    </div>
  );
}

export default Notification;

const Datas = [
  {
    id: 1,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
  {
    id: 2,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
  {
    id: 3,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 4,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 5,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 6,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "lue",
  },
  {
    id: 7,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
  {
    id: 8,
    user: "Dr Ibrahima Ndiaye",
    type: "un rendez-vous",
    heure: "12:21",
    statut: "non lue",
  },
];
