import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { ISpecialite } from "./specialite.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  SpecialiteFormData,
} from "./specialite.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const SpecialiteApi = createApi({
  reducerPath: "specialite",
  tagTypes: [
    "allSpecialiteList",
    "specialite",
    "specialiteList",
    "specialiteBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get specialite pratricien 

      getAllSpecialiteList: builder.query<any, void>({
        providesTags: ["allSpecialiteList"],
        query: () => {
          return "specialites/";
        },
      }),
   
      getSpecialite: builder.query<PaginationResults<ISpecialite>, TypeQuery>({
        query: (query) => QueryUrl(`specialites/`, query),
        providesTags: ["specialiteList"],
      }),
      specialiteBySlug: builder.query<ISpecialite | any, string>({
        query: (slug) => `specialites/${slug}/`,
        providesTags: ["specialiteBySlug"],
      }),

      addOrUpdateSpecialite: builder.mutation<ISpecialite, { slug?: string; data: ISpecialite | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `specialites/${slug}/` : `specialites/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["specialite","allSpecialiteList","specialiteList","specialiteList"],
      }),
      deleteSpecialite: builder.mutation<SpecialiteFormData, string>({
        query: (slug) => ({
          url: `specialites/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["specialite","allSpecialiteList","specialiteList","specialiteList"],
      }),


  }),
});

export const {
  useGetSpecialiteQuery,
  useLazySpecialiteBySlugQuery,
  useAddOrUpdateSpecialiteMutation,
  useDeleteSpecialiteMutation,
  useGetAllSpecialiteListQuery,
} = SpecialiteApi;
