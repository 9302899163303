import React, { useEffect, useState } from "react";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import Icon1 from "../../../assets/icons/icon1.svg";
import Icon2 from "../../../assets/icons/icon2.svg";
import Icon3 from "../../../assets/icons/icon3.svg";
import Icon4 from "../../../assets/icons/icon4.svg";
import Revenus from "./LineChat/Revenus";
import Incomming from "./LineChat/Incomming";
import RdvAdminTable from "./ActiviteRdvTable/RdvAdminTable";
import { usePagination, useSearch } from "../../../utils/hooks";
import {
  useGetRdvsQuery,
  useGetDashboardsQuery,
} from "../../../utils/api/rdvs/rdvs.api";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
} from "react-icons/md";
import moment from "moment";

const DashboardAdmin = () => {
  //filrter ?filter=year&date=
  const { search, handleSearch } = useSearch();
  const { limit, page, onChange } = usePagination(10);
  const [rdvs, setRdvs] = useState<any>([]);
  const [type, setType] = useState<string>("week");
  const [date, setDate] = useState<Date>(new Date());
  const [qdate, setQDate] = useState<any>('');
  const [query, setQuery] = useState("");
  const { data: dashboard } = useGetDashboardsQuery({
    filter: type === "custom" ? "" : type,
    date: moment(date).format("DD-MM-YYYY"),
  });
  const { data, isLoading, refetch } = useGetRdvsQuery({
    page: page,
    limit: limit,
    date: qdate ? moment(qdate).format('DD-MM-Y') : '',
    q: query,
  });

  useEffect(() => {
    setRdvs(data?.results);
  }, [data]);

  // const onChangeDate = (e) => {
  //   setRdvs(
  //     data?.results?.filter((x) =>
  //       moment(x?.date).isSame(moment(e.target.value).format("YYYY-MM-DD"))
  //     )
  //   );
  // };

  const onChangeDate = (e) => {
    setQDate(e.target.value)
    refetch()
  }
  console.log('dashboard', dashboard)
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view ">
              <div className="content-stat-dashboard-view">
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon1}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Rendez-vous
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {dashboard?.rdvs}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon2}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Nouveaux Patients
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {dashboard?.patients}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon3}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Rendez-vous terminés
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {dashboard?.operations}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon4}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">Gain</p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {dashboard?.gains} FCFA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-graph-admin mt-5 mb-3">
          <LineChart dashboard={dashboard}
          setType={setType}
          type={type}
          date={date}
          setDate={setDate}
           />
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 mb-3">
            <div className="content-graph-admin">
              <Revenus
                dashboard={dashboard}
                setType={setType}
                type={type}
                date={date}
                setDate={setDate}
              />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-12 mb-3">
            <div className="content-graph-admin">
              <Incomming dashboard={dashboard} />
            </div>
          </div> */}
        </div>
        <div className="content-graph-admin">
          <div className="container-title-dash gap-4 pb-4">
            <h3 className="custom-top-title">Liste des rendez-vous</h3>
            <div className="filtered-rdv-on-date-container custom-filtered-text">
              {/* <button className="filter-scope-btn">
                <MdKeyboardArrowLeft style={{ fontSize: 18 }} />
              </button>
              <span className='px-2'>
                05 Août 2022 {" "} &nbsp;
                <em>Aujourd'hui</em> <MdKeyboardArrowDown />
              </span>
              <button className="filter-scope-btn">
                <MdKeyboardArrowRight style={{ fontSize: 18 }} />
              </button> */}
              <input
                  type="search"
                  className="form-control form-control-search"
                  placeholder="Tapez pour rechercher"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
              <input
                onChange={(e) => onChangeDate(e)}
                className="form-control form-control-modal-custom"
                type="date"
              />
            </div>
          </div>
          <div className="admin-table pt-5">
            <RdvAdminTable
              rdvs={data?.results}
              isLoading={isLoading}
              limit={limit}
              page={page}
              onChange={onChange}
              count={data?.count}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
