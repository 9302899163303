import React, { Dispatch, SetStateAction }  from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { HiOutlineDownload } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function Revenus(
  { dashboard,
    setType,
    type,
    date,
    setDate
  }: {
    dashboard: any;
    type: string;
    setType: Dispatch<SetStateAction<string>>;
    date: Date;
    setDate: Dispatch<SetStateAction<Date>>;
  }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };


  const labels = dashboard?.revenus?.graph?.map((x) => (x?.date));

  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Patients 2019',
        borderColor: '#22BBC0',
        borderWidth: 2,
        fill: false,
        data: dashboard?.revenus?.graph?.map((x) => (x?.revenu)),
        lineTension: 0.3,
      },
    ],
  };

  const onChange = (start: any) => {
    setDate(start);
  };

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">
          {dashboard?.revenus?.total} FCFA
        </h2>
        {/* <button  
          className="btn custom-extract-btn"
        >
          <span className="download-icon pe-2">
            <HiOutlineDownload />
          </span>
          <span>Extraction</span>
        </button> */}
        <div className="chart-item-admin-week-indicator-container">
          <DatePicker
            selected={date}
            onChange={(date: any) => onChange(date)}
            startDate={date}
            locale="fr"
            className="chart-item-admin-week-indicator"
            showMonthYearPicker={type === "month"}
            showYearPicker={type === "year"}
            dateFormat={
              type === "month"
                ? "MMMM/yyyy"
                : type === "year"
                  ? "yyyy"
                  : "dd/MM/yyyy"
            }
          />
          <div className="chart-item-admin-week-indicator-statut"></div>
        </div>
        <div className="content-select-date-graph gap-3 d-flex">
          <button className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${type === "year" ? "fc-button-active" : ""
            }`}
            onClick={() => setType("year")}>
            Année
          </button>
          <button className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${type === "month" ? "fc-button-active" : ""
            }`}
            onClick={() => setType("month")}>
            Mois
          </button>
          <button className={`fc-timeGridWeek-button fc-button fc-button-primary btn-type-date ${type === "week" ? "fc-button-active" : ""
            }`}
            onClick={() => setType("week")}>Semaine
          </button>
        </div>
        <div className="content-select-date-graph">
          <p className="text-subtitle-graph">Revenu</p>
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  );
}

export default Revenus;
