import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { NavLink } from "react-router-dom";

const ForgetPasswordPhone = () => {
 
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4">
              <div className="content-logo-auth pb-2 pt-5">
                <img src={Logo} alt="logo" className="logo-auth-page" />
              </div>
              <h2 className="title-auth-form-text pt-5">Mot de passe oublié </h2>
              <p className="text-message-auth w-50 ms-auto me-auto">Entrez votre adresse e-mail et nous vous enverrons un e-mail avec toutes les instructions.</p>
              <div className="mt-5">
                <form id="auth-form">
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <label className="form-label form-label-auth-login">
                          Adresse email
                        </label>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          aria-label="Username"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-submit-btn-container mt-5">
                      <NavLink
                       to="/reinitialiser-mot-de-passe"
                        className="btn auth-submit-btn trans-0-2"
                      >Envoyer
                      </NavLink>
                    </div>
                    <div className="col-md-12 mt-3 text-right">
                      <div className="col-md-12 pt-4">
                        <NavLink
                          className="nav-link forget-password-link text-center"
                          to="/mot-de-passe-oublie"
                        >
                          Utiliser une autre méthode? 
                        </NavLink>
                    </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPhone;
