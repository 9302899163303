import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  useAddOrEditAdminMutation,
  useArchiveUserMutation,
  useUnArchiveUserMutation
} from "../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import axios from "axios";


function UseCrudProfilForm() {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    telephone: validatePhone(yup, code).required("Téléphone est obligatoire"),
    email: yup.string().email().required("Email est obligatoire"),
  });
  const navigate = useNavigate();
  const { user, token } = useAppSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditAdminMutation();
  const [address, setAddress] = useState("");

  const handleChangeAvatar = (e) => { 
    let files = e.target.files[0];
    let fd = new FormData();
    fd.append("nom", user?.nom);
    fd.append("prenom", user?.nom);
    fd.append("email", user?.email);
    fd.append("avatar", files);

    addOrUpdateUser({
      slug: user?.slug,
      data: fd,
    });

  }



  useEffect(() => {
    if (isSuccess) {
      if (data?.slug) {
        if (user?.slug === data?.slug) {
          
          dispatch(onSetUser(data));
        }
      }

      Swal.fire({
        icon: "success",
        title: !user
          ? "Admin ajouté avec succès"
          : "Votre profil a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        
          navigate("/admin/mon-profil");
        
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      console.log("user", user);
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "avatar"
      ];
      if (user?.user_type !== "admin") {
        fields = [
          "nom",
          "prenom",
          "telephone",
          "email",
          "adresse",
          "avatar"
        ];
      }
      if (user?.telephone) {
        setPhone(user.telephone);
      }
      
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
    }
  }, [user]);

  const onSubmit = (data: UserFormData) => {
    !user && (data["user_type"] = "admin");
    console.log("data", data);
    if(data?.avatar)
    {
      delete data['avatar']
    }
    addOrUpdateUser({
      slug: user?.slug,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeAvatar,
  };
}

export default UseCrudProfilForm;

export function useToggleArchiveAdmin(user: IUser) {
  const [editData] = user?.is_archive ? useUnArchiveUserMutation() : useArchiveUserMutation();
  const onArchive = async () => {
    let data: UserFormData = {
      is_archive: !user.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet admin ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Admin ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}