import React from "react";
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { NavLink } from "react-router-dom";
import { DeleteApropos } from "../requestForm/UseAddOrUpdateAproposForm"
import ReactQuill from 'react-quill';


const AproposItem = ({about}) => {
    return(
      <div className="use-condition-item">
            <h5 className="use-condition-title pb-3">
              {about?.titre}
            </h5>
            <div className="custom-condition-content bordered">
              <ReactQuill readOnly={true} value={about?.content} />
              <div className="btn-group action-btn">
                  <button
                    className="btn btn-sm dropdown-toggle custom-btn-dots"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to={`/admin/parametres/modifier-apropos/${about?.slug}`}
                      >
                        Modifié
                      </NavLink>
                    </li>
                    <li>
                      <DeleteApropos item={about} />
                    </li>
                  </ul>
              </div>
          </div>
        </div>
    )
}

export default AproposItem;