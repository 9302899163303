/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { QueryUrl } from "../../Utils";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults,TypeQuery } from "../user/user.type";
import { ConditionFormData, ICondition } from "./condition.type";

export const ConditionApi = createApi({
  reducerPath: "conditionApi",
  tagTypes: ["conditionList","conditionBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),

  endpoints: (builder) => ({
    
    getListConditionsAdmin: builder.query<PaginationResults<ICondition>, TypeQuery>({
      providesTags: ["conditionList"],
      query: (query) => QueryUrl("conditions/", query),
    }),
    addOrEditCondition: builder.mutation<
      ICondition,
      { slug?: string; data: ConditionFormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `conditions/${slug}/` : `conditions/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["conditionList"],
    }),

    deleteCondition: builder.mutation<ICondition, string>({
      query: (slug) => ({
        url: `conditions/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["conditionList"],
    }),

    conditionBySlug: builder.query<ICondition | any, string>({
      query: (slug) => `conditions/${slug}/`,
      providesTags: ["conditionBySlug"],
    }),
  
  }),
});


export const {
  useAddOrEditConditionMutation,
  useGetListConditionsAdminQuery,
  useLazyConditionBySlugQuery,
  useDeleteConditionMutation,
} = ConditionApi;
