import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { NavLink } from "react-router-dom";
import {UseForgetPasswordForm} from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";


const ForgetPassword = () => {
  const { register, errors, isLoading, onSubmit } = UseForgetPasswordForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4">
              <div className="content-logo-auth pb-2 pt-5">
              <NavLink
                  className="nav-link forget-password-link text-center"
                  to="/"
                >
                <img src={Logo} alt="logo" className="logo-auth-page" />
              </NavLink>
              </div>
              <h2 className="title-auth-form-text pt-5">Mot de passe oublié </h2>
              <p className="text-message-auth w-50 ms-auto me-auto">Entrez votre adresse e-mail et nous vous enverrons un e-mail avec toutes les instructions.</p>
              <div className="mt-5">
                <form id="auth-form" onSubmit={onSubmit}>
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <label className="form-label form-label-auth-login">
                          Adresse email
                        </label>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          aria-label="Username"
                          placeholder="Email"
                          {...register("email")}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                    <div className="col-md-12 auth-submit-btn-container mt-5">
                      <button
                        className="btn auth-submit-btn trans-0-2"
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>En cours...</span>
                          </>
                        ) : (
                          "Envoyer"
                        )}

                      </button>
                    </div>
                    <div className="col-md-12 mt-3 text-right">
                      <div className="col-md-12 pt-4">
                        <NavLink
                          className="nav-link forget-password-link text-center"
                          to="/mot-de-passe-oublie-telephone"
                        >
                          Utiliser une autre méthode? 
                        </NavLink>
                    </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
