/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../utils/Utils";
import { RdvsFormData, IRdvs } from "../../../../utils/api/rdvs/rdvs.type";
import {
  useAddOrUpdateRdvMutation,
  useDeleteRdvMutation,
} from "../../../../utils/api/rdvs/rdvs.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";
import { IoIosUnlock } from "react-icons/io";

function UseAddOrUpdateRendezVousForm(rendezVous?: IRdvs) {
  const validationSchema = yup.object().shape({
    motif: yup.string().required("Le Motif est obligatoire"),
    patient: yup.string().required("Le patient est obligatoire"),
    medecin: yup.string().required("Le praticien est obligatoire"),
    date: yup
      .mixed()
      .required("La date est obligatoire")
      .test("is-valid-date", "Date invalide", function (value) {
        if (value === null || value === undefined) return false;

        let date;
        if (typeof value === "number") {
          date = new Date(value);
        } else if (typeof value === "string") {
          date = new Date(value);
        } else if (value instanceof Date) {
          date = value;
        } else {
          return false;
        }

        return !isNaN(date.getTime());
      })
      .transform((value) => {
        if (typeof value === "number") {
          return new Date(value);
        } else if (typeof value === "string") {
          return new Date(value);
        }
        return value;
      })
      .test(
        "is-not-past",
        "La date ne peut pas être antérieure à aujourd'hui",
        function (value) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return value >= today;
        }
      ),
    heure_de_debut: yup
      .string()
      .required("L'heure de début est obligatoire")
      .test(
        "is-before-end-time",
        "L'heure de début ne doit pas être supérieure à l'heure de fin",
        function (value) {
          const { heure_de_fin } = this.parent;
          if (!value || !heure_de_fin) return true;
          return value <= heure_de_fin;
        }
      ),
    heure_de_fin: yup
      .string()
      .required("L'heure de fin est obligatoire")
      .test(
        "is-after-start-time",
        "L'heure de fin doit être après l'heure de début",
        function (value) {
          const { heure_de_debut } = this.parent;
          if (!value || !heure_de_debut) return true;
          return value >= heure_de_debut;
        }
      ),
    moyen_paiement: yup
      .string()
      .required("Le moyen de paiement de fin est obligatoire"),
    format: yup.string().required("Le format de fin est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<RdvsFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<
    { label: string; value: string } | undefined
  >();
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();

  const [addOrUpdateRendezVous, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateRdvMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `RendezVous ${rendezVous ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate("/admin/rendez-vous");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {
    if (rendezVous?.id) {
      let fields: (keyof RdvsFormData)[] = [
        "slug",
        "heure_de_debut",
        "heure_de_fin",
        "date",
        "format",
        "statut",
        "document",
        "paid",
        "moyen_paiement",
        "motif",
        "prestation",
        "patient",
        "medecin",
        "choix_consultation",
      ];
      for (let field of fields) {
        register(field);
        if (field === "date" && rendezVous[field]) {
          const dateString = rendezVous[field];
          const date = new Date(dateString);

          if (
            dateString &&
            !isNaN(date.getTime()) &&
            dateString !== "Invalid Date"
          ) {
            setValue(field, date.toISOString().slice(0, 10)); // formatter la date YYYY-MM-DD
          } else {
            setValue(field, "");
          }
        } else if (field == "format") {
          setValue(field, rendezVous[field]?.id);
        } else if (field == "patient") {
          setValue(field, rendezVous[field]?.id);
        } else if (field == "medecin") {
          setValue(field, rendezVous[field]?.id);
        } else {
          setValue(field, rendezVous[field]);
        }
      }
    }
  }, [rendezVous]);

  const onSubmit = async (data: RdvsFormData) => {
    if (data.date) {
      const date = new Date(data.date);
      if (!isNaN(date.getTime())) {
        data.date = date.toISOString().slice(0, 10);
      } else {
        data.date = "";
      }
    }

    await addOrUpdateRendezVous({ slug: rendezVous?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
  };
}

export function DeleteRendezVous({ item }: { item: IRdvs }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteRdvMutation();
  const onDelete = useDelete<IRdvs>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce rendezVous",
    successMessage: "Rendez-vous supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseAddOrUpdateRendezVousForm;
