import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IAnnonce } from "./annonce.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  AnnonceFormData,
} from "./annonce.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const AnnonceApi = createApi({
  reducerPath: "annonce",
  tagTypes: [
    "annonce",
    "annonceList",
    "annonceBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get annonce pratricien 
   
      getAnnonce: builder.query<PaginationResults<IAnnonce>, TypeQuery>({
        query: (query) => QueryUrl(`annonces/`, query),
        providesTags: ["annonce"],
      }),
      annonceBySlug: builder.query<IAnnonce | any, string>({
        query: (slug) => `annonce/${slug}/`,
        providesTags: ["annonceBySlug"],
      }),

      addOrUpdateAnnonce: builder.mutation<IAnnonce, { slug?: string; data: IAnnonce | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `annonces/${slug}/` : `annonces/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["annonce"],
      }),
      deleteAnnonce: builder.mutation<AnnonceFormData, string>({
        query: (slug) => ({
          url: `annonces/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["annonce"],
      }),


  }),
});

export const {
  useGetAnnonceQuery,
  useLazyAnnonceBySlugQuery,
  useAddOrUpdateAnnonceMutation,
  useDeleteAnnonceMutation,
} = AnnonceApi;
