import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IReponseDemande } from "./reponseDemande.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  ReponseDemandeFormData,
} from "./reponseDemande.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const ReponseDemandeApi = createApi({
  reducerPath: "reponseDemande",
  tagTypes: [
    "allReponseDemandeList",
    "reponseDemande",
    "reponseDemandeList",
    "reponseDemandeBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get reponseDemande pratricien 

      getAllReponseDemandeList: builder.query<any, void>({
        providesTags: ["allReponseDemandeList"],
        query: () => {
          return "responses_demandes/";
        },
      }),
  
      getReponseDemande: builder.query<PaginationResults<IReponseDemande>, TypeQuery>({
        query: (query) => QueryUrl(`responses_demandes/`, query),
        providesTags: ["reponseDemandeList"],
      }),
      reponseDemandeBySlug: builder.query<IReponseDemande | any, string>({
        query: (slug) => `responses_demandes/${slug}/`,
        providesTags: ["reponseDemandeBySlug"],
      }),

      addReponseDemande: builder.mutation<IReponseDemande, { slug?: string; data: IReponseDemande | FormData }>({
        query: ({ slug, data }) => ({
          url: `responses_demandes/`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["reponseDemande"],
      }),
      deleteReponseDemande: builder.mutation<ReponseDemandeFormData, string>({
        query: (slug) => ({
          url: `responses_demandes/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["reponseDemande"],
      }),


  }),
});

export const {
  useGetReponseDemandeQuery,
  useLazyReponseDemandeBySlugQuery,
  useAddReponseDemandeMutation,
  useDeleteReponseDemandeMutation,
  useGetAllReponseDemandeListQuery,
} = ReponseDemandeApi;
