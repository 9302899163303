import React from 'react';
import "./UsersAdmin.css";
import { MdAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import BreadCrumb from '../../shared/BreadCrumb';
import UsersAdminTable from './tables/UsersAdminTable';
import { ApiBaseUrl } from "../../../utils/http";


function UsersAdmin() {

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/praticiens'
          pageName="Utilisateurs"
        />
        <div className="content-graph-admin">
          
          
          <div className="custom-table-content pt-3">
            <UsersAdminTable />
          </div>

        </div>
      </div>
    </div>
  )
}

export default UsersAdmin

