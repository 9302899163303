import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import "./ProfilAdmin.css";
import profil from "../../../assets/appImages/profil_admin.png";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import InfosPersonnelles from "./Tabs/InfosPersonnelles";
import ChangerPassword from "./Tabs/ChangerPassword";
import Notification from "./Tabs/Notification";
import { HiOutlineCamera } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { BiLink } from "react-icons/bi";
import { useAppSelector } from "../../../redux/hook";
import { getAvatar,getUserPseudo } from "../../../utils/Utils";

import UseCrudProfil from "./request/UseCrudProfil";
import { ApiBaseUrl } from "../../../utils/http";

const steps = [
  { id: "infos-perso", Component: InfosPersonnelles },
  { id: "change-password", Component: ChangerPassword },
  { id: "notification", Component: Notification },
];
const tabs = [
  "Mon profil", 
  "Changer mon mot de passe", 
  "Notifications"
];

function ProfilAdmin() {
  const location = useLocation();
  const { user, token } = useAppSelector((state) => state.user);

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    setValue,
    handleChangeAvatar
  } = UseCrudProfil();

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageName="Mon profil"
        />
        <div className="custom-profil-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Mon profil       
                </h3>
            </div>
            <div className="photo-and-main-infos mt-2">
                <div className="row">
                    <div className="col-md-3">
                        <div className="custom-wrapper">
                            <div className="photo-container pb-5">
                                <h4 className="custom-title pb-3">
                                    Photo
                                </h4>
                                <div className="photo-wrapper position-relative">
                                    <label htmlFor="input-test" className="label-input-test">
                                        {(user?.avatar && !user?.avatar?.includes("default.png")) ?
                                          <img src={getAvatar(user?.avatar)} alt="photo" />
                                          :
                                          <span className="format-pseudo">{getUserPseudo(user)}</span>
                                        }
                                      <div className="picture-overlay">
                                        <HiOutlineCamera />
                                      </div>
                                    </label>
                                    <input
                                      type="file"
                                      className="input-add-avatar"
                                      id="input-test"
                                      onChange={handleChangeAvatar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="custom-tabs-content">
                          <ul className="tabs-container">
                          {tabs.map((tab, key) => (
                            <li 
                              className={`
                                tab-item
                                ${index === key ? 'tab-item-active' : ''}
                              `}
                              onClick={() => go(key)}
                            >
                              {tab}
                            </li>
                          ))}
                          </ul>
                        </div>
                        <div className="top-curved-container mt-2">
                            <Component />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilAdmin;
