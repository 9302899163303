import React, { useState } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import TableSkeleton from '../../../common/TableSkeleton';
import { useDelete, usePagination, useSearch } from '../../../../utils/hooks';
import { useDeleteSpecialiteMutation, useGetSpecialiteQuery } from '../../../../utils/api/specialite/specialite.api';
import { AlertInfo } from '../../../common/Alert';
import AddSpecialitesModal from '../Modals/AddSpecialitesModal';
import { ButtonDelete } from '../../../common/Button';
import { ISpecialite } from '../../../../utils/api/specialite/specialite.type';
import { CustomPagination } from '../../../common/CustomPagination';
import { onShow , getAvatar} from "../../../../utils/Utils";


function SpecialitesTable() {
  const { limit, page, onChange } = usePagination(10);
  const { search, handleSearch } = useSearch();

  const { data, isLoading } = useGetSpecialiteQuery({ 
    page: page,
    limit: limit,
   });
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState();
  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commande$s d-flex gap-3">
          {/* <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/parametres/admins/${row.slug}`}
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Voir"
              >
                <AiFillEye />
              </NavLink>
            </div> */}
          <div className="container-btn-action-icon-modal">
            <button
              onClick={() => {
                setItem(row);
                onShow("AddSpecialisteModal");
              }}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
            >
              <MdEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteSpecialite item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="textt-center fw-bold">
        {cell}
      </div>
    );
  };

  const imageFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-center align-items-center row gap-3">
        <div className="td-photo-container">
          {row?.image &&  <img src={getAvatar(row?.image)} alt="photo" /> }
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "image",
      text: `Images`,
      formatter: imageFormatter,
    },
    {
      dataField: "nom",
      text: `Spécialités`,
      formatter: nameFormatter,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <div className="liste-administrator-table-wrapper pt-4">
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          {/* <div className="content-form-search-bloc me-3">
            <input
              type="search"
              className="form-control form-control-search"
              placeholder="Tapez pour rechercher"
              id="specialiteSearch"
              onChange={handleSearch}
            />
          </div> */}
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => <AlertInfo message="Aucune donnée trouvéé" />}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={onChange}
              perPage={limit}
            />
          </div>
        </>
      )}
      <AddSpecialitesModal item={item} />
    </div>
  );
}

export default SpecialitesTable

export function DeleteSpecialite({ item }: { item: ISpecialite }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteSpecialiteMutation();
  const onDelete = useDelete<ISpecialite>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: `cette spécialité ?`,
    successMessage: `Spécialité supprimée`,
  });
  return <ButtonDelete onClick={onDelete} />;
}