import React, { useState , useEffect} from "react";
import { Input } from "../../common/Input";
import BreadCrumb from "../../shared/BreadCrumb";
import UseAddOrUpdateUserForm from "./requestForm/UseAddOrUpdateUserForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyUserByIdQuery } from "../../../utils/api/user/user.api";
import { useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Authorisations } from "../../../utils/constante";


function ShowAdmin() {
    const [subAdmin, setSubAdmin] = useState<any>();

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyUserByIdQuery();
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);

    const {
        register,
        errors,
        onSubmit,
        setValue,
        isLoading,
      } = UseAddOrUpdateUserForm(data);


    return(
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container mb-3">
                    <BreadCrumb
                        pageUrl='/admin/parametres' 
                        pageName="Paramètres"
                        secondPage="Ajouter un sous-admin"
                    />
                    <div className="custom-add-form-content">
                        <div className="top-curved-container">
                            <h3 className="custom-top-title">
                                Paramètres       
                            </h3>
                        </div>
                        <div className="custom-sub-title-content px-4 mt-1">
                            <h4 className="custom-top-title">
                                Details d'un sous-admin       
                            </h4>
                        </div>
                        <div className="custom-wrapper">
                            <div className="custom-form mt-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="prenon">Prenon :</label>
                                            <p id='prenon'>{data?.prenom}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Non">Nom :</label>
                                            <p id='Non'>{data?.nom}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Email">Email :</label>
                                            <p id='Email'>{data?.email}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Téléphone">Téléphone :</label>
                                            <p id='Téléphone'>{data?.telephone}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Adresse">Adresse :</label>
                                            <p id='Adresse'>{data?.adresse}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Boite">Boite postale :</label>
                                            <p id='Boite'>{data?.boite_postal}</p>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <label htmlFor="Nationnalité">Nationnalité :</label>
                                            <p id='Nationnalité'>{data?.nationnalite}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="bg-white p-3 filter-and-table-container">
                                            <div className="content-title-section-detail-admin">
                                                <p className="title-permissions">
                                                    Permissions
                                                </p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group crud-form-group">
                                                        <div className="container-radio-input">
                                                            <div className="form-radio-input radio-input">
                                                                {Authorisations?.map(
                                                                    (
                                                                        item
                                                                    ) => (
                                                                        <label
                                                                            className="ml-0 mb-3"
                                                                            key={
                                                                                item?.field
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                id="dashbboard"
                                                                                {...register(
                                                                                    item?.field
                                                                                )}
                                                                                disabled={
                                                                                    true
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {
                                                                                    item?.label
                                                                                }
                                                                            </span>
                                                                        </label>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                                    <NavLink
                                        className="nav-link forget-password-link text-center"
                                        to="/admin/parametres"
                                        >
                                        Retour
                                    </NavLink>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowAdmin;