import React, { useState , useEffect } from 'react';
import "./FormatsAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input, InputQuill } from '../../common/Input';
import { useLocation, useParams } from "react-router-dom";
import UseAddOrUpdateFormatForm from "./requestForm/UseAddOrUpdateFormatForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyFormatBySlugQuery } from "../../../utils/api/format/format.api";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { GoogleAdresseType } from "../../../utils/type";
import { SelectInput } from "../../common/SelectInput";


function AddOrUpdateFormat() {
    let location = useLocation();

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyFormatBySlugQuery();
    const [adresse, setAdresse] = useState<any>('');

    const selectConsultations = [
        {label: 'Télémédecine',value: 'telemedecine'},
        {label: 'A domicile',value: 'a_domicile'}
    ] ;

    const selectFormats = [
        {label: 'Vidéo',value: 'video'},
        // {label: 'Audio',value: 'audio'},
        // {label: 'Message',value: 'texto'},
        {label: 'A domicile',value: 'domicile'}
    ] ;


    useEffect(() => {
        if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);
  
  
    const {
      register,
      errors,
      onSubmit,
      setValue,
      isLoading,
      handleChangeIcone,
    } = UseAddOrUpdateFormatForm(data);



  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        {!!data && data ?
            <BreadCrumb 
                pageUrl='/admin/formats'
                pageName="Formats"
                secondPage="Modifier un format"
            />
            :
            <BreadCrumb 
            pageUrl='/admin/formats'
            pageName="Formats"
            secondPage="Ajouter un format"
            />
        }
        <div className="content-graph-admin">
            <div className="custom-form mt-5">
                <form onSubmit={onSubmit} >
                    <div className="row">
                        <div className="col-md-6">
                            <Input 
                                label='Nom'
                                type='text'
                                placeholder='Nom'
                                {...register("nom")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.nom && errors?.nom?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Prix'
                                type='number'
                                placeholder='Prix'
                                {...register("prix")}
                                min={0}
                                required
                            />
                            <ErrorMessage
                                message={errors?.prix && errors?.prix?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectInput label="Type de format" options={selectFormats} {...register("type_format")}/>
                            <ErrorMessage
                                message={errors?.type_format && errors?.type_format?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectInput label="Choix consultation" options={selectConsultations} {...register("choix_consultation")}/>
                            <ErrorMessage
                                message={errors?.choix_consultation && errors?.choix_consultation?.message}
                            />
                        </div>
                        {/* <div className="col-md-12">
                            <Input 
                                label='Upload icone'
                                type='file'
                                onChange={(e) => handleChangeIcone(e)}
                            />
                            <ErrorMessage
                                message={errors?.icone && errors?.icone?.message}
                            />
                        </div> */}
                        <div className="col-md-12">
                            <label
                                htmlFor='description'
                                className={`form-label form-label-modal-custom`}
                                aria-labelledby='description'
                            >
                                Description
                            </label>
                            <textarea className="form-control form-control-modal-custom" {...register("description")} id="" cols={30} rows={10}></textarea>
                            <ErrorMessage
                                message={errors?.description && errors?.description?.message}
                            />
                        </div>
                        
                    </div>
                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                        <button
                            type="submit"
                            className="btn btn-theme-admin"
                            disabled={isLoading}
                        >
                            {isLoading  ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                    role="status"
                                ></span>
                                <span>
                                    {data ? "Modification..." : "Ajout..."}
                                </span>
                            </>
                            ) : data ? (
                                "Modifer"
                            ) : (
                                "Ajouter"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdateFormat;

