import React , { useState, useEffect} from "react";
import "./HeaderAdmin.css";
import { NavLink, useLocation } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import { FiSearch } from "react-icons/fi";
import "../SidebarAdmin/SidebarAdmin.css";
import Avatar from "../../../../assets/appImages/avatar.png";
import { IoNotifications } from "react-icons/io5";
import { onlogout } from "../../../../redux/slice/User.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { ApiBaseUrl } from "../../../../utils/http";
import {
  useGetNotificationsAdminQuery,
} from "../../../../utils/api/notification/notification.api";
import { useLazyNotificationsNonLuByUserQuery } from "../../../../utils/api/notification/notification.api";
import { INotification } from "../../../../utils/api/notification/notification.type";
import { truncate, isSuperAdmin , isAdmin , getUserPseudo, isPraticien, getAvatar, getName, createMarkup } from "../../../../utils/Utils";
import moment from "moment";


const HeaderAdmin: React.FC = () => {
  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
 
  var [showNotifications, setShowNotifications] = React.useState(false);

  const { data:notificationsAdmin } = useGetNotificationsAdminQuery();
  const [modalNotification, setModalNotification] = useState(false);
  const [showProfil , setShowProfil] = useState(true);
  const [notification, setNotification] = useState();
  const [findNotifications, { data:notifications }] = useLazyNotificationsNonLuByUserQuery() ;

  console.log("notifications", notifications)
  console.log("notificationsAdmin", notificationsAdmin)

  useEffect(() => {
    findNotifications(user?.slug as string);
  }, [user]);


  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const onLogout = () => {
    window.location.pathname = "/";
    dispatch(onlogout());
    
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark">
        <div className="headering-admin">
         
          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div
                  className="notification-item-container linkable position-relative"
                  onClick={() => onShowAndHideNotification()}
                >
                  <div className="notification-item-content">
                    <IoNotifications className="notif-icon" />
                    {/* <span className="notification-counter">
                    {notifications?.results?.length}
                    </span> */}
                    {/* {notifications?.results?.filter((x) => !x.read)?.length ==
                    0 ? null : (
                      <span className="notification-counter">
                        {notifications?.results?.filter((x) => !x.read)?.length}
                      </span>
                    )} */}
                  </div>
                </div>
              </div>
              
              {location?.pathname?.startsWith("/patient") ? 
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="user-acount-dashboard-header-display g-2">
                      <NavLink
                          to="/admin/mon-profil"
                        >
                          
                          {(user?.avatar && !user?.avatar?.includes("default.png")) ?
                            <img src={getAvatar(user?.avatar)} alt="User Avatar" />
                            :
                            <span className="format-pseudo">{getUserPseudo(user)}</span>
                          }
                        </NavLink>
                  </div>
                </div>
              </div>
              :
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to="/admin/mon-profil"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        {getName(user)}
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                        onClick={() => onLogout()}
                      >
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              }
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <ul className="header-hiddenable-notification-ul">
           
                <li className="header-hiddenable-notification-li">
                  Il n'y a aucune notification .
                </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;
