import React from "react";
import Logo from "../../assets/appImages/logo.svg";


const AuthWrapper = (
    {children}: {children?: any}
) => {

    return(
        <>
            <div className="container-page-login-app">
                <div className="auth-row">
                    <div className="auth-col auth-right-side-col">
                        <div className="auth-right-side-container">
                            <div className="auth-form-container text-center pb-4">
                                <div className="content-logo-auth pb-2 pt-5">
                                    <img src={Logo} alt="logo" className="logo-auth-page" />
                                </div>
                                <div className="pb-5">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthWrapper;
