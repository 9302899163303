import React, { useState,useEffect } from "react";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import ReactQuill from "react-quill";
import { INotification } from "../../../../utils/api/notification/notification.type";
import UseAddOrUpdateNotificationForm from "../request/UseAddOrUpdateNotificationForm";
import { onHide } from "../../../../utils/Utils";


const ShowNotificationModal = (
  { 
    notification 
  }: {
    notification?: INotification;
  }
) => {
  const [value, setValue] = useState("");


  const {
    handleChangeStatutNotif
  } = UseAddOrUpdateNotificationForm();

  useEffect(() => {
    if(notification)
    {
      handleChangeStatutNotif(notification);
    }
  }, [notification]);

  const closeShowNotification = ()=>{
    onHide('ShowNotificationModal');
  }


  return (
    <div
      className="modal fade"
      id="ShowNotificationModal"
      aria-labelledby="ShowNotificationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
            <h5 className="modal-title" id="ChangePasswordModalLabel">
              Detail d'une notification
            </h5>
            <CloseModalBtn onClick={()=>closeShowNotification()}/>
          </div>
          <div className="modal-body justify-content-start w-100">
            <div className="row">
              <div className="col-md-12">
                <label
                    htmlFor="content"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="description"
                  >
                  Description
                </label>
                {notification?.content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowNotificationModal;

