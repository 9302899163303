import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import ReactQuill from 'react-quill';
import { useLocation, useNavigate, useParams  } from 'react-router-dom';
import UseAddOrUpdateAproposForm from "./requestForm/UseAddOrUpdateAproposForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyAproposBySlugQuery } from "../../../utils/api/apropos/apropos.api";

const tabs = [
  "Sous-admin",
  "Conditions d’utilisation",
  "Politique de confidentialité",
];

const AddOrUpdateApropos = () => {
  const [condition, setCondition] = useState<any>();
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError }] = useLazyAproposBySlugQuery();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);

  const {
    register,
    errors,
    onSubmit,
    setValue,
    isLoading,
    contenu,
    setContenu
  } = UseAddOrUpdateAproposForm(data);

  useEffect(() => {
    if (contenu) {
      setValue('content',contenu);
    }
  }, [contenu]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="breadcrumb-content pb-5">
          {!!condition && condition !== undefined ? 
            <BreadCrumb 
              pageUrl='/admin/parametres'
              pageName="Paramètres"
              secondPage="Mise à jour de a propos"
            />
            :
            <BreadCrumb 
              pageUrl='/admin/parametres'
              pageName="Paramètres"
              secondPage="Ajouter a propos"
            />
          }
        </div>
        <div className="custom-content">
            <div className="top-curved-container mb-2">
                <h3 className="custom-top-title">
                  Paramètres       
                </h3>
            </div>
            <div className="custom-tabs-content mb-2">
              <ul className="tabs-container">
                {tabs.map((tab, key) => (
                  <li 
                    className={`
                      tab-item
                      ${key === 1 ? 'tab-item-active' : ''}
                    `}
                    onClick={() => navigate(-1)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-colgate p-3">
              <div className="form-content">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-md-12 pb-2">
                      <Input 
                        label='Titre'
                        type='text'
                        placeholder='Titre'
                        {...register("titre")}
                        required
                      />
                      <ErrorMessage
                          message={errors?.titre && errors?.titre?.message}
                      />
                    </div>
                    <div className="col-md-12">
                      <label 
                        htmlFor="content"
                        className="form-label form-label-modal-custom mb-3"
                      >
                        Contenu
                      </label>
                      <ReactQuill 
                        value={contenu}
                        onChange={setContenu}
                      />
                    </div>
                    <div className="col-md-3 offset-md-9 pt-3">
                      <button
                          type="submit"
                          className="btn btn-theme-admin"
                          disabled={isLoading}
                      >
                          {isLoading  ? (
                          <>
                              <span
                                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                  role="status"
                              ></span>
                              <span>
                                  {data ? "Modification..." : "Ajout..."}
                              </span>
                          </>
                          ) : data ? (
                              "Modifer"
                          ) : (
                              "Ajouter"
                          )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdateApropos;