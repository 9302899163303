import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Logo from "../../../../assets/appImages/logo.svg";
import { RiSettings3Fill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { BiCategory, BiUser } from "react-icons/bi";
import { CiMail, CiMoneyCheck1 } from "react-icons/ci";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoLogOutOutline } from "react-icons/io5";
const SidebarMobile = () => {
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/dashboard"
                >
                  <BiCategory />
                  <span className="item-nav-menu drop-header">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/patients"
                >
                  <FaUserFriends />
                  <span className="item-nav-menu drop-header">Patients</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/praticiens"
                >
                  <FaUserFriends />
                  <span className="item-nav-menu drop-header">Praticiens</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/gestionnaires"
                >
                  <FaUserFriends />
                  <span className="item-nav-menu drop-header">
                    Gestionnaires
                  </span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/rendez-vous"
                >
                  <AiOutlineCalendar />
                  <span className="item-nav-menu drop-header">Rendez-vous</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/paiements"
                >
                  <CiMoneyCheck1 />
                  <span className="item-nav-menu drop-header">Paiements</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/messages"
                >
                  <CiMail />
                  <span className="item-nav-menu drop-header">Messages</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/annonces"
                >
                  <MdOutlineLocalOffer />
                  <span className="item-nav-menu drop-header">Annonces</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/mon-profil"
                >
                  <BiUser />
                  <span className="item-nav-menu drop-header">Mon profil</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/parametres"
                >
                  <RiSettings3Fill />
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom pt-5">
                <button className="btn nav-link nav-link-vertival-custom-admin sidebar-logout-btn">
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
