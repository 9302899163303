import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IGestionnaire } from "./gestionnaire.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  GestionnaireFormData,
} from "./gestionnaire.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const GestionnaireApi = createApi({
  reducerPath: "gestionnaire",
  tagTypes: [
    "gestionnaire",
    "gestionnaireList",
    "gestionnaireBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get gestionnaire pratricien 
  
      getGestionnaire: builder.query<PaginationResults<IGestionnaire>, TypeQuery>({
        query: (query) => QueryUrl(`gestionnaires/`, query),
        providesTags: ["gestionnaire"],
      }),
      gestionnaireBySlug: builder.query<IGestionnaire | any, string>({
        query: (slug) => `users/${slug}/`,
        providesTags: ["gestionnaireBySlug"],
      }),

      addOrUpdateGestionnaire: builder.mutation<IGestionnaire, { slug?: string; data: IGestionnaire | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `users/${slug}/` : `users/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["gestionnaire"],
      }),
      deleteGestionnaire: builder.mutation<GestionnaireFormData, string>({
        query: (slug) => ({
          url: `users/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["gestionnaire"],
      }),


  }),
});

export const {
  useGetGestionnaireQuery,
  useLazyGestionnaireBySlugQuery,
  useAddOrUpdateGestionnaireMutation,
  useDeleteGestionnaireMutation,
} = GestionnaireApi;
