/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { AproposFormData , IApropos } from "../../../../utils/api/apropos/apropos.type";
import { useAddOrEditAproposMutation  , useDeleteAproposMutation } from "../../../../utils/api/apropos/apropos.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";
import { IoMdTrash } from "react-icons/io";


function UseAddOrUpdateAproposForm(apropos?: IApropos) {
  const [contenu, setContenu] = useState('');

  const validationSchema = yup.object().shape({
    titre: yup.string().required("Le titre est obligatoire"),
    content: yup.string().required("Le contenue est obligatoire"),
   // apropos_type: yup.string().required("Le téléphone est obligatoire"),
    // specialite: yup.string().required("Le specialité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<AproposFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateApropos, { isLoading, isSuccess, error, isError }] =
  useAddOrEditAproposMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `A propos ${apropos ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/parametres')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("L'email a déjà été pris.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (apropos?.id) {
      let fields: (keyof AproposFormData)[] = [
        "slug",
        "titre",
        "content",	
        "image",	
      ];
      for (let field of fields) {
          register(field);
        
          setValue(field, apropos[field])
          
      }
      setContenu(apropos?.content);
    }
  }, [apropos]);

  const onSubmit = async (data: IApropos) => {
      
    await addOrUpdateApropos({ slug: apropos?.slug, data: data });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    contenu,
    setContenu,

  };
}

export function DeleteApropos({ item }: { item: IApropos }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteAproposMutation();
  const onDelete = useDelete<IApropos>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet a propos",
    successMessage: "A propos supprimé",
  });
  return (
    <button
      className="dropdown-item"
      onClick={onDelete}
    >
      Supprimé
    </button>
  );
}



export default UseAddOrUpdateAproposForm;
