import React, { useState,useEffect } from 'react';
import "./PraticiensAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import photo_patient from "../../../assets/appImages/profilPraticien.png";
import PraticiensPaiementTable from './tables/PraticiensPaiementTable';
import { MdCheck, MdCircle } from "react-icons/md";
import { FaSkullCrossbones } from "react-icons/fa";
import { AllRdvItemData } from './tables';
import { useLazyPraticienBySlugQuery , useLazyRdvsProchainsByPraticienQuery, useLazyRdvsPassersByPraticienQuery,useLazyPaiementsByPraticienQuery} from "../../../utils/api/praticien/praticien.api";
import { useLocation, useParams } from "react-router-dom";
import { formatDate, getAvatar, getUserPseudo } from "../../../utils/Utils";

const RdvItem = (
    {praticienName, speciality, hour, status, date}:
    {
        praticienName?: string, 
        speciality?: string, 
        hour?: string, 
        status?: string,
        date?: string,
    }
) => {
    return(
        <div 
            className={`
                rdv-sm-item
                ${status === 'nouveau' ? 'past' : ''}
                ${status === 'en_attente' ? 'past' : ''}
                ${status === 'accepter' ? 'inprogress' : ''}
                ${status === 'annuler' ? 'canceled' : ''}
                ${status === 'rejeter' ? 'canceled' : ''}
                ${status === 'terminer' ? 'past' : ''}
            `}
        >
            <div className="name-and-speciality d-flex justify-content-between align-items-center pb-2">
                <span className="name">{praticienName}</span>
                <span className="speciality">{speciality}</span>
            </div>
            <div className="hour-and-status d-flex align-items-center gap-4">
                <span className="hour">{formatDate(date)} {hour}</span>
                {!!status && status === "passé" &&
                    <span className='status'>
                        <MdCheck style={{ color: '#219653', fontSize: 10 }} />
                    </span>
                }
                {!!status && status === "en cours" &&
                    <span className='status'>
                        <MdCircle style={{ color: '#F2994A', fontSize: 11 }} />
                    </span>
                }
                {!!status && status === "annulé" &&
                    <span className='status'>
                        <FaSkullCrossbones style={{ color: '#EB5757', fontSize: 10 }} />
                    </span>
                }
            </div>
        </div>
    )
}


function PraticienDetail() {

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyPraticienBySlugQuery();
    const [findRdvProchain, { data:rdvProchain }] = useLazyRdvsProchainsByPraticienQuery();
    const [findRdvPasser, { data:rdvPasser }] = useLazyRdvsPassersByPraticienQuery();
    const [findPaiements, { data:paiements , isLoading }] = useLazyPaiementsByPraticienQuery();

    useEffect(() => {
      if (slug) {
        findBySlug(slug);
        findRdvProchain(slug);
        findRdvPasser(slug);
        findPaiements(slug);
      }
    }, [slug]);

   console.log('data',data)


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageName="Praticiens"
            pageUrl='/admin/praticiens'
            secondPage="Détail d'un praticien"
        />
        <div className="custom-detail-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Détails praticien
                </h3>
            </div>
            <div className="photo-and-general-infos mt-1">
                <div className="row">
                    <div className="col-md-3">
                        <div className="custom-wrapper">
                            <div className="photo-container pb-5">
                                <h4 className="custom-title pb-3">
                                    Photo
                                </h4>
                                <div className="photo-wrapper">
                                    {(data?.avatar && !data?.avatar?.includes("default.png")) ?
                                        <img src={getAvatar(data?.avatar)} alt="photo" />
                                        :
                                        <span className="format-pseudo">{getUserPseudo(data)}</span>
                                    }
                                </div>
                            </div>
                            <div className="rdv-container">
                                <h4 className="custom-title pb-3">
                                    Rendez-vous
                                </h4>
                                <div className="all-rdv-wrapper">
                                    <h5 className="custom-rdv-status-title incoming">
                                        À venir
                                    </h5>
                                    <div className="rdv-items-container pt-2 pb-5">
                                        <div className="row">
                                        {(!!rdvProchain && rdvProchain?.length > 0 ) ?
                                            
                                            rdvProchain.slice(0, 5).map((rdv, key) => {
                                                return(
                                                    <div className="col-md-12" key={key}>
                                                        <RdvItem 
                                                            praticienName={rdv?.patient?.prenom+' '+rdv?.patient?.nom}
                                                            speciality={data?.specialite?.nom}
                                                            hour={rdv?.heure_de_debut}
                                                            status={rdv?.status}
                                                            key={key}
                                                        />
                                                    </div>
                                                )
                                            })
                                            :
                                            <p>Pas de rendez-vous à venir </p>
                                        }
                                        </div>
                                    </div>
                                    <h5 className="custom-rdv-status-title past">
                                        Passés
                                    </h5>
                                    <div className="rdv-items-container pt-2">
                                        <div className="row">
                                            {(!!rdvPasser && rdvPasser?.length > 0 ) ?
                                                rdvPasser.slice(Math.max(rdvPasser.length - 5, 0)).map((rdv, key) => {
                                                    return(
                                                        <div className="col-md-12" key={key}>
                                                            <RdvItem 
                                                                praticienName={rdv?.patient?.prenom+' '+rdv?.patient?.nom}
                                                                speciality={data?.specialite?.nom}
                                                                hour={rdv?.heure_de_debut}
                                                                date={rdv?.date}
                                                                status={rdv?.status}
                                                                key={key}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p>Pas de rendez-vous Passés </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="custom-wrapper">
                            <div className="custom-information-content pb-5">
                                <h4 className="custom-title pb-3">
                                    Information générale
                                </h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Input 
                                            label='Prénom'
                                            type='text'
                                            value={data?.prenom}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Nom'
                                            type='text'
                                            value={data?.nom}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Date de naissane'
                                            type='text'
                                            value={data?.date_de_naissance ? formatDate(data?.date_de_naissance) : ""}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Adresse'
                                            type='text'
                                            value={data?.adresse}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Téléphone'
                                            type='tel'
                                            value={data?.telephone}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Email'
                                            type='email'
                                            value={data?.email}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Nationalité'
                                            type='text'
                                            value={data?.nationnalite}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='CNI'
                                            type='text'
                                            value={data?.cni}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='N° Ordre des médecins'
                                            type='text'
                                            value={data?.ordre_des_medecins} 
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Pays d’inscription'
                                            type='text'
                                            value={data?.pays_ordre_des_medecins}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Spécialité'
                                            type='text'
                                            value={data?.specialite?.nom} 
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Taux d’horaires'
                                            type='text'
                                            value={data?.taux_horaire} 
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="payment-content">
                                <h4 className="custom-title pb-3">
                                    Paiements
                                </h4>
                                <div className="praticien-paiement-table">
                                    <PraticiensPaiementTable paiements={paiements} isLoading={isLoading}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PraticienDetail;

