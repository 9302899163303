import React, { useState ,useEffect } from 'react';
import "./FormatsAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import photo_patient from "../../../assets/appImages/profilFormat.png";
import { MdCheck, MdCircle } from "react-icons/md";
import { FaSkullCrossbones } from "react-icons/fa";
import { AllRdvItemData } from './tables';
import { useLazyFormatBySlugQuery  } from "../../../utils/api/format/format.api";
import { useLocation, useParams } from "react-router-dom";
import { formatDate, getAvatar } from "../../../utils/Utils";


function FormatDetail() {

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError ,isLoading }] = useLazyFormatBySlugQuery();
    
    useEffect(() => {
        if (slug) {
            findBySlug(slug as string);
        }
    }, [slug]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageUrl='/admin/formats'
            pageName="Formats"
            secondPage="Détail d'un format"
        />
        <div className="custom-detail-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Détails format
                </h3>
            </div>
            <div className="photo-and-general-infos mt-1">
                <div className="row">
                    <div className="col-md-12">
                        <div className="custom-wrapper">
                            <div className="custom-information-content pb-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6>Nom</h6>
                                        <p>{data?.nom}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Prix</h6>
                                        <p>{data?.prix}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Type de format</h6>
                                        <p>{data?.type_format}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Type de Consultation</h6>
                                        <p>{data?.choix_consultation == "telemedecine" ? "Télémédecine" : "A domicile"}</p>
                                    </div>
                                    {/* {data?.icone && (
                                    <div className="col-md-3">
                                        <h4>Icone</h4>
                                        <div className="photo-wrapper">
                                            <img src={getAvatar(data?.icone)} alt="photo" />
                                        </div>
                                    </div>
                                    )} */}
                                    <div className="col-md-9">
                                        <h6>Description</h6>
                                        <p>{data?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default FormatDetail;

