/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IFichier,
  FichierFormData
} from "./fichier.type";

export const FichierApi = createApi({
  reducerPath: "fichierApi",
  tagTypes: ["fichierList", "fichierBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListFichiersAdmin: builder.query<PaginationResults<IFichier>, TypeQuery>({
      providesTags: ["fichierList"],
      query: (query) => QueryUrl("documents/", query),
    }),

    addOrEditFichier: builder.mutation<IFichier, { slug?: string; data: IFichier | FormData }>({
      query: ({ slug, data }) => ({
        url: slug ? `documents/${slug}/` : `documents/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["fichierList"],
    }),
    deleteFichier: builder.mutation<IFichier, string>({
      query: (slug) => ({
        url: `documents/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["fichierList"],
    }),
    fichierBySlug: builder.query<IFichier | any, string>({
      query: (slug) => `documents/${slug}/`,
      providesTags: ["fichierBySlug"],
    }),
  
  }),
});

export const {
  useAddOrEditFichierMutation,
  useGetListFichiersAdminQuery,
  useLazyFichierBySlugQuery,
  useDeleteFichierMutation,
} = FichierApi;
