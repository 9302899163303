import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import "./ParametreAdmin.css";
import { NavLink } from "react-router-dom";
import BreadCrumb from "../../shared/BreadCrumb";
import SousAdmins from "./tabs/SousAdmins";
import ConditionsUtilisations from "./tabs/ConditionsUtilisations";
import PolitiqueConfidentialite from "./tabs/PolitiqueConfidentialite";
import Specialites from "./tabs/Specialites";
import Apropos from "./tabs/Apropos";


const steps = [
  { id: "sous-admins", Component: SousAdmins },
  { id: "use-conditions", Component: ConditionsUtilisations },
  { id: "politique", Component: PolitiqueConfidentialite },
  { id: "specialites", Component: Specialites },
  { id: "a-propos", Component: Apropos }
];

const tabs = [
  "Sous-admin",
  "Conditions d’utilisation",
  "Politique de confidentialité",
  "Spécialités",
  "A propos"
];


function ParametreAdmin() {
  
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageUrl='/admin/parametres'
            pageName="Paramètres"
        />
        <div className="custom-profil-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                  Paramètres       
                </h3>
            </div>
            <div className="parametre-content mt-1">
                <div className="row">
                    <div className="col-md-12">
                        <div className="custom-tabs-content">
                          <ul className="tabs-container">
                          {tabs.map((tab, key) => (
                            <li 
                              className={`
                                tab-item
                                ${index === key ? 'tab-item-active' : ''}
                              `}
                              onClick={() => go(key)}
                            >
                              {tab}
                            </li>
                          ))}
                          </ul>
                        </div>
                        <div className="tabs-content mt-1 ">
                            <Component />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ParametreAdmin;
