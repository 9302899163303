import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit } from "react-icons/md";
import TableSkeleton from '../../../common/TableSkeleton';
import { NavLink } from "react-router-dom";
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ApiBaseUrl } from "../../../../utils/http";
import { formatDate, getAvatar } from "../../../../utils/Utils";
import { DeleteContact } from "../requestForm/UseAddOrUpdateContactForm"
import { getUserPseudo } from "../../../../utils/Utils";
import { usePagination, useSearch } from "../../../../utils/hooks";
import { useGetListContactAdminQuery  } from "../../../../utils/api/contact/contact.api";
import { AiFillEye } from 'react-icons/ai';
import { CustomPagination } from '../../../common/CustomPagination';



function MessagesContacts(props) {
  const navigate = useNavigate()

  const { search, handleSearch } = useSearch();
  const { limit, page, onChange } = usePagination(10);

  const { data, isLoading } = useGetListContactAdminQuery({
    page: page,
    limit: limit,
  });


    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/messages/detail-message/${row?.slug}`}
                className="btn btn-action-icon-see with-tooltip"
                data-tooltip-content="Voir"
              >
                <AiFillEye />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
              {/* <button
                className="btn with-tooltip btn-action-icon-delete"
                data-tooltip-content="Supprimer"
                style={{ color: "#E53E3E" }}
              >
                <FaTrash />
              </button> */}
              <DeleteContact item={row} />
            </div>
          </div>
        </>
      );
    };
    const nameFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center row gap-3">
          <p className="name-profil-patient mb-0 col-md-8">{row?.user?.prenom} {row?.user?.nom}</p>
        </div>
      );
    };

    const dateFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <p className="name-profil-patient mb-0">{formatDate(row?.created_at)}</p>
        </div>
      );
    };

    const choixFormatter = (cell: any, row: any) => {
      if(row?.choix_consultation == 'telemedecine')
      {
        return (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <p className="name-profil-patient mb-0">Télémédecine</p>
          </div>
        );
      }else{
        return (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <p className="name-profil-patient mb-0">A domicile</p>
          </div>
        );
      }

    };

    const columns = [
      {
        dataField: "nom",
        text: "Noms et Prenom",
        formatter: nameFormatter,
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "subject",
        text: "Object",
      },
      {
        dataField: "message",
        text: "Message",
      },
      {
        dataField: "created_at",
        text: "Date d’ajout",
        formatter: dateFormatter,
      },
      {
        dataField: "id",
        text: "Action",
        formatter: actionFormatter,
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    const rowEvents = {
      onClick: (e: any, row: any, rowIndex: any) => {
        navigate(`/admin/messages/detail-message/${row.slug}`)
      }
    };

    return (
      <>
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results || []}
              columns={columns}
              // selectRow={selectRow}
             // rowEvents={rowEvents}
              rowClasses="custom-table-row-class"
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={data?.count}
                  page={page}
                  onChange={onChange}
                  perPage={limit}
              />
              </div>
          </>
        )}
      </>
    );
}

export default MessagesContacts