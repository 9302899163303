import React, { useState ,useEffect } from 'react';
import "./RemboursementAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import photo_patient from "../../../assets/appImages/profilRemboursement.png";
import { MdCheck, MdCircle } from "react-icons/md";
import { FaSkullCrossbones } from "react-icons/fa";
import { useLazyGetRemboursementBySlugQuery  } from "../../../utils/api/remboursements/remboursements.api";
import { useLocation, useParams } from "react-router-dom";
import { formatDate, getAvatar, formatMontant, getName } from "../../../utils/Utils";


function RemboursementDetail() {

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError ,isLoading }] = useLazyGetRemboursementBySlugQuery();

    let { state } = useLocation();

    console.log({state})
    
    useEffect(() => {
        if (slug) {
            findBySlug(slug as string);
        }
    }, [slug]);


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageUrl='/admin/remboursements'
            pageName="Remboursements"
            secondPage="Détail d'un format"
        />
        <div className="custom-detail-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Détails Remboursement
                </h3>
            </div>
            <div className="photo-and-general-infos mt-1">
                <div className="row">
                    <div className="col-md-12">
                        <div className="custom-wrapper">
                            <div className="custom-information-content pb-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6>Nom Patient</h6>
                                        <p>{getName(state?.remboursement?.rdv?.patient)}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Montant</h6>
                                        <p>{state?.remboursement?.montant} fcfa</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Moyen</h6>
                                        <p>
                                        {state?.remboursement?.rdv?.moyen_paiement == 'WAVE_SN_API_CASH_OUT' && ("Wave")}
                                        {state?.remboursement?.rdv?.moyen_paiement == 'ORANGE_SN_API_CASH_OUT' && ("Orange Money")}
                                        {state?.remboursement?.rdv?.moyen_paiement == 'BANK_TRANSFER_SN_API_CASH_OUT' && ("Virement bancaire")}
                                        {state?.remboursement?.rdv?.moyen_paiement == 'CASH' && ("CASH")}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Status Remboursement</h6>
                                        <p>{state?.remboursement?.paid ? 'Payer' :'Non Payer'}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Date</h6>
                                        <p>{formatDate(state?.remboursement?.created_at)}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Message</h6>
                                        <p>{state?.remboursement?.msg}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default RemboursementDetail;

