import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IDemande } from "../demande/demande.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  DemandeFormData,
} from "./demande.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const DemandeApi = createApi({
  reducerPath: "demande",
  tagTypes: [
    "demande",
    "demandeList",
    "demandeBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({

    // demande d'incription pratricien mutation
      DemandeUser: builder.mutation<AuthState["user"],DemandeFormData | FormData>({
        query: (data) => ({
          url: "demande_inscription/create/",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["demande"],
        transformResponse: ({ data }) => data,
      }),

      //update demande
      updateDemande: builder.mutation<IDemande, { slug?: string; data: IDemande | FormData }>({
        query: ({ slug, data }) => ({
          url:`demande_inscriptions/${slug}/` ,
          method: "PUT" ,
          body: data,
        }),
        invalidatesTags: ["demande"],
      }),

    // get demande d'incription pratricien mutation
      getDemande: builder.query<PaginationResults<IDemande>, TypeQuery>({
        query: (query) => QueryUrl(`demande_inscriptions/`, query),
        providesTags: ["demande"],
      }),

  // get demande by id 
      demandeBySlug: builder.query<IDemande | any, string>({
        query: (slug) => `demande_inscriptions/${slug}/`,
        providesTags: ["demandeBySlug"],
      }),
  // delete demande  
      deleteDemande: builder.mutation<DemandeFormData, string>({
        query: (slug) => ({
          url: `demande_inscriptions/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["demande"],
      }),


  }),
});

export const {
  useDemandeUserMutation,
  useUpdateDemandeMutation,
  useGetDemandeQuery,
  useLazyDemandeBySlugQuery,
  useDeleteDemandeMutation
} = DemandeApi;
