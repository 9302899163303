import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import UseLoginForm from "./requestForm/UseLoginForm";
import ErrorMessage from "../common/ErrorMessage";

function Login() {
  const { register, errors, isLoading, onSubmit } = UseLoginForm();
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <AuthWrapper>
      <h2 className="title-auth-form-text pt-5">
        Connexion
      </h2>
      <form id="auth-form" onSubmit={onSubmit}>
        <div className="row auth-form-row">
          <div className="col-md-12 auth-input-col mb-3">
            <div className="auth-form-group">
              <label className="form-label form-label-auth-login">
                Adresse email
              </label>
              <input
                type={`email`}
                className="form-control auth-form-control"
                id="email-or-username"
                aria-label="Username"
                placeholder="Email"
                {...register("email")}
              />
            </div>
          </div>
          <ErrorMessage
            message={errors?.email && errors?.email?.message}
          />
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group position-relative">
              <label className="form-label form-label-auth-login">
                Mot de passe
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="password"
                placeholder="Mot de passe"
                aria-label="Password"
                aria-placeholder="***********"
                {...register("password")}
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <AiFillEyeInvisible />
                ) : (
                  <AiFillEye />
                )}
              </span>
            </div>
          </div>
          <ErrorMessage
            message={errors?.password && errors?.password?.message}
          />
          <div className="col-md-12 auth-submit-btn-container my-5">
            <button
              className="btn auth-submit-btn trans-0-2"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Connexion...</span>
                </>
              ) : (
                "Connexion"
              )}
            </button>
          </div>
          <div className="col-md-12 pt-4">
            <NavLink
              className="nav-link forget-password-link text-center"
              to="/mot-de-passe-oublie"
            >
              Mot de passe oublié ?
            </NavLink>
          </div>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default Login;
