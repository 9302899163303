/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IApropos,
  AproposFormData
} from "./apropos.type";

export const AproposApi = createApi({
  reducerPath: "apropos",
  tagTypes: ["aproposList", "aproposBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListAproposAdmin: builder.query<PaginationResults<IApropos>, TypeQuery>({
      providesTags: ["aproposList"],
      query: (query) => QueryUrl("abouts/", query),
    }),

    addOrEditApropos: builder.mutation<IApropos, { slug?: string; data: IApropos | FormData }>({
      query: ({ slug, data }) => ({
        url: slug ? `abouts/${slug}/` : `abouts/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["aproposList"],
    }),
    deleteApropos: builder.mutation<IApropos, string>({
      query: (slug) => ({
        url: `abouts/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["aproposList"],
    }),
    aproposBySlug: builder.query<IApropos | any, string>({
      query: (slug) => `abouts/${slug}/`,
      providesTags: ["aproposBySlug"],
    }),
  
  }),
});

export const {
  useAddOrEditAproposMutation,
  useGetListAproposAdminQuery,
  useLazyAproposBySlugQuery,
  useDeleteAproposMutation,
} = AproposApi;
