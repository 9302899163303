import React, { useState , useEffect} from "react";
import { Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import { CiEdit } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import UseCrudProfil from "../request/UseCrudProfil";
import { useAppSelector } from "../../../../redux/hook";
import GoogleInput from "../../../common/GoogleInput";
import { GoogleAdresseType } from "../../../../utils/type";
import ErrorMessage from "../../../common/ErrorMessage";


function InfosPersonnelles() {
  const location = useLocation();
  const [valu, setValu] = useState("");
  const [adresse, setAdresse] = useState<any>('');

  const { user, token } = useAppSelector((state) => state.user);

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    setValue,
  } = UseCrudProfil();

  const onChangeAdresse = (val: GoogleAdresseType) => {		
		if (val?.rue) {
      setAdresse(val?.rue)
			setValue(`adresse`, val?.rue);
		}else{
      setAdresse(val?.ville)
      setValue(`adresse`, val?.ville);
    }
	};

  useEffect(() => {
    setAdresse(user?.adresse)
		setValue(`adresse`, user?.adresse);
  }, [user]);

  return (
    <div className="custom-tab-content">
      <h4 className="custom-tab-title">
        Information générale
      </h4>
      <form className="mt-4" onSubmit={onSubmit}>
        <div className="row row-add-student my-3">
          {location?.pathname?.startsWith("/admin") && (
            <>
              <div className="col-md-4 position-relative">
                <Input
                  type="text"
                  label="Prénom"
                  id="prenom"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  placeholder="Prenom"
                  {...register("prenom")}
                    error={errors?.prenom}
                />
                <span className="icon-edit-info-profil">
                  <CiEdit />
                </span>
              </div>
              <div className="col-md-4 position-relative">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  placeholder="Nom"
                  {...register("nom")}
                    error={errors?.nom}
                />
                <span className="icon-edit-info-profil">
                  <CiEdit />
                </span>
              </div>
              <div className="col-md-4 position-relative">
                <Input
                  type="text"
                  label="Téléphone"
                  id="telephone"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  placeholder="77 700 00 00"
                  {...register("telephone")}
                    error={errors?.telephone}
                />
                <span className="icon-edit-info-profil">
                  <CiEdit />
                </span>
              </div>
              <div className="col-md-6 position-relative">
                <Input
                  type="email"
                  label="Email"
                  id="email"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  placeholder="exemple@gmail.com"
                  {...register("email")}
                    error={errors?.email}
                />
                <span className="icon-edit-info-profil">
                  <CiEdit />
                </span>
              </div>
              <div className="col-md-6 position-relative">
                {/* <Input
                  type="text"
                  label="Adresse"
                  id="adresse"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  placeholder="Adresse"
                  {...register("adresse")}
                    error={errors?.adresse}
                /> */}
                <label
                    htmlFor='adresse'
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby='adresse'
                >
                    Adresse
                </label>
                <GoogleInput
                    className="form-control form-control-modal-custom input-parametre-form-add"
                    value={adresse}
                    onChange={onChangeAdresse}
                    placeholder="Adresse"
                />
                <ErrorMessage
                    message={errors?.telephone && errors?.telephone?.message}
                />
                <span className="icon-edit-info-profil">
                  <CiEdit />
                </span>
              </div>
            </>
          )}
          <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
              <button
                  type="submit"
                  className="btn btn-theme-admin"
                  disabled={isLoading}
              >
                  {isLoading  ? (
                  <>
                      <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                      ></span>
                          <span>
                            Mise à jour...
                          </span>
                      </>)
                      : 
                      <span>Mettre à jour</span> 
                    }
              </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InfosPersonnelles;
