import React, { useState , useEffect} from "react";
import { Input } from "../../common/Input";
import BreadCrumb from "../../shared/BreadCrumb";
import UseAddOrUpdateUserForm from "./requestForm/UseAddOrUpdateUserForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyUserByIdQuery } from "../../../utils/api/user/user.api";
import { useLocation, useParams } from "react-router-dom";
import GoogleInput from "../../common/GoogleInput";
import { GoogleAdresseType } from "../../../utils/type";
import { Authorisations } from "../../../utils/constante";



function AddAdmin() {
    const [subAdmin, setSubAdmin] = useState<any>();
    const [adresse, setAdresse] = useState<any>('');

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyUserByIdQuery();
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);
  
    const {
      register,
      errors,
      onSubmit,
      setValue,
      isLoading,
    } = UseAddOrUpdateUserForm(data);

    const onChangeAdresse = (val: GoogleAdresseType) => {		
		if (val?.rue) {
            setAdresse(val?.rue)
			setValue(`adresse`, val?.rue);
		}else{
            setAdresse(val?.ville)
            setValue(`adresse`, val?.ville);
        }
	};


    return(
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container mb-3">
                    <BreadCrumb 
                        pageUrl='/admin/parametres'
                        pageName="Paramètres"
                        secondPage="Ajouter un sous-admin"
                    />
                    <div className="custom-add-form-content">
                        <div className="top-curved-container">
                            <h3 className="custom-top-title">
                                Paramètres       
                            </h3>
                        </div>
                        <div className="custom-sub-title-content px-4 mt-1">
                            <h4 className="custom-top-title">
                                Ajouter un sous-admin       
                            </h4>
                        </div>
                        <div className="custom-wrapper">
                            <div className="custom-form mt-5">
                                <form action="" onSubmit={onSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input 
                                                label='Nom du sous-admin'
                                                type='text'
                                                placeholder="Ajouter un titre"
                                                {...register("nom")}
                                                required
                                            />
                                            <ErrorMessage
                                                message={errors?.nom && errors?.nom?.message}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input 
                                                label='Prénom du sous-admin'
                                                type='text'
                                                placeholder="Ajouter un titre"
                                                {...register("prenom")}
                                                required
                                            />
                                            <ErrorMessage
                                                message={errors?.prenom && errors?.prenom?.message}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input 
                                                label='Adresse mail'
                                                type='email'
                                                placeholder="exemple@gmail.com"
                                                {...register("email")}
                                                required
                                                />
                                            <ErrorMessage
                                                message={errors?.email && errors?.email?.message}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input 
                                                label='Téléphone'
                                                type='tel'
                                                placeholder="77 000 00 00"
                                                {...register("telephone")}
                                                required
                                                />
                                            <ErrorMessage
                                                message={errors?.telephone && errors?.telephone?.message}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor='adresse'
                                                className={`form-label form-label-modal-custom`}
                                                aria-labelledby='adresse'
                                            >
                                                Adresse
                                            </label>
                                            <GoogleInput
                                                className="form-control form-control-modal-custom input-parametre-form-add"
                                                value={adresse}
                                                onChange={onChangeAdresse}
                                                placeholder="Adresse"
                                            />
                                            <ErrorMessage
                                                message={errors?.adresse && errors?.adresse?.message}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input 
                                                label='Boite postale'
                                                type='text'
                                                placeholder="Boite postale"
                                                {...register("boite_postal")}
                                            />
                                            <ErrorMessage
                                                message={errors?.boite_postal && errors?.boite_postal?.message}
                                            />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <Input 
                                                label='Nationnalité'
                                                type='text'
                                                placeholder="Nationnalité"
                                                {...register("nationnalite")}
                                                required
                                            />
                                            <ErrorMessage
                                                message={errors?.nationnalite && errors?.nationnalite?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="bg-white p-3 filter-and-table-container">
                                            <div className="content-title-section-detail-admin">
                                                <p className="title-permissions">
                                                    Permissions
                                                </p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group crud-form-group">
                                                        <div className="container-radio-input">
                                                            <div className="form-radio-input radio-input">
                                                                {Authorisations?.map(
                                                                    (
                                                                        item
                                                                    ) => (
                                                                        <label
                                                                            className="ml-0 mb-3"
                                                                            key={
                                                                                item?.field
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                id="dashbboard"
                                                                                {...register(
                                                                                    item?.field
                                                                                )}
                                                                                disabled={
                                                                                    false
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {
                                                                                    item?.label
                                                                                }
                                                                            </span>
                                                                        </label>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                                        <button
                                            type="submit"
                                            className="btn btn-theme-admin"
                                            disabled={isLoading}
                                        >
                                            {isLoading  ? (
                                            <>
                                                <span
                                                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                                    role="status"
                                                ></span>
                                                <span>
                                                    {data ? "Modification..." : "Ajout..."}
                                                </span>
                                            </>
                                            ) : data ? (
                                                "Modifer"
                                            ) : (
                                                "Ajouter"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAdmin;