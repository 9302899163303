import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../../utils/api/product/product.type";

type Cart = {
  cartItems: CartItemType[];
  totalCount: number;
  tax: number;
  delivery: number;
  subAmount: number;
  totalAmount: number;
};

export type CartItemType = {
  product: Product;
  qty: number;
};
const initialState: Cart = {
  cartItems: [],
  totalCount: 0,
  delivery: 0,
  tax: 0,
  subAmount: 0,
  totalAmount: 0,
};
const useCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartProduct: (state, action: PayloadAction<CartItemType>) => {
      let cartIndex = state.cartItems.findIndex(
        (item) => item?.product._id === action.payload.product?._id
      );
      if (cartIndex >= 0) {
        state.cartItems[cartIndex].qty += action?.payload?.qty;
      } else {
        let tempProduct = { ...action.payload };
        state.cartItems.push(tempProduct);
      }
    },
    getCartProducts: (state) => {
      return {
        ...state,
      };
    },
    getCartCount: (state) => {
      let cartCount = state.cartItems.reduce((total, item) => {
        return item?.qty + total;
      }, 0);
      state.totalCount = cartCount;
    },
    getSubTotal: (state) => {
      state.subAmount = state.cartItems.reduce((acc, item) => {
        return (
          acc +
          (item?.product?.promote?.price || item?.product?.price) * item?.qty
        );
      }, 0);
    },
    removeCartItem: (state, action: PayloadAction<Product>) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?._id === action.payload?._id
      );
      if (index !== -1) {
        state.cartItems.splice(index, 1);
      }
    },
    increment: (state, action: PayloadAction<CartItemType>) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?._id === action.payload?.product?._id
      );
      state.cartItems[index].qty += 1;
    },
    decrement: (state, action: PayloadAction<CartItemType>) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?._id === action.payload?.product?._id
      );
      if (state.cartItems[index].qty <= 0) {
        state.cartItems[index].qty = 0;
        state.cartItems.splice(index, 1);
      } else {
        state.cartItems[index].qty -= 1;
      }
    },
    calculateTax: (state) => {
      // GST value: 18% => action.payload
      let totalTax = (18 / 100) * state.subAmount;
      state.tax = totalTax;
    },
    getDeliveryAmount: (state,action:PayloadAction<number>) => {
     
      state.delivery = action.payload;
    },
    getTotalAmount: (state) => {
      state.totalAmount = state?.tax + state?.subAmount + state.delivery;
    },
    removeCart: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  addCartProduct,
  getCartProducts,
  removeCartItem,
  getCartCount,
  getSubTotal,
  increment,
  decrement,
  calculateTax,
  getTotalAmount,
  removeCart,
  getDeliveryAmount
} = useCartSlice.actions;

export default useCartSlice.reducer;
