import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import { MdOutlineModeEdit } from "react-icons/md";
import TableSkeleton from '../../../common/TableSkeleton';
import { NavLink , Link} from "react-router-dom";
import { FaTrash } from 'react-icons/fa';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { usePagination, useSearch } from '../../../../utils/hooks';
import { useGetRemboursementsQuery } from '../../../../utils/api/remboursements/remboursements.api';
import { formatDate, formatMontant, getAvatar, getName, getUserPseudo } from '../../../../utils/Utils';
import { CustomPagination } from '../../../common/CustomPagination';
import { IoFilterSharp } from 'react-icons/io5';
import { AiFillEye } from 'react-icons/ai';


function RemboursementAdminTable(props) {
  
  const navigate = useNavigate()

  const { search, handleSearch } = useSearch();
  const { limit, page, onChange } = usePagination(10);
  const { data, isLoading } = useGetRemboursementsQuery({
    page: page,
    limit: limit,
  });

  console.log({data})


    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
            
            <div className="container-btn-action-icon-modal">
              <Link
                to={`/admin/remboursements/detail-remboursement/${row?.slug}`}
                state={{ remboursement: row }}
                className="btn btn-action-icon-see with-tooltip"
                data-tooltip-content="Voir"
              >
                <AiFillEye />
              </Link>
            </div>
            {/* <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/remboursements/${row?.slug}`}
                className="btn btn-action-icon-edit with-tooltip"
                data-tooltip-content="Modifier"
              >
                <MdOutlineModeEdit />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
              <button
                className="btn with-tooltip btn-action-icon-delete"
                data-tooltip-content="Supprimer"
                style={{ color: "#E53E3E" }}
              >
                <FaTrash />
              </button>
            </div> */}
          </div>
        </>
      );
    };
    const nameFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <p className="name-profil-patient mb-0">{getName(row?.rdv?.patient)}</p>
        </div>
      );
    };

    const photoFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
            <div className="td-photo-container">
                {(row?.rdv?.patient?.avatar && !row?.rdv?.patient?.avatar?.includes("default.png")) ?
                  <img src={getAvatar(row?.rdv?.patient?.avatar)} alt="photo" />
                  :
                  <span className="format-pseudo">{getUserPseudo(row?.rdv?.patient)}</span>
                }
            </div>
        </div>
      );
    };

    const prixFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <p className="name-profil-patient mb-0">{row?.montant} FCFA</p>
        </div>
      );
    };

    const typeFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <p className="name-profil-patient mb-0">{row?.rdv?.format?.choix_consultation}</p>
        </div>
      );
    };

    const moyenFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <p className="name-profil-patient mb-0">
            {row?.rdv?.moyen_paiement == 'WAVE_SN_API_CASH_OUT' && ("Wave")}
            {row?.rdv?.moyen_paiement == 'ORANGE_SN_API_CASH_OUT' && ("Orange Money")}
            {row?.rdv?.moyen_paiement == 'BANK_TRANSFER_SN_API_CASH_OUT' && ("Virement bancaire")}
            {row?.rdv?.moyen_paiement == 'CASH' && ("CASH")}
          </p>
        </div>
      );
    };

    const columns = [
      {
        dataField: "nom",
        text: "Photos",
        formatter: photoFormatter,
      },
      {
        dataField: "nom",
        text: "Noms",
        formatter: nameFormatter,
      },
      {
        dataField: "created_at",
        text: "Date",
        formatter: (cell: any, row: any) => formatDate(cell)
      },
      // {
      //   dataField: "type",
      //   text: "Type",
      //   formatter: typeFormatter,
      // },
      {
        dataField: "amount",
        text: "montant",
        formatter: prixFormatter,
      },
      {
        dataField: "moyen",
        text: "Moyen",
        formatter: moyenFormatter,
      },
      {
        dataField: "id",
        text: "Action",
        formatter: actionFormatter,
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };


    return (
      <>
        {props?.isLoading && <TableSkeleton headers={columns} />}
        {!props?.isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results || []}
              columns={columns}
              // selectRow={selectRow}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={data?.count}
                  page={page}
                  onChange={onChange}
                  perPage={limit}
              />
              </div>
          </>
        )}
      </>
    );
}

export default RemboursementAdminTable;