import React, { useState, useEffect } from 'react';
import "./PraticiensAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import { SelectInput } from "../../common/SelectInput";
import { useLocation, useParams } from "react-router-dom";
import UseAddOrUpdatePraticienForm from "./requestForm/UseAddOrUpdatePraticienForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyPraticienBySlugQuery } from "../../../utils/api/praticien/praticien.api";
import { useGetAllSpecialiteListQuery } from "../../../utils/api/specialite/specialite.api";
import PhoneInput from "react-phone-input-2";
import { ISpecialite } from "../../../utils/api/specialite/specialite.type";
import GoogleInput from "../../common/GoogleInput";
import { GoogleAdresseType } from "../../../utils/type";

function AddOrUpdatePraticien() {

    const { data:specialites } = useGetAllSpecialiteListQuery();
    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyPraticienBySlugQuery();
    const [adresse, setAdresse] = useState<any>('');
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);
  
    const {
      register,
      errors,
      onSubmit,
      setValue,
      isLoading,
      handleChangeAvatar,
      handleChangeCv,
      phone,
      setPhone,
      handleChangePhone,
      handleReset,
    } = UseAddOrUpdatePraticienForm(data);
  
  
    const selectSpecialite = specialites?.results?.map((specialite: ISpecialite) => ({ label: specialite.nom, value: specialite.id }));
  
    const onChangeAdresse = (val: GoogleAdresseType) => {		
		if (val?.rue) {
            setAdresse(val?.rue)
			setValue(`adresse`, val?.rue);
		}else{
            setAdresse(val?.ville)
            setValue(`adresse`, val?.ville);
        }
	};

    useEffect(() => {
        setAdresse(data?.adresse)
            setValue(`adresse`, data?.adresse);
      }, [data]);


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        {!!data && data ?
            <BreadCrumb 
                pageUrl='/admin/praticiens'
                pageName="Praticiens"
                secondPage="Modifier un praticien"
            />
            :
            <BreadCrumb 
            pageUrl='/admin/praticiens'
            pageName="Praticiens"
            secondPage="Ajouter un praticien"
            />
        }
        <div className="content-graph-admin">
            <div className="custom-form mt-5">
                <form  onSubmit={onSubmit} >
                    <div className="row">
                        <div className="col-md-6">
                            <Input 
                                label='Nom'
                                type='text'
                                placeholder='Nom'
                                {...register("nom")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.nom && errors?.nom?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Prenom'
                                type='text'
                                placeholder='Prenom'
                                {...register("prenom")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.prenom && errors?.prenom?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Email'
                                type='email'
                                placeholder='Email'
                                {...register("email")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.email && errors?.email?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            {/* <Input 
                                label='Téléphone'
                                type='tel'
                                placeholder='77 000 00 00'
                            /> */}
                            <label 
                                htmlFor="telephone"
                                className="form-label form-label-modal-custom "
                            >
                                Téléphone<span className="text-danger" style={{ fontSize: 15 }}>*</span>
                            </label>
                            <PhoneInput
                                defaultMask={".. ... .. .."}
                                containerClass={`mb-3 p-0`}
                                inputClass={`form-control-modal-custom`}
                                country={"sn"}
                                placeholder=""
                                inputProps={{
                                name: "telephone",
                                required: true,
                                autoFocus: true,
                                }}
                                countryCodeEditable={false}
                                enableAreaCodes={true}
                                prefix="+"
                                value={phone}
                                onChange={(phone, country: any) =>
                                handleChangePhone(phone, country)
                                }
                                inputStyle={{
                                width: "100%",
                                height:"45px",
                                background: "rgba(232, 240, 253, 0.7)",
                                }}
                            />
                            <ErrorMessage
                                message={errors?.telephone && errors?.telephone?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            {/* <Input 
                                label='Adresse'
                                type='text'
                                placeholder='Adresse'
                                {...register("adresse")}
                            /> */}
                            <label
                                htmlFor='adresse'
                                className={`form-label form-label-modal-custom`}
                                aria-labelledby='adresse'
                            >
                                Adresse
                            </label>
                            <GoogleInput
                                className="form-control form-control-modal-custom input-parametre-form-add"
                                value={adresse}
                                onChange={onChangeAdresse}
                                placeholder="Adresse"
                            />
                            <ErrorMessage
                                message={errors?.adresse && errors?.adresse?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Date de naissance'
                                type='date'
                            {...register("date_de_naissance")}
                            />
                            <ErrorMessage
                                message={errors?.date_de_naissance && errors?.date_de_naissance?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Nationalité'
                                type='text'
                                placeholder='Nationalité'
                                required
                                {...register("nationnalite")}
                            />
                            <ErrorMessage
                                message={errors?.nationnalite && errors?.nationnalite?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='CNI'
                                type='text'
                                placeholder='CNI'
                                {...register("cni")}
                                onKeyDown={(event) => {
                                    if (!/\d/.test(event.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <ErrorMessage
                                message={errors?.cni && errors?.cni?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='N° Ordre des médecins'
                                type='text'
                                placeholder='N° Ordre des médecins'
                                {...register("ordre_des_medecins")}
                                />
                            <ErrorMessage
                                message={errors?.ordre_des_medecins && errors?.ordre_des_medecins?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Pays d’inscription Ordre des Médecins'
                                type='text'
                                placeholder='Pays d’inscription Ordre des Médecins'
                                {...register("pays_ordre_des_medecins")}
                            />
                            <ErrorMessage
                                message={errors?.pays_ordre_des_medecins && errors?.pays_ordre_des_medecins?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Date d’inscription'
                                type='date'
                                {...register("date_inscription_ordre")}
                            />
                            <ErrorMessage
                                message={errors?.date_inscription_ordre && errors?.date_inscription_ordre?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Upload son CV'
                                type='file'
                                onChange={(e) => handleChangeCv(e)}
                            />
                            <ErrorMessage
                                message={errors?.cv && errors?.cv?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Taux d’horaires'
                                type='number'
                                min={0}
                                placeholder='Taux d’horaires'
                                {...register("taux_horaire")}
                            />
                            <ErrorMessage
                                message={errors?.taux_horaire && errors?.taux_horaire?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            {/* <Input 
                                label='Spécialité'
                                type='text'
                                placeholder='Spécialité'
                                {...register("specialite")}
                            /> */}
                            <SelectInput label="Spécialité" options={selectSpecialite} {...register("specialite")} required/>

                            <ErrorMessage
                                message={errors?.specialite && errors?.specialite?.message}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                        <button
                            type="submit"
                            className="btn btn-theme-admin"
                            disabled={isLoading}
                        >
                            {isLoading  ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                    role="status"
                                ></span>
                                <span>
                                    {data ? "Modification..." : "Ajout..."}
                                </span>
                            </>
                            ) : data ? (
                                "Modifer"
                            ) : (
                                "Ajouter"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdatePraticien;

