import React, { useState } from "react";
import { Input } from "../../../common/Input";
import { BtnAnnulerModal, BtnSubmit } from "../../../common/Button";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import UseFormChangePassword from '../request/UseFormChangePassword';

function ChangerPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
		register,
		onSubmit,
		errors,
		isLoading,
		passwordHandleChange,
		haveMinCharacter,
		haveMinLowercase,
		haveMinNumber,
		haveMinSpecialCharacter,
		haveMinUppercase,
		passwordHandleReset,
		PasswordInput
	  } = UseFormChangePassword();


  return (
    <div className="custom-tab-content">
      <h4 className="custom-tab-title">
        Changer mot de passe
      </h4>
      <form className="mt-4" onSubmit={onSubmit}>
        <div className="row row-add-student my-3">
          <div className="col-md-4 position-relative">
            <Input
              type={showPassword ? "text" : "password"}
              label="Ancien mot de passe"
              id="old_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("old_password")}
              error={errors?.old_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          </div>
          <div className="col-md-4 position-relative">
            <Input
              type={showNewPassword ? "text" : "password"}
              label="Nouveau mot de passe"
              id="new_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              onChange={passwordHandleChange}
              error={errors?.new_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            >
              {showNewPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
            {PasswordInput && (
            <div className="col-md-12">
            <div className="input-group my-2 password-validation-terms-row">
            <div className="password-validation-terms-container">
              <h4 className="password-validation-terms-title">
              Votre mot de passe doit contenir :{" "}
              </h4>
              <ul className="password-validation-terms-ul p-l-14">
              <li
                className={
                "password-rule-item " +
                (haveMinCharacter && "text-success")
                }
              >
                Au minimum 8 caractères
              </li>
              <li
                className={
                "password-rule-item " +
                (haveMinUppercase && "text-success")
                }
              >
                Au minimum 1 caractère en majuscule
              </li>
              <li
                className={
                "password-rule-item " +
                (haveMinLowercase && "text-success")
                }
              >
                Au minimum 1 caractère en minuscule
              </li>
              <li
                className={
                "password-rule-item " +
                (haveMinNumber && "text-success")
                }
              >
                Au minimum 1 nombre
              </li>
              <li
                className={
                "password-rule-item " +
                (haveMinSpecialCharacter && "text-success")
                }
              >
                Au minimum 1 caractère spéciale
              </li>
              </ul>
            </div>
            </div>
          </div>
          )}
          </div>
          
          <div className="col-md-4 position-relative">
            <Input
              type={showConfirmPassword ? "text" : "password"}
              label="Confirmer mot de passe"
              id="confirm_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("confirm_password")}
              error={errors?.confirm_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          </div>
          <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
              <button
                  type="submit"
                  className="btn btn-theme-admin"
                  disabled={isLoading}
              >
                  {isLoading  ? (
                  <>
                      <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                      ></span>
                          <span>
                              Enregistrement...
                          </span>
                      </>)
                      : 
                      <span>Enregister</span> 
                    }
              </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangerPassword;
