import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IPromos } from "./promos.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  PromosFormData,
} from "./promos.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const PromosApi = createApi({
  reducerPath: "promos",
  tagTypes: [
    "promos",
    "promosList",
    "promosBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get promos pratricien 
   
      getPromos: builder.query<PaginationResults<IPromos>, TypeQuery>({
        query: (query) => QueryUrl(`promos/`, query),
        providesTags: ["promos"],
      }),
      promosBySlug: builder.query<IPromos | any, string>({
        query: (slug) => `promos/${slug}/`,
        providesTags: ["promosBySlug"],
      }),

      addOrUpdatePromos: builder.mutation<IPromos, { slug?: string; data: IPromos | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `promos/${slug}/` : `promos/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["promos"],
      }),
      deletePromos: builder.mutation<PromosFormData, string>({
        query: (slug) => ({
          url: `promos/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["promos"],
      }),


  }),
});

export const {
  useGetPromosQuery,
  useLazyPromosBySlugQuery,
  useAddOrUpdatePromosMutation,
  useDeletePromosMutation,
} = PromosApi;
