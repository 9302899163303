import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaAccessibleIcon, FaUserNurse } from "react-icons/fa";
import { AiTwotonePieChart, AiTwotoneSetting } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo-nav.svg";
import { MdFormatIndentIncrease } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";
import { MdOutlinePeople } from "react-icons/md";
import { GiPillDrop } from "react-icons/gi";
import { IoCashOutline } from "react-icons/io5";
import { FieldType } from "../../../../utils/constante";
import { useAppSelector } from "../../../../redux/hook";
import { MdPeopleOutline } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { CiTimer } from "react-icons/ci";

function SidebarAdmin() {
  const location = useLocation();
  const { user } = useAppSelector((s) => s?.user);

  const hasAccess = (type: FieldType) => {
    let isAccess = false;

    if (user?.user_type !== "superadmin" && !user[type]) {
      isAccess = true;
    }

    return isAccess;
  };

  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-3" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          {/* logo nav */}
          <div className="container-logo-sidebar-nav">
            <img src={Logo} alt="Logo" className="logo-sidebar-nav" />
          </div>
          {/* logo nav */}
          <ul className="nav nav-items-custom flex-column">
            {location?.pathname?.startsWith("/admin") && (
              <>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("dashboard")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotonePieChart />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("dashboard")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/utilisateurs"
                    data-tooltip-content="Utilisateurs"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdPeopleOutline />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Inscriptions
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("patients")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/patients"
                    data-tooltip-content="Patients"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdOutlinePeople />
                    </span>
                    <span className="hiddenable-navlink-label">Patients</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("medecins")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/praticiens"
                    data-tooltip-content="Praticiens"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaAccessibleIcon />
                    </span>
                    <span className="hiddenable-navlink-label">Praticiens</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("rdvs")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/rendez-vous"
                    data-tooltip-content="Rendez-vous"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <GiPillDrop />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Rendez-vous
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("paiements")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/historiques"
                    data-tooltip-content="Historiques"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <CiTimer />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Historiques
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("paiements")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/paiements"
                    data-tooltip-content="Paiements"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BsCreditCard />
                    </span>
                    <span className="hiddenable-navlink-label">Paiements</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("remboursements")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/remboursements"
                    data-tooltip-content="Rembousements"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoCashOutline />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Remboursements
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("messages")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("formats")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/formats"
                    data-tooltip-content="Formats"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdFormatIndentIncrease />
                    </span>
                    <span className="hiddenable-navlink-label">Formats</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/mon-profil"
                    data-tooltip-content="Mon profil"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserNurse />
                    </span>
                    <span className="hiddenable-navlink-label">Mon profil</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={hasAccess("parametres")}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
