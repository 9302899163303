import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { INotification } from "./notification.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  NotificationFormData,
} from "./notification.type";

import {
  IUser,
} from "../user/user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const NotificationApi = createApi({
  reducerPath: "notification",
  tagTypes: [
    "notification",
    "notificationList",
    "notificationBySlug",
    "notificationsByUser",
    "notificationsByAdmin",
    "notificationsNonLuByUser"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get notification pratricien 
   
      getNotification: builder.query<PaginationResults<INotification>, TypeQuery>({
        query: (query) => QueryUrl(`notifications/`, query),
        providesTags: ["notification"],
      }),
      notificationBySlug: builder.query<INotification | any, string>({
        query: (slug) => `notification/${slug}/`,
        providesTags: ["notificationBySlug"],
      }),
      getNotificationsAdmin: builder.query<any, void>({
        providesTags: ["notificationsByAdmin"],
        query: () => {
          return "notifications/";
        },
      }),
      notificationsByUser: builder.query<IUser | any, string>({
        query: (slug) => `user/${slug}/notifications/`,
        providesTags: ["notificationsByUser"],
      }),
      notificationsNonLuByUser: builder.query<IUser | any, string>({
        query: (slug) => `user/${slug}/notifications/`,
        providesTags: ["notificationsNonLuByUser"],
      }),
      addOrUpdateNotification: builder.mutation<INotification, { slug?: string; data: INotification | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `notifications/${slug}/` : `notifications/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["notification","notificationsByUser","notificationsByAdmin","notificationsNonLuByUser"],
      }),
      updateNotificationRead: builder.mutation<INotification, { slug?: string; data: INotification | FormData }>({
        query: ({ slug, data }) => ({
          url: `notifications/${slug}/`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["notification","notificationsByUser","notificationsByAdmin","notificationsNonLuByUser"],
      }),
      deleteNotification: builder.mutation<NotificationFormData, string>({
        query: (slug) => ({
          url: `notifications/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["notification"],
      }),


  }),
});

export const {
  useGetNotificationQuery,
  useLazyNotificationBySlugQuery,
  useAddOrUpdateNotificationMutation,
  useUpdateNotificationReadMutation,
  useDeleteNotificationMutation,
  useGetNotificationsAdminQuery,
  useLazyNotificationsByUserQuery,
  useLazyNotificationsNonLuByUserQuery,

} = NotificationApi;
