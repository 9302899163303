import React, { useState } from "react";
import "./Register.css";
import PhoneInput from "react-phone-input-2";

function Register() {
const [telephone, setTelephone] = useState("")
const [code, setCode] = useState<string>("");
 const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setTelephone("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setTelephone;
    } else {
      setTelephone;
    }
  };
  return (
    <>
      <div className="custom-page-register">
        <div className="">
          <div className="">
            <div className="register-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pb-2 pt-5">
                  <img src="../../assets/appImages/logo.png" alt="logo" className="logo-auth-page" />
                </div>
                <h2 className="title-auth-form-text pt-5">S’inscrire</h2>
                <div className="mt-5">
                  <form id="auth-form">
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-register">
                            Prénom
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="first-name"
                            aria-label="First name"
                            placeholder="Prénom"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="">
                          <label className="form-label form-label-auth-register">
                            Nom
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="last-name"
                            aria-label="Last name"
                            placeholder="Nom"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="">
                          <label className="form-label form-label-auth-register">
                            Adresse mail
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email"
                            aria-label="Email"
                            placeholder="Adresse mail"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="">
                          <label className="form-label form-label-auth-register">
                            N° téléphone portable
                          </label>
                          <div className="input-group">
                            <PhoneInput
                              defaultMask={".. ... .. .."}
                              containerClass="mb-3"
                              inputClass="react-custom-tel-input"
                              country={"sn"}
                              placeholder=""
                              inputProps={{
                                name: "telephone",
                                required: true,
                                autoFocus: true,
                              }}
                              countryCodeEditable={false}
                              enableAreaCodes={true}
                              prefix="+"
                              value={telephone}
                              onChange={(phone, country) =>
                                handleChangePhone(phone, country)
                              }
                              inputStyle={{
                                width: "100%",
                                borderRadius: "6px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="">
                          <label className="form-label form-label-auth-register">
                            CNI
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="cni"
                            aria-label="CNI"
                            placeholder="CNI"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-5 mb-3">
                        <button
                       // to="/admin/dashboard"
                        className="btn auth-submit-btn trans-0-2"
                      >Inscription</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
