import React,{useState, useEffect} from 'react'
import "./RdvAdmin.css"
import RdvAdminTable from "./tables/RdvAdminTable";
import BreadCrumb from '../../shared/BreadCrumb';
import { 
  MdKeyboardArrowDown, 
  MdKeyboardArrowLeft, 
  MdKeyboardArrowRight 
} from 'react-icons/md';
import { IoCall, IoFilterSharp } from "react-icons/io5";
import { BsCheckCircle, BsFillChatDotsFill, BsXCircle, BsClockHistory, BsArrowClockwise } from "react-icons/bs";
import { FaVideo } from "react-icons/fa";
import { usePagination, useSearch } from "../../../utils/hooks";
import { useGetRdvsQuery } from "../../../utils/api/rdvs/rdvs.api";
import { IRdvs } from "../../../utils/api/rdvs/rdvs.type";
import { NavLink } from 'react-router-dom';
import { MdAddCircleOutline } from 'react-icons/md';
import moment from "moment";

function RdvAdmin() {

  const { search, handleSearch } = useSearch();
  const [rdvs, setRdvs] = useState<any>([]);
  const [date, setDate] = useState<any>(null);
  const [query, setQuery] = useState("");
  const { limit, page, onChange } = usePagination(10);
  const { data, isLoading , refetch } = useGetRdvsQuery({
    page: page,
    limit: limit,
    date: date ? moment(date).format('DD-MM-Y') : null ,
    q: query,
  });

  const onChangeDate = (e) => {
    setDate(e.target.value)
    refetch()
  }

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/rendez-vous'
          pageName="Rendez-vous"
        />
        <div className="content-graph-admin">
          <div className="container-title-dash gap-4 pb-4">
            <h3 className="custom-top-title">
              Liste des rendez-vous
            </h3>
            <div className="filtered-rdv-on-date-container custom-filtered-text">
              {/* <button className="filter-scope-btn">
                <MdKeyboardArrowLeft style={{ fontSize: 18 }} />
              </button>
              <span className='px-2'>
                05 Août 2022 {" "} &nbsp;
                <em>Aujourd'hui</em> <MdKeyboardArrowDown />
              </span>
              <button className="filter-scope-btn">
                <MdKeyboardArrowRight style={{ fontSize: 18 }} />
              </button> */}
              <input
                type="search"
                className="form-control form-control-search"
                placeholder="Tapez pour rechercher"
                onChange={(e) => setQuery(e?.target?.value)}
              />
              <input onChange={(e) => onChangeDate(e)} className="form-control form-control-modal-custom" type="date" /> {" "} &nbsp;
              
            </div>
            <NavLink to="/admin/rendez-vous/ajouter-rendez-vous" 
                  className="btn custom-add-btn"
              >
                  <span className="add-icon pe-2">
                      <MdAddCircleOutline />
                  </span>
                  <span>Ajouter un Rendez vous</span>
              </NavLink>
          </div>
          
          <div className="custom-table-content pt-3">
            <RdvAdminTable rdvs={data} isLoading={isLoading} limit={limit} page={page} onChange={onChange} />
          </div>

        </div>
      </div>
    </div>
  )
}

export default RdvAdmin;
