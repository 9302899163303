import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IRemboursement } from "./remboursements.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
    RemboursementFormData,
} from "./remboursements.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
    const token =
        (getState() as { user: AuthState }).user.token ??
        AppStorage.getItem<AuthState>("user")?.token;
    if (token) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
};

export const RemboursementApi = createApi({
    reducerPath: "remboursement",
    tagTypes: [
        "remboursement",
        "remboursementList",
        "remboursementBySlug",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${ApiBaseUrl}/api/`,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getRemboursements: builder.query<PaginationResults<IRemboursement>, TypeQuery>({
            query: (query) => QueryUrl(`remboursements/`, query),
            providesTags: ["remboursement"],
        }),
        getRemboursementBySlug: builder.query<IRemboursement | any, string>({
            query: (slug) => `remboursements/${slug}/`,
            providesTags: ["remboursementBySlug"],
        }),
        // addOrUpdateRemboursement: builder.mutation<IRemboursement, { slug?: string; data: RemboursementFormData | FormData }>({
        //     query: ({ slug, data }) => ({
        //         url: slug ? `promos/${slug}/` : `promos/`,
        //         method: slug ? "PUT" : "POST",
        //         body: data,
        //     }),
        //     invalidatesTags: ["promos"],
        // }),
        // deleteRemboursement: builder.mutation<RemboursementFormData, string>({
        //     query: (slug) => ({
        //         url: `promos/${slug}/`,
        //         method: "DELETE",
        //     }),
        //     invalidatesTags: ["promos"],
        // }),
    }),
});

export const {
    useGetRemboursementsQuery,
    useLazyGetRemboursementBySlugQuery,
    // useAddOrUpdateRemboursementMutation,
    // useDeleteRemboursementMutation,
} = RemboursementApi;