import React, { useState } from 'react';
import "./PraticiensAdmin.css";
import { MdAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import BreadCrumb from '../../shared/BreadCrumb';
import PraticiensAdminTable from './tables/PraticiensAdminTable';
import { ApiBaseUrl } from "../../../utils/http";
import { useAppSelector } from '../../../redux/hook';


function PraticiensAdmin() {
  const { token } = useAppSelector((state) => state.user);
  const [query, setQuery] = useState("");
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/praticiens'
          pageName="Praticiens"
        />
        <div className="content-graph-admin">
        <div className="container-title-dash gap-4 pb-4">
            <NavLink to="/admin/praticiens/ajouter-praticien" 
                className="btn custom-add-btn"
            >
                <span className="add-icon pe-2">
                    <MdAddCircleOutline />
                </span>
                <span>Ajouter un praticien</span>
            </NavLink>
            <div className='mon-doc-btn-filter-container gap-4 pb-4'>
            <input
                type="search"
                className="form-control form-control-search"
                placeholder="Tapez pour rechercher"
                onChange={(e) => setQuery(e?.target?.value)}
              />
            <button  
                className="btn custom-extract-btn custom-extract-btn-light"
                onClick={(e) => {
                  e?.preventDefault();
                  // window?.open(`${ApiBaseUrl}/api/export_medecin`)?.focus();
                  const url = `${ApiBaseUrl}/api/export_medecin/`;
                  const currentDate = new Date().toISOString().split('T')[0];
                  const fileName = `export_medecin${currentDate}.xls`;

                  fetch(url, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${token}`
                    }
                  })
                  .then(response => {
                    if (response.ok) {
                      return response.blob();
                    }
                    throw new Error("Problème lors de l'exportation");
                  })
                  .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  })
                  .catch(error => console.error('Erreur:', error));
                }}
            >
                <span className="download-icon pe-2">
                    <HiOutlineDownload />
                </span>
                <span>Extraction</span>
            </button>
            </div>
          </div>
          
          <div className="custom-table-content pt-3">
            <PraticiensAdminTable q={query} />
          </div>

        </div>
      </div>
    </div>
  )
}

export default PraticiensAdmin

