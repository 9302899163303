/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IOrdonnance,
  OrdonnanceFormData
} from "./ordonnance.type";

export const OrdonnanceApi = createApi({
  reducerPath: "ordonnanceApi",
  tagTypes: ["ordonnanceList", "ordonnanceBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListOrdonnancesAdmin: builder.query<PaginationResults<IOrdonnance>, TypeQuery>({
      providesTags: ["ordonnanceList"],
      query: (query) => QueryUrl("ordonnances/", query),
    }),

    addOrEditOrdonnance: builder.mutation<IOrdonnance, { slug?: string; data: IOrdonnance | FormData }>({
      query: ({ slug, data }) => ({
        url: slug ? `ordonnances/${slug}/` : `ordonnances/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["ordonnanceList"],
    }),
    deleteOrdonnance: builder.mutation<IOrdonnance, string>({
      query: (slug) => ({
        url: `ordonnances/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ordonnanceList"],
    }),
    ordonnanceBySlug: builder.query<IOrdonnance | any, string>({
      query: (slug) => `ordonnances/${slug}/`,
      providesTags: ["ordonnanceBySlug"],
    }),
  
  }),
});

export const {
  useAddOrEditOrdonnanceMutation,
  useGetListOrdonnancesAdminQuery,
  useLazyOrdonnanceBySlugQuery,
  useDeleteOrdonnanceMutation,
} = OrdonnanceApi;
