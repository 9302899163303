import React from 'react';
import "./FormatsAdmin.css";
import { MdAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import BreadCrumb from '../../shared/BreadCrumb';
import FormatsAdminTable from "./tables/FormatsAdminTable";
import { ApiBaseUrl } from "../../../utils/http";


function FormatsAdmin() {

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/formats'
          pageName="Formats"
        />
        <div className="content-graph-admin">
        <div className="container-title-dash gap-4 pb-4">
            <NavLink 
                to="/admin/formats/ajouter-format" 
                className="btn custom-add-btn"
            >
                <span className="add-icon pe-2">
                    <MdAddCircleOutline />
                </span>
                <span>Ajouter un format</span>
            </NavLink>
          </div>

          <div className="custom-tab-content pt-3">
            <FormatsAdminTable />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormatsAdmin

