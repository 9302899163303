import React, { useState, useEffect } from "react";
import "./MessagesContacts.css";
import BreadCrumb from "../../shared/BreadCrumb";
import { Input } from "../../common/Input";
import photo_patient from "../../../assets/appImages/profilFormat.png";
import { MdCheck, MdCircle } from "react-icons/md";
import { FaSkullCrossbones } from "react-icons/fa";
import { AllRdvItemData } from "./tables";
import {
  useLazyContactBySlugQuery,
  useSendResponseContactMutation,
} from "../../../utils/api/contact/contact.api";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { createMarkup, formatDate, getAvatar, readMarkup } from "../../../utils/Utils";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";

function MesaageContac() {
  const { slug } = useParams<{ slug: string }>();
  const [reponse, setReponse] = useState("");
  const [responseLoading, setResponseLoading] = useState(false);
  const [responseIsEmpty, setResponseIsEmpty] = useState(false);
  const { auth } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [findBySlug, { data }] = useLazyContactBySlugQuery();

  const [sendMessage, { isLoading, isSuccess, error, isError }] =
    useSendResponseContactMutation();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);

  const onResponseMessageContact = async () => {
    if (reponse?.length === 0) {
      setResponseIsEmpty(true);
      setTimeout(() => {
        setResponseIsEmpty(false);
      }, 5000);
    } else {
      setResponseLoading(true);
      let reponseMessage: any = {};

      let dataReponse: any = {};

      dataReponse["message"] = data?.id;
      dataReponse["response"] = reponse;
      dataReponse["user"] = data?.user?.id ? data?.user?.id : data?.user;

      await sendMessage(dataReponse);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Message envoyé avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setResponseIsEmpty(false);
        setResponseLoading(false);
        navigate("/admin/messages");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.phone) {
        Swal.fire({
          icon: "error",
          title: err?.data?.phone[0],
          showConfirmButton: false,
          timer: 5000,
        });
      } else if (err?.data?.email) {
        Swal.fire({
          icon: "error",
          title: err?.data?.email[0],
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb
          pageUrl="/admin/messages"
          pageName="Messages"
          secondPage="Détail d'un message"
        />
        <div className="custom-detail-content">
          <div className="top-curved-container">
            <h3 className="custom-top-title">Détails message</h3>
          </div>
          <div className="photo-and-general-infos mt-1">
            <div className="row">
              <div className="col-md-12">
                <div className="custom-wrapper">
                  <div className="custom-information-content pb-5">
                    <div className="row">
                      <div className="col-md-6">
                        <h6>Prenon et Nom</h6>
                        <p>
                          {data?.user?.prenom} {data?.user?.nom}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h6>Email</h6>
                        <p>{data?.email}</p>
                      </div>
                      <div className="col-md-6">
                        <h6>Objet</h6>
                        <p>{data?.subject}</p>
                      </div>
                      <div className="col-md-12">
                        <h6>Message</h6>
                        <p>{data?.message}</p>
                      </div>
                      <div className="col-md-12">
                        <form id="response-message-form">
                          <div className="response-message-col">
                            <div className="response-message-container">
                              <label className="response-message-label">
                                Repondre
                              </label>
                              <ReactQuill
                                className="form-control-quill"
                                theme="snow"
                                value={reponse}
                                onChange={setReponse}
                              />
                            </div>
                            {responseIsEmpty && (
                              <div className="alert alert-danger form-alert-danger">
                                Le message ne doit pas etre vide
                              </div>
                            )}
                          </div>
                          <div className="response-message-submit-btn-col m-t-5">
                            <button
                              className="btn btn-theme-admin m-t-10"
                              disabled={responseLoading}
                              onClick={(e: any) => {
                                e.preventDefault();
                                onResponseMessageContact();
                              }}
                            >
                              {responseLoading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                    role="status"
                                  ></span>
                                  <span>En cours ...</span>
                                </>
                              ) : (
                                "Répondre"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data?.responses?.length > 0 && (
            <div className="top-curved-container flex-column align-items-start gap-2 mt-3">
              <h3 className="custom-top-title">
                {`Réponse${data?.responses?.length > 1 ? "s" : ""} précédente${
                  data?.responses?.length > 1 ? "s" : ""
                }`}
              </h3>
              <div className="row">
              </div>
              {data?.responses.map((rep: any) => (
                <div className="col-12 mb-2">
                    <div dangerouslySetInnerHTML={createMarkup(rep?.response)} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MesaageContac;
