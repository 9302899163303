import React, { useState } from 'react';
import "./PatientsAdmin.css";
import { MdAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import BreadCrumb from '../../shared/BreadCrumb';
import PatientsAdminTable from "./tables/PatientsAdminTable";
import { ApiBaseUrl } from "../../../utils/http";


function PatientsAdmin() {
  const [query, setQuery] = useState("");
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/parametres'
          pageName="Patients"
        />
        <div className="content-graph-admin">
        <div className="container-title-dash gap-4 pb-4">
            <NavLink 
                to="/admin/patients/ajouter-patient" 
                className="btn custom-add-btn"
            >
                <span className="add-icon pe-2">
                    <MdAddCircleOutline />
                </span>
                <span>Ajouter un patient</span>
            </NavLink>
            <div className='mon-doc-btn-filter-container gap-4 pb-4'>
            <input
                type="search"
                className="form-control form-control-search"
                placeholder="Tapez pour rechercher"
                onChange={(e) => setQuery(e?.target?.value)}
              />
            <button 
                className="btn custom-extract-btn"
                onClick={(e) => {
                  e?.preventDefault();
                  window?.open(`${ApiBaseUrl}/api/export_patient`)?.focus();
                }}
            >
                <span className="download-icon pe-2">
                    <HiOutlineDownload />
                </span>
                <span>Extraction</span>
            </button>
              </div>
          </div>

          <div className="custom-tab-content pt-3">
            <PatientsAdminTable q={query} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientsAdmin

