import React from 'react';
import { SnippetsItemData } from '../tables';
import { NavLink } from 'react-router-dom';
import SnippetItem from '../items/SnippetItem';
import {
  useGetListConditionsAdminQuery,
} from "../../../../utils/api/condition/condition.api";
import { useDelete, usePagination, useSearch } from '../../../../utils/hooks';

const ConditionsUtilisations = () => {

  const { limit, page, onChange } = usePagination(10);

  const { data, isLoading } = useGetListConditionsAdminQuery({
    page: page,
    limit: limit,
  });

  return (
    <div className="custom-tab-content">
       {data?.results?.filter((x)=>x?.type =="CGU")?.length == 0 && (
        <div className="top-curved-container mb-1">
            <div className="col-md-2">
                <NavLink
                    className="btn btn-theme-admin"
                    to={"/admin/parametres/ajouter-condition"}
                >
                  Ajouter
                </NavLink>
            </div>
        </div>
       )}
        <div className="bg-colgate">
          <div className="list-all-conditions">
          {!!data?.results &&
            data?.results?.filter((x)=>x?.type =="CGU")?.map((condition, key) => {
              return(
                <div className="wrapper" key={key}>
                  <SnippetItem 
                    condition={condition}
                    key={key}
                  />
                </div>
              )
            })
          }
          </div>
        </div>
    </div>
  );
};

export default ConditionsUtilisations;
