import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit } from "react-icons/md";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatDate, getAvatar } from "../../../../utils/Utils";
import {
  DeletePatient,
} from "../requestForm/UseAddOrUpdatePatientForm";
import { getUserPseudo } from "../../../../utils/Utils";
import { usePagination } from "../../../../utils/hooks";
import { useGetPatientQuery } from "../../../../utils/api/patient/patient.api";
import { AiFillEye } from "react-icons/ai";
import { CustomPagination } from "../../../common/CustomPagination";

function PatientsAdminTable({ q }) {
  const navigate = useNavigate();

  const { limit, page, onChange } = usePagination(10);

  const { data, isLoading } = useGetPatientQuery({
    page: page,
    limit: limit,
    q: q,
  });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
        {/* <div className="container-btn-action-icon-modal">
              {row?.is_active ? <DeactivePatient item={row} /> : <ActivePatient item={row} />}
            </div> */}
        <div className="container-btn-action-icon-modal">
          <NavLink
            to={`/admin/patients/detail-patient/${row?.slug}`}
            className="btn btn-action-icon-see with-tooltip"
            data-tooltip-content="Voir"
          >
            <AiFillEye />
          </NavLink>
        </div>
        <div className="container-btn-action-icon-modal">
          <NavLink
            to={`/admin/patients/modifier-patient/${row?.slug}`}
            className="btn btn-action-icon-edit with-tooltip"
            data-tooltip-content="Modifier"
          >
            <MdEdit />
          </NavLink>
        </div>
        <div className="container-btn-action-icon-modal">
          <DeletePatient item={row} />
        </div>
      </div>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-start align-items-center row gap-3">
        <div className="td-photo-container col-md-4">
          {row?.avatar && !row?.avatar?.includes("default.png") ? (
            <img src={getAvatar(row?.avatar)} alt="photo" />
          ) : (
            <span className="format-pseudo">{getUserPseudo(row)}</span>
          )}
        </div>
        <p className="name-profil-patient mb-0 col-md-8">
          {row?.prenom} {row?.nom}
        </p>
      </div>
    );
  };

  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-center align-items-center gap-3">
        <p className="name-profil-patient mb-0">
          {formatDate(row?.created_at)}
        </p>
      </div>
    );
  };

  const numeroFormatter = (cell: any, row: any, index: number) => {
    return (
      <div className="d-flex justify-content-center align-items-center row gap-3">
        <p className="name-profil-patient mb-0 col-md-8">
          {page > 1 ? limit * (page - 1) + index + 1 : index + 1}{" "}
        </p>
      </div>
    );
  };
  const adresseFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        {cell}
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: numeroFormatter,
    },
    {
      dataField: "nom",
      text: "Noms",
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "350px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "adresse",
      text: "Adresse",
      formatter: adresseFormatter,
      headerStyle: () => {
        return { width: "350px", whiteSpace: "normal", textAlign: "left" };
      },
    },
    {
      dataField: "civilite",
      text: "Genre",
    },
    {
      dataField: "created_at",
      text: "Date d’ajout",
      formatter: dateFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      navigate(`/admin/patients/detail-patient/${row.slug}`);
    },
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={onChange}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PatientsAdminTable;
