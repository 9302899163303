import photo from "../../../../assets/appImages/user.png";

export const PaiementAdminTableData = [
    {
        id: 1,
        slug: "paiement-1",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
    {
        id: 2,
        slug: "paiement-2",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
    {
        id: 3,
        slug: "paiement-3",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
    {
        id: 4,
        slug: "paiement-4",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
    {
        id: 5,
        slug: "paiement-5",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
    {
        id: 6,
        slug: "paiement-6",
        photo: photo,
        nom: "Fama Kebe",
        date: "21/02/2023",
        type: "Prestation",
        amount: "150 000 FCFA",
        moyen: "PayPal",
    },
]