import React, { useState , useEffect} from "react";
import { Input } from "../../common/Input";
import BreadCrumb from "../../shared/BreadCrumb";
import UseAddOrUpdateUserForm from "./requestForm/UseAddOrUpdateUserForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyUserBySlugQuery } from "../../../utils/api/user/user.api";
import { useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { formatDate, formatMontant, getAvatar } from '../../../utils/Utils';


function UserDetail() {
    const [subAdmin, setSubAdmin] = useState<any>();

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyUserBySlugQuery();
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);

    const {
        register,
        errors,
        onSubmit,
        setValue,
        isLoading,
      } = UseAddOrUpdateUserForm(data);


    return(
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container mb-3">
                    <BreadCrumb
                        pageUrl='/admin/utilisateurs' 
                        pageName="Paramètres"
                        secondPage="Utilisateurs"
                    />
                    <div className="custom-add-form-content">
                        <div className="top-curved-container">
                            <h3 className="custom-top-title">
                            Details d'un utilisateur       
                            </h3>
                        </div>
                        <div className="custom-wrapper">
                            <div className="custom-form mt-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="prenon">Prenon :</label>
                                            <p id='prenon'>{data?.prenom}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Non">Nom :</label>
                                            <p id='Non'>{data?.nom}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Email">Email :</label>
                                            <p id='Email'>{data?.email}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Téléphone">Téléphone :</label>
                                            <p id='Téléphone'>{data?.telephone}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="date_de_naissance">Date de naissance :</label>
                                            <p id='date_de_naissance'>{formatDate(data?.date_de_naissance)}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Adresse">Adresse :</label>
                                            <p id='Adresse'>{data?.adresse}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="Boite">Boite postale :</label>
                                            <p id='Boite'>{data?.boite_postal}</p>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <label htmlFor="Nationnalité">Nationnalité :</label>
                                            <p id='Nationnalité'>{data?.nationnalite}</p>
                                        </div>
                                        {data?.user_type == 'medecin' && (
                                        <>
                                        <div className="col-md-6">
                                            <label htmlFor="ordre_des_medecins">Ordre des medecins :</label>
                                            <p id='ordre_des_medecins'>{data?.ordre_des_medecins}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="pays_ordre_des_medecins">Pays ordre des medecins :</label>
                                            <p id='pays_ordre_des_medecins'>{data?.pays_ordre_des_medecins}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="date_inscription_ordre">Date inscription ordre :</label>
                                            <p id='date_inscription_ordre'>{formatDate(data?.date_inscription_ordre)}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="specialite">Spécialite :</label>
                                            <p id='specialite'>{data?.specialite?.nom}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="duree_rdv">Durée Rendez-vous:</label>
                                            <p id='duree_rdv'>{data?.duree_rdv}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="taux_horaire">Taux horaire :</label>
                                            <p id='taux_horaire'>{data?.taux_horaire}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="toujours_disponible">Toujours disponible :</label>
                                            <p id='toujours_disponible'>{data?.toujours_disponible ? "OUI" : "NON"}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="telemedecine">Télémédecine :</label>
                                            <p id='telemedecine'>{data?.telemedecine ? "OUI" : "NON"}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="presentiel"> Présentiel :</label>
                                            <p id='presentiel'>{data?.presentiel ? "OUI" : "NON"}</p>
                                        </div>
                                        </>
                                        )}
                                    </div>
                                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                                    <NavLink
                                        className="nav-link forget-password-link text-center"
                                        to="/admin/utilisateurs"
                                        >
                                        Retour
                                    </NavLink>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetail;