export const regions = [
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Diourbel",
    value: "Diourbel",
  },
  {
    label: "Fatick",
    value: "Fatick",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack",
  },
  {
    label: "kédougou",
    value: "kédougou",
  },
  {
    label: "Kolda",
    value: "Kolda",
  },
  {
    label: "Louga",
    value: "Louga",
  },
  {
    label: "Matam",
    value: "Matam",
  },
  {
    label: "Saint-Louis",
    value: "Saint-Louis",
  },
  {
    label: "Sédhiou",
    value: "Sédhiou",
  },
  {
    label: "Tambacounda",
    value: "Tambacounda",
  },
  {
    label: "Thiès",
    value: "Thiès",
  },
  {
    label: "Ziguinchor",
    value: "Ziguinchor",
  },
];

export type FieldType =
	| "dashboard"
  | "patients"
  | "medecins"
	| "rdvs"
	| "paiements"
	| "remboursements"
	| "messages"
  | "formats"
	| "parametres"


type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		field: "patients",
		label: "Patients",
		path: "/admin/patients",
	},
	{
		field: "medecins",
		label: "Praticiens",
		path: "/admin/praticiens",
	},
	{
		field: "rdvs",
		label: "Rendez-vous",
		path: "/admin/rendez-vous",
	},
	{
		field: "paiements",
		label: "Paiements",
		path: "/admin/paiements",
	},
	{
		field: "remboursements",
		label: "Remboursements",
		path: "/admin/remboursements",
	},
	{
		field: "messages",
		label: "Messages",
		path: "/admin/messages",
	},
  {
		field: "formats",
		label: "Formats",
		path: "/admin/formats",
	},
	{
		field: "parametres",
		label: "Paramètres",
		path: "/admin/parametres",
	},
];
