import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { DemandeApi } from "../utils/api/demande/demande.api";
import { PraticienApi } from "../utils/api/praticien/praticien.api";
import { PatientApi } from "../utils/api/patient/patient.api";
import { SpecialiteApi } from "../utils/api/specialite/specialite.api";
import { ReponseDemandeApi } from "../utils/api/reponseDemande/reponseDemande.api";
import { GestionnaireApi } from "../utils/api/gestionnaire/gestionnaire.api";
import { AnnonceApi } from "../utils/api/annonce/annonce.api";
import { MessagerieApi } from "../utils/api/messagerie/messagerie.api";
// import { StoreApi } from "../utils/api/store/store.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import storage from "redux-persist/lib/storage";
import useCartReducer from "./slice/useCartSlice";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { DisponibiliteApi } from "../utils/api/disponibilite/disponibilite.api";
import { RdvsApi } from "../utils/api/rdvs/rdvs.api";
import { PrestationApi } from "../utils/api/prestations/prestations.api";
import { ConditionApi } from "../utils/api/condition/condition.api";
import { CertificatApi } from "../utils/api/certificat/certificat.api";
import { OrdonnanceApi } from "../utils/api/ordonnance/ordonnance.api";
import { BulletinApi } from "../utils/api/bulletin/bulletin.api";
import { FichierApi } from "../utils/api/fichier/fichier.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { PromosApi } from "../utils/api/promos/promos.api";
import { PaiementApi } from "../utils/api/paiements/paiements.api";
import { FormatApi } from "../utils/api/format/format.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { AproposApi } from "../utils/api/apropos/apropos.api";
import { RemboursementApi } from "../utils/api/remboursements/remboursements.api";


const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    `${UserSlice.name}`,
    `${UserApi.reducerPath}`,
    `${AuthApi.reducerPath}`,
    `${DisponibiliteApi.reducerPath}`,
    `${DemandeApi.reducerPath}`,
    `${PraticienApi.reducerPath}`,
    `${RdvsApi.reducerPath}`,
    `${PrestationApi.reducerPath}`,
    `${SpecialiteApi.reducerPath}`,
    `${ReponseDemandeApi.reducerPath}`,
    `${PatientApi.reducerPath}`,
    `${GestionnaireApi.reducerPath}`,
    `${AnnonceApi.reducerPath}`,
    `${ConditionApi.reducerPath}`,
    `${CertificatApi.reducerPath}`,
    `${OrdonnanceApi.reducerPath}`,
    `${BulletinApi.reducerPath}`,
    `${FichierApi.reducerPath}`,
    `${NotificationApi.reducerPath}`,
    `${PromosApi.reducerPath}`,
    `${MessagerieApi.reducerPath}`,
    `${PaiementApi.reducerPath}`,
    `${FormatApi.reducerPath}`,
    `${ContactApi.reducerPath}`,
    `${AproposApi.reducerPath}`,
    `${RemboursementApi.reducerPath}`,



  ],
};

export const rootReducers = combineReducers({
  cart: useCartReducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [UserSlice.name]: UserSlice.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [DisponibiliteApi.reducerPath]: DisponibiliteApi.reducer,
  [DemandeApi.reducerPath]: DemandeApi.reducer,
  [PraticienApi.reducerPath]: PraticienApi.reducer,
  [RdvsApi.reducerPath]: RdvsApi.reducer,
  [SpecialiteApi.reducerPath]: SpecialiteApi.reducer,
  [PrestationApi.reducerPath]: PrestationApi.reducer,
  [PatientApi.reducerPath]: PatientApi.reducer,
  [ReponseDemandeApi.reducerPath]: ReponseDemandeApi.reducer,
  [GestionnaireApi.reducerPath]: GestionnaireApi.reducer,
  [AnnonceApi.reducerPath]: AnnonceApi.reducer,
  [ConditionApi.reducerPath]: ConditionApi.reducer,
  [CertificatApi.reducerPath]: CertificatApi.reducer,
  [OrdonnanceApi.reducerPath]: OrdonnanceApi.reducer,
  [BulletinApi.reducerPath]: BulletinApi.reducer,
  [FichierApi.reducerPath]: FichierApi.reducer,
  [NotificationApi.reducerPath]: NotificationApi.reducer,
  [PromosApi.reducerPath]: PromosApi.reducer,
  [MessagerieApi.reducerPath]: MessagerieApi.reducer,
  [PaiementApi.reducerPath]: PaiementApi.reducer,
  [FormatApi.reducerPath]: FormatApi.reducer,
  [ContactApi.reducerPath]: ContactApi.reducer,
  [AproposApi.reducerPath]: AproposApi.reducer,
  [RemboursementApi.reducerPath]: RemboursementApi.reducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    AuthApi.middleware,
    UserApi.middleware,
    DisponibiliteApi.middleware,
    DemandeApi.middleware,
    PraticienApi.middleware,
    RdvsApi.middleware,
    SpecialiteApi.middleware,
    PrestationApi.middleware,
    PatientApi.middleware,
    ReponseDemandeApi.middleware,
    GestionnaireApi.middleware,
    AnnonceApi.middleware,
    ConditionApi.middleware,
    CertificatApi.middleware,
    OrdonnanceApi.middleware,
    BulletinApi.middleware,
    FichierApi.middleware,
    NotificationApi.middleware,
    PromosApi.middleware,
    MessagerieApi.middleware,
    PaiementApi.middleware,
    FormatApi.middleware,
    ContactApi.middleware,
    AproposApi.middleware,
    RemboursementApi.middleware,

  ],
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
