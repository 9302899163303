import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import { FaTrash } from "react-icons/fa";
import { MdEdit, MdOutlineTimer } from "react-icons/md";
import TableSkeleton from '../../../common/TableSkeleton';
import { NavLink } from "react-router-dom";
import { SousAdminsTableData } from '.';
import { AiFillEye } from 'react-icons/ai';
import { getAvatar, getUserPseudo ,formatDate} from "../../../../utils/Utils";
import { DeleteUser } from "../requestForm/UseAddOrUpdateUserForm"
import { CustomPagination } from '../../../common/CustomPagination';



function SousAdminsTable({listItem, isLoading, onChange, page,limit}) {

  
    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="d-flex justify-content-center gap-3">
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/parametres/voir-sous-admin/${row?.slug}`}
                className="btn btn-action-icon-see with-tooltip"
                data-tooltip-content="Voir"
              >
                <AiFillEye />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/parametres/modifier-sous-admin/${row?.slug}`}
                className="btn btn-action-icon-edit with-tooltip"
                data-tooltip-content="Modifier"
              >
                <MdEdit />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
              {/* <button
                className="btn with-tooltip btn-action-icon-delete"
                data-tooltip-content="Supprimer"
                style={{ color: "#E53E3E" }}
              >
                <FaTrash />
              </button> */}
              <DeleteUser item={row} />
            </div>
          </div>
        </>
      );
    };
    const nameFormatter = (cell: any, row: any) => {
        return (
          <div className="d-flex justify-content-center align-items-center row gap-3">
              <div className="td-photo-container col-md-4">
                {(row?.avatar && !row?.avatar?.includes("default.png")) ?
                  <img src={getAvatar(row?.avatar)} alt="photo" />
                  :
                  <span className="format-pseudo">{getUserPseudo(row)}</span>
                }
              </div>
            <p className="name-profil-patient mb-0 col-md-8">{row?.prenom} {row?.nom}</p>
          </div>
        );
      };

    const numeroFormatter = (cell: any, row: any, index: number ) => {
      return (
        <div className="d-flex justify-content-center align-items-center row gap-3">
          <p className="name-profil-patient mb-0 col-md-8">{ (page > 1 ? (limit*(page-1))+index+1 : index+1)} </p>
        </div>
      );
    };

      const dateFormatter = (cell: any, row: any) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <p className="name-profil-patient mb-0">{formatDate(row?.created_at)}</p>
          </div>
        );
      };

    const columns = [
      {
        dataField: "id",
        text: 'N°',
        formatter: numeroFormatter,
      },
      {
        dataField: "nom",
        text: 'Noms et Photos',
        formatter: nameFormatter,
      },
      {
        dataField: "add_date",
        text: "Date d’ajout",
        formatter: dateFormatter,
      },
      {
        dataField: "profession",
        text: "Profession",
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "adresse",
        text: "Adresse",
      },
      {
        dataField: "id",
        text: "Action",
        formatter: actionFormatter,
      },
    ];

    return (
      <>
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              defaultSorted
              keyField="id"
              data={listItem?.results || []}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={listItem?.count}
                  page={page}
                  onChange={onChange}
                  perPage={limit}
              />
            </div>
          </>
        )}
      </>
    );
}

export default SousAdminsTable