import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import visa from "../../../../assets/icons/visa.png";
import mastercard from "../../../../assets/icons/mastercard.png";
import { PraticiensPaimentTableData } from "../tables";
import { formatDate, formatMontant, getAvatar, getName,getUserPseudo } from '../../../../utils/Utils';
import { CustomPagination } from '../../../common/CustomPagination';
import { IoFilterSharp } from 'react-icons/io5';
import { AiFillEye } from 'react-icons/ai';
import { NavLink } from "react-router-dom";


function PraticiensPaiementTable({paiements,isLoading}) {

const [pages, setPages] = useState(PraticiensPaimentTableData)


const actionFormatter = (cell: any, row: any) => {
  return (
    <>
      <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
        
        <div className="container-btn-action-icon-modal">
          <NavLink
            to={`/admin/paiements/detail-paiement/${row?.slug}`}
            className="btn btn-action-icon-see with-tooltip"
            data-tooltip-content="Voir"
          >
            <AiFillEye />
          </NavLink>
        </div>
      </div>
    </>
  );
};
const nameFormatter = (cell: any, row: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <p className="name-profil-patient mb-0">{getName(row?.rdv?.patient)}</p>
    </div>
  );
};

const photoFormatter = (cell: any, row: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
        <div className="td-photo-container pt-2">
            
            {(row?.rdv?.patient?.avatar && !row?.rdv?.patient?.avatar?.includes("default.png")) ?
              <img src={getAvatar(row?.rdv?.patient?.avatar)} alt="photo" />
              :
              <span className="format-pseudo">{getUserPseudo(row?.rdv?.patient)}</span>
            }
        </div>
    </div>
  );
};

const prixFormatter = (cell: any, row: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <p className="name-profil-patient mb-0">{row?.montant} FCFA</p>
    </div>
  );
};

const typeFormatter = (cell: any, row: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <p className="name-profil-patient mb-0">{row?.rdv?.format?.choix_consultation}</p>
    </div>
  );
};

const moyenFormatter = (cell: any, row: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3">
      <p className="name-profil-patient mb-0">
        {row?.rdv?.moyen_paiement == 'WAVE_SN_API_CASH_OUT' && ("Wave")}
        {row?.rdv?.moyen_paiement == 'ORANGE_SN_API_CASH_OUT' && ("Orange Money")}
        {row?.rdv?.moyen_paiement == 'BANK_TRANSFER_SN_API_CASH_OUT' && ("Virement bancaire")}
        {row?.rdv?.moyen_paiement == 'CASH' && ("CASH")}
      </p>
    </div>
  );
};

const columns = [
  {
    dataField: "nom",
    text: "Photos",
    formatter: photoFormatter,
  },
  {
    dataField: "nom",
    text: "Noms",
    formatter: nameFormatter,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell: any, row: any) => formatDate(row?.rdv?.date)
  },
  {
    dataField: "type",
    text: "Type",
    formatter: typeFormatter,
  },
  {
    dataField: "amount",
    text: "montant",
    formatter: prixFormatter,
  },
  {
    dataField: "moyen",
    text: "Moyen",
    formatter: moyenFormatter,
  },
  {
    dataField: "id",
    text: "Action",
    formatter: actionFormatter,
  },
];
  const selectRow = {
    mode: "checkbox",
  };
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={paiements?.results || []}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive paiement-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
        </>
      )}
    </>
  );
}

export default PraticiensPaiementTable;
