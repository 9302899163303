/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { NotificationFormData , INotification } from "../../../../utils/api/notification/notification.type";
import { useAddOrUpdateNotificationMutation , useDeleteNotificationMutation , useUpdateNotificationReadMutation } from "../../../../utils/api/notification/notification.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";


function UseAddOrUpdateNotificationForm(func?:any,notification?:INotification) {

  const validationSchema = yup.object().shape({
    // contenu: yup.string().required("Le contenu est obligatoire"),
    // statut: yup.string().required("Le statut est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<NotificationFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateNotification, { isLoading, isSuccess, error, isError }] = useAddOrUpdateNotificationMutation();
  const [updateNotificationRead ] = useUpdateNotificationReadMutation()

  const handleChangeStatutNotif = (data:INotification) => { 
    
    let fd = new FormData();
    
    // Object.entries(data).forEach(([key, value]) => {
    //   if(value != "" && value != undefined && value != null)
    //   {
    //     if(key == "read")
    //     {
    //       fd.append( key, 'true');
    //     }
    //     else{
    //       fd.append( key, value);
    //     }
    //   }
    // });

    fd.append( "read", 'true');

    updateNotificationRead({
      slug: data?.slug,
      data: fd,
    });

  }

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Notification ${notification ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 6000,
      }).then(() => {
        navigate('/admin/mon-profil')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (notification?.id) {
      let fields: (keyof NotificationFormData)[] = [
        "slug",
        "content",
        "data",
        "notif_type" ,
        "is_archived",
        "read",
        "receiver",
        "admins" ,
      ];
      for (let field of fields) {
          register(field);
          setValue(field, notification[field])
          
      }

    }
  }, [notification]);

  const onSubmit = async (data: NotificationFormData) => {
  
    let fd = new FormData();
    
    Object.entries(data).forEach(([key, value]) => {
      if(value != "" && value != undefined)
      {
        fd.append( key, value);
      }
    });
      
    await addOrUpdateNotification({ slug: notification?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeStatutNotif,
  };
}

export function DeleteNotification({ item }: { item: INotification }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteNotificationMutation();
  const onDelete = useDelete<INotification>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce notification",
    successMessage: "Notification supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}


export default UseAddOrUpdateNotificationForm;
