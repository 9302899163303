/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IBulletin,
  BulletinFormData
} from "./bulletin.type";

export const BulletinApi = createApi({
  reducerPath: "bulletinApi",
  tagTypes: ["bulletinList", "bulletinBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListBulletinsAdmin: builder.query<PaginationResults<IBulletin>, TypeQuery>({
      providesTags: ["bulletinList"],
      query: (query) => QueryUrl("bulletin_examens/", query),
    }),

    addOrEditBulletin: builder.mutation<IBulletin, { slug?: string; data: IBulletin | FormData }>({
      query: ({ slug, data }) => ({
        url: slug ? `bulletin_examens/${slug}/` : `bulletin_examens/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["bulletinList"],
    }),
    deleteBulletin: builder.mutation<IBulletin, string>({
      query: (slug) => ({
        url: `bulletin_examens/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["bulletinList"],
    }),
    bulletinBySlug: builder.query<IBulletin | any, string>({
      query: (slug) => `bulletin_examens/${slug}/`,
      providesTags: ["bulletinBySlug"],
    }),
  
  }),
});

export const {
  useAddOrEditBulletinMutation,
  useGetListBulletinsAdminQuery,
  useLazyBulletinBySlugQuery,
  useDeleteBulletinMutation,
} = BulletinApi;
