import React from 'react';
import "./PaiementAdmin.css";
import { MdAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import BreadCrumb from '../../shared/BreadCrumb';
import PaiementAdminTable from './tables/PaiementAdminTable';


function PaiementAdmin() {

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
          pageUrl='/admin/paiements'
          pageName="Paiements"
        />
        <div className="content-graph-admin">
        <div className="container-title-dash gap-4 pb-4">
            <h3 className="custom-top-title">
                Tout les paiements
            </h3>
            {/* <button  
                className="btn custom-extract-btn custom-extract-btn-light"
            >
                <span className="download-icon pe-2">
                    <HiOutlineDownload />
                </span>
                <span>Extraction</span>
            </button> */}
          </div>
          
          <div className="custom-table-content pt-3">
            <PaiementAdminTable />
          </div>

        </div>
      </div>
    </div>
  )
}

export default PaiementAdmin

