import React, { useState ,useEffect } from 'react';
import "./PatientsAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import photo_patient from "../../../assets/appImages/profilPatient.png";
import PatientsPaimentTable from './tables/PatientsPaimentTable';
import { MdCheck, MdCircle } from "react-icons/md";
import { FaSkullCrossbones } from "react-icons/fa";
import { AllRdvItemData } from './tables';
import { useLazyPatientBySlugQuery , useLazyPaiementByPatientQuery } from "../../../utils/api/patient/patient.api";
import { useLocation, useParams } from "react-router-dom";
import { formatDate, getAvatar ,getUserPseudo} from "../../../utils/Utils";


const RdvItem = (
    {praticienName, speciality, hour, status}:
    {
        praticienName?: string, 
        speciality?: string, 
        hour?: string, 
        status?: string
    }
) => {
    return(
        <div 
            className={`
                rdv-sm-item
                ${status === 'passé' ? 'past' : ''}
                ${status === 'en cours' ? 'inprogress' : ''}
                ${status === 'annulé' ? 'canceled' : ''}
            `}
        >
            <div className="name-and-speciality d-flex justify-content-between align-items-center pb-2">
                <span className="name">{praticienName}</span>
                <span className="speciality">{speciality}</span>
            </div>
            <div className="hour-and-status d-flex align-items-center gap-4">
                <span className="hour">{hour}</span>
                {!!status && status === "passé" &&
                    <span className='status'>
                        <MdCheck style={{ color: '#219653', fontSize: 10 }} />
                    </span>
                }
                {!!status && status === "en cours" &&
                    <span className='status'>
                        <MdCircle style={{ color: '#F2994A', fontSize: 11 }} />
                    </span>
                }
                {!!status && status === "annulé" &&
                    <span className='status'>
                        <FaSkullCrossbones style={{ color: '#EB5757', fontSize: 10 }} />
                    </span>
                }
            </div>
        </div>
    )
}


function PatientDetail() {

    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError ,isLoading }] = useLazyPatientBySlugQuery();

    const [findByPaiement, { data:paiements  }] = useLazyPaiementByPatientQuery();
    
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
        findByPaiement(slug as string);
      }
    }, [slug]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageUrl='/admin/parametres'
            pageName="Patients"
            secondPage="Détail d'un patient"
        />
        <div className="custom-detail-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Détails patient
                </h3>
            </div>
            <div className="photo-and-general-infos mt-1">
                <div className="row">
                    <div className="col-md-3">
                        <div className="custom-wrapper">
                            <div className="photo-container pb-5">
                                <h4 className="custom-title pb-3">
                                    Photo
                                </h4>
                                <div className="photo-wrapper">
                                    {(data?.avatar && !data?.avatar?.includes("default.png")) ?
                                        <img src={getAvatar(data?.avatar)} alt="photo" />
                                        :
                                        <span className="format-pseudo">{getUserPseudo(data)}</span>
                                    }
                                </div>
                            </div>
                            <div className="rdv-container">
                                <h4 className="custom-title pb-3">
                                    Rendez-vous
                                </h4>
                                <div className="all-rdv-wrapper">
                                    <h5 className="custom-rdv-status-title incoming">
                                        À venir
                                    </h5>
                                    <div className="rdv-items-container pt-2 pb-5">
                                        <div className="row">
                                        {!!data?.next_rdvs &&
                                            data?.next_rdvs.map((rdv, key) => {
                                                return(
                                                    <div className="col-md-12" key={key}>
                                                        <RdvItem 
                                                            praticienName={rdv?.medecin?.prenom+' '+rdv?.medecin?.nom}
                                                            speciality={rdv?.medecin?.specialite?.nom}
                                                            hour={rdv?.heure_de_debut}
                                                            status={rdv?.status}
                                                            key={key}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                        {(!isLoading && data?.next_rdvs?.length == 0 )  && (<p> Pas de rendez vous à venir</p>)}
                                        </div>
                                    </div>
                                    <h5 className="custom-rdv-status-title past">
                                        Passés
                                    </h5>
                                    <div className="rdv-items-container pt-2">
                                        <div className="row">
                                            {!!data?.past_rdvs &&
                                                data?.past_rdvs.map((rdv, key) => {
                                                    return(
                                                        <div className="col-md-12" key={key}>
                                                            <RdvItem 
                                                                praticienName={rdv?.medecin?.prenom+' '+rdv?.medecin?.nom}
                                                                speciality={rdv?.medecin?.specialite?.nom}
                                                                hour={rdv?.heure_de_debut}
                                                                status={rdv?.status}
                                                                key={key}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                            {(!isLoading && data?.past_rdvs?.length == 0 )  && (<p> Pas de rendez vous passés</p>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="custom-wrapper">
                            <div className="custom-information-content pb-5">
                                <h4 className="custom-title pb-3">
                                    Information générale
                                </h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Input 
                                            label='Prénom'
                                            type='text'
                                            value={data?.prenom}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Nom'
                                            type='text'
                                            value={data?.nom}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label='Téléphone'
                                            type='tel'
                                            value={data?.telephone}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input 
                                            label='Email'
                                            type='email'
                                            value={data?.email}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input 
                                            label='Adresse'
                                            type='text'
                                            value={data?.adresse}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="payment-content">
                                <h4 className="custom-title pb-3">
                                    Paiements
                                </h4>
                                <div className="patient-paiement-table">
                                    <PatientsPaimentTable paiements={paiements} isLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PatientDetail;

