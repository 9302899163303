import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery,IUser } from "../user/user.type";
import { IPatient } from "./patient.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  PatientFormData,
} from "./patient.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const PatientApi = createApi({
  reducerPath: "patient",
  tagTypes: [
    "patient",
    "patientList",
    "patientBySlug",
    "ordonnancesByPatient" ,
    "ayantDroitsByPatient",
    "rdvsByPatient",
    "rdvsAvenirByPatient",
    "rdvsTerminerByPatient",
    "documentsByPatient",
    "exportPatient",
    "paiementByPatient",
    "allPatientsList"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


      getAllPatientsList: builder.query<any, void>({
        providesTags: ["allPatientsList"],
        query: () => {
          return "patients/";
        },
      }),

    // get patient pratricien 
      getPatient: builder.query<PaginationResults<IPatient>, TypeQuery>({
        query: (query) => QueryUrl(`patients/`, query),
        providesTags: ["patient"],
      }),
      patientBySlug: builder.query<IPatient | any, string>({
        query: (slug) => `patients/${slug}/`,
        providesTags: ["patientBySlug"],
      }),
      paiementByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/paiements/`,
        providesTags: ["paiementByPatient"],
      }),
      ordonnancesByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/ordonnances/`,
        providesTags: ["ordonnancesByPatient"],
      }),
      ayantDroitsByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/ayant_droits/`,
        providesTags: ["ayantDroitsByPatient"],
      }),
      rdvsByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/rdvs/`,
        providesTags: ["rdvsByPatient"],
      }),
      rdvsAvenirByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/rdvs_a_venir/`,
        providesTags: ["rdvsAvenirByPatient"],
      }),
      rdvsTerminerByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/rdvs_terminer/`,
        providesTags: ["rdvsTerminerByPatient"],
      }),
      documentsByPatient: builder.query<IPatient | any, string>({
        query: (slug) => `patient/${slug}/documents/`,
        providesTags: ["documentsByPatient"],
      }),
      addOrUpdatePatient: builder.mutation<IPatient, { slug?: string; data: IPatient | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `patients/${slug}/` : `patients/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["patient","patientList"],
      }),
      deletePatient: builder.mutation<PatientFormData, string>({
        query: (slug) => ({
          url: `patients/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["patient","patientList"],
      }),
      activeUser: builder.mutation<IUser | any, string>({
        query: (slug) => ({
          url: `medecin/account/activation/${slug}/`,
          method: "GET",
        }),
        invalidatesTags: ["patient"],
      })


  }),
});

export const {
  useGetAllPatientsListQuery,
  useGetPatientQuery,
  useLazyPatientBySlugQuery,
  useLazyPaiementByPatientQuery,
  useLazyOrdonnancesByPatientQuery,
  useLazyAyantDroitsByPatientQuery,
  useLazyRdvsByPatientQuery,
  useLazyRdvsAvenirByPatientQuery,
  useLazyRdvsTerminerByPatientQuery,
  useAddOrUpdatePatientMutation,
  useDeletePatientMutation,
  useActiveUserMutation,
  useLazyDocumentsByPatientQuery,
} = PatientApi;
