import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IRdvs, RdvsFormData } from "./rdvs.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { Dashboard } from "./rdvs.type";


export const RdvsApi = createApi({
    reducerPath: "rdvsApi",
    tagTypes: ["rdv",'dashboard'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${ApiBaseUrl}/api/`,
        prepareHeaders,
    }),
    endpoints: (builder) => ({addOrUpdateRdv: builder.mutation<IRdvs,{ slug?: string; data: RdvsFormData | FormData }>({
            query: ({ slug, data }) => {
                if (slug) {
                    return {
                        url: `rdvs/${slug}/`,
                        method: "PUT",
                        body: data,
                    };
                }
                return {
                    url: `rdvs/`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["rdv"],
        }),
        getRdvs: builder.query<PaginationResults<IRdvs>, TypeQuery>({
            query: (query) => QueryUrl(`rdvs/`, query),
            providesTags: ["rdv"],
        }),
        getDashboards: builder.query<Dashboard, TypeQuery>({
            providesTags: ["dashboard"],
            query: (query) => QueryUrl("dashboard/", query),
        }),
        rdvBySlug: builder.query<IRdvs | any, string>({
            query: (slug) => `rdvs/${slug}/`,
            providesTags: ["rdv"],
        }),
        getRdvsByPraticien: builder.query<IRdvs[], TypeQuery>({
            query: ({ slug, ...query }) =>
                QueryUrl(`praticien/${slug}/rdvs/`, query),
            providesTags: ["rdv"],
        }),
        deleteRdv: builder.mutation<RdvsFormData, string>({
            query: (slug) => ({
                url: `rdvs/${slug}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["rdv"],
        }),
    })
})
export const {
    useAddOrUpdateRdvMutation,
    useDeleteRdvMutation,
    useGetRdvsQuery,
    useGetDashboardsQuery,
    useLazyRdvBySlugQuery,
    useGetRdvsByPraticienQuery
} = RdvsApi