import React, { useState } from 'react'
import SpecialitesTable from '../tables/SpecialitesTable';
import AddSpecialitesModal from '../Modals/AddSpecialitesModal';
import { usePagination, useSearch } from '../../../../utils/hooks';
import { useGetSpecialiteQuery } from '../../../../utils/api/specialite/specialite.api';
import { onShow } from "../../../../utils/Utils";

function Specialites() {


    const [showModal, setShowModal] = useState<boolean>(false);

    function openModalSpecialiste() {
      onShow("AddSpecialisteModal");
    }

    
    return (
      <>
        <div className="administrator-param-tab-container">
          <div className="liste-administrator-container">
            {/* <div className="d-flex justify-content-between align-items-center">
              <div>

              </div>
              <button 
                className="btn btn-theme-body-page"
                onClick={() => setShowModal(true)}
              >
                Ajouter une spécialité
              </button>
              
            </div> */}
            <div className="top-curved-container">
                <div className="col-md-2">
                    <button
                        onClick={(e) => openModalSpecialiste()}
                        className="btn btn-theme-admin"
                        >Ajouter une spécialité
                    </button>
                </div>
            </div>
            <div className="all-admins-table pt-3">
              <SpecialitesTable />
            </div>
            <AddSpecialitesModal/>
          </div>
        </div>
      </>
    );
}

export default Specialites