import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery, } from "../user/user.type";
import { IPaiement } from "./paiements.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
    PaiementFormData,
} from "./paiements.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
    const token =
        (getState() as { user: AuthState }).user.token ??
        AppStorage.getItem<AuthState>("user")?.token;
    if (token) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
};

export const PaiementApi = createApi({
    reducerPath: "paiement",
    tagTypes: [
        "paiement",
        "paiementList",
        "paiementBySlug",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${ApiBaseUrl}/api/`,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getPaiements: builder.query<PaginationResults<IPaiement>, TypeQuery>({
            query: (query) => QueryUrl(`paiements/`, query),
            providesTags: ["paiement"],
        }),
        getPaiementBySlug: builder.query<IPaiement | any, string>({
            query: (slug) => `paiements/${slug}/`,
            providesTags: ["paiementBySlug"],
        }),
        // addOrUpdatePaiement: builder.mutation<IPaiement, { slug?: string; data: PaiementFormData | FormData }>({
        //     query: ({ slug, data }) => ({
        //         url: slug ? `promos/${slug}/` : `promos/`,
        //         method: slug ? "PUT" : "POST",
        //         body: data,
        //     }),
        //     invalidatesTags: ["promos"],
        // }),
        // deletePaiement: builder.mutation<PaiementFormData, string>({
        //     query: (slug) => ({
        //         url: `promos/${slug}/`,
        //         method: "DELETE",
        //     }),
        //     invalidatesTags: ["promos"],
        // }),
    }),
});

export const {
    useGetPaiementsQuery,
    useLazyGetPaiementBySlugQuery,
    // useAddOrUpdatePaiementMutation,
    // useDeletePaiementMutation,
} = PaiementApi;