/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { ConditionFormData , ICondition } from "../../../../utils/api/condition/condition.type";
import { useAddOrEditConditionMutation  , useDeleteConditionMutation } from "../../../../utils/api/condition/condition.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";
import { IoMdTrash } from "react-icons/io";


function UseAddOrUpdateConditionForm(condition?: ICondition) {

  const [contenu, setContenu] = useState('');

  const validationSchema = yup.object().shape({
   // type: yup.string().required("Le type est obligatoire"),
    text: yup.string().required("La description est obligatoire"),
   // condition_type: yup.string().required("Le téléphone est obligatoire"),
    // specialite: yup.string().required("Le specialité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<ConditionFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateCondition, { isLoading, isSuccess, error, isError }] =
  useAddOrEditConditionMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Condition ${condition ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/parametres')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("L'email a déjà été pris.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (condition?.id) {
      let fields: (keyof ConditionFormData)[] = [
        "slug",
        "titre",
        "text",
        "text_fr",	
        "type",	
      ];
      for (let field of fields) {
          register(field);
        
          setValue(field, condition[field])
          
      }

      setContenu(condition?.text);

    }
  }, [condition]);

  const onSubmit = async (data: ICondition) => {
      
    await addOrUpdateCondition({ slug: condition?.slug, data: data });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    contenu,
    setContenu,
  };
}

export function DeleteCondition({ item }: { item: ICondition }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteConditionMutation();
  const onDelete = useDelete<ICondition>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet condition",
    successMessage: "Condition supprimé",
  });
  return (
    <button
      className="dropdown-item"
      onClick={onDelete}
    >
      Supprimé
    </button>
  );
}



export default UseAddOrUpdateConditionForm;
