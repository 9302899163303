/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { PatientFormData , IPatient } from "../../../../utils/api/patient/patient.type";
import { useAddOrUpdatePatientMutation , useDeletePatientMutation,useActiveUserMutation } from "../../../../utils/api/patient/patient.api";
import { Color } from "../../../../utils/theme";
import { FaTrash,FaToggleOff , FaToggleOn } from "react-icons/fa";
import { useDelete, useActive, useDesactive } from "../../../../utils/helpers";
import { UserFormData , IUser } from "../../../../utils/api/user/user.type";
import {  useArchiveUserMutation , useUnArchiveUserMutation } from "../../../../utils/api/user/user.api";


function UseAddOrUpdatePatientForm(patient?: IPatient) {

  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Le prenom est obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    email: yup.string().email("L'email est invalide").required("L'email est obligatoire"),
    telephone: yup.string().required("Le téléphone est obligatoire"),
    // specialite: yup.string().required("Le specialité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control, 
    reset
  } = useForm<PatientFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const [phone, setPhone] = useState<string | null>(null);
  const [code, setCode] = useState<string>("");
  const navigate = useNavigate();


  const [addOrUpdatePatient, { isLoading, isSuccess, error, isError }] =
  useAddOrUpdatePatientMutation();

  const handleChangeAvatar = (e) => { 
    let files = e.target.files[0];
    setValue("avatar" , files)
  }

  const handleReset = () => {
    if (!patient) {
      setPhone("+221");
      reset();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Patient ${patient ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/patients')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      }
      else if (err?.data?.telephone) {
        Swal.fire({
          icon: "error",
          title: err?.data?.telephone[0],
          showConfirmButton: false,
          timer: 5000,
        });

      }
      else if (err?.data?.email) {
        Swal.fire({
          icon: "error",
          title: err?.data?.email[0],
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (patient?.id) {
      let fields: (keyof PatientFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "specialite",
        "slug",
        "nom",
        "password",	
        "last_login",		
        "is_archive",	
        "longitude",	
        "latitude",
        "password_reset_count",	
        "user_type",	
        "civilite",	
        "first_connexion",
        "token",	
        "bio",	
        "hopital",	
        "maladie",	
        "delais",	
        "deletion_id",	
        "deletion_type",	
        "condition",	
        "specialite",
        "tension",	
        "pouls",	
        "allergie",	
        "poids",	
        "taille",
        "groupe_sanguin",
      ];
      for (let field of fields) {
          register(field);
          setValue(field, patient[field])
          
      }
      setPhone(patient?.telephone);

    }
  }, [patient]);

  const handleChangePhone = (item: any, country: any) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const onSubmit = async (data: PatientFormData) => {
  
    let fd = new FormData();
    
    Object.entries(data).forEach(([key, value]) => {
      if(value != "" && value != undefined)
      {
        fd.append( key, value);
      }
    });
      
    fd.append("user_type", 'patient');

    await addOrUpdatePatient({ slug: patient?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeAvatar,
    phone,
    setPhone,
    handleChangePhone,
    handleReset,
  };
}

export function DeletePatient({ item }: { item: IPatient }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeletePatientMutation();
  const onDelete = useDelete<IPatient>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce patient",
    successMessage: "Patient supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export function useToggleArchivePatient(user: IUser) {
 
  const [editData] = user?.is_archive ? useUnArchiveUserMutation() : useArchiveUserMutation();
  const onArchive = async () => {
    // let data: UserFormData = {
    //   is_archive: !user?.is_archive,
    // };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet patient ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.email);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Patient ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}

export function ActivePatient({ item }: { item: IPatient }) {

  const [activeItem, {  isSuccess, isError, error }] =
    useActiveUserMutation();
  const onActive = useActive<IPatient>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "activé ce patient " ,
    successMessage: "Patient activé" ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Activé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
       <FaToggleOff />
    </button>
  );
}

export function DeactivePatient({ item }: { item: IPatient }) {

  const [activeItem, {  isSuccess, isError, error }] =
    useActiveUserMutation();
  const onActive = useDesactive<IPatient>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "désactivé ce patient " ,
    successMessage: "Patient désactivé"  ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Désactivé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
      <FaToggleOn />  
    </button>
  );
}


export default UseAddOrUpdatePatientForm;
