/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { FormatFormData , IFormat } from "../../../../utils/api/format/format.type";
import { useAddOrUpdateFormatMutation , useDeleteFormatMutation } from "../../../../utils/api/format/format.api";
import { Color } from "../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/helpers";
import { UserFormData , IUser } from "../../../../utils/api/user/user.type";
import { useArchiveUserMutation, useUnArchiveUserMutation } from "../../../../utils/api/user/user.api";


function UseAddOrUpdateFormatForm(format?: IFormat) {

  const validationSchema = yup.object().shape({
    nom: yup.string().required("Le nom est obligatoire"),
    description: yup.string().required("Le description est obligatoire"),
    type_format: yup.string().label('Type de format').nullable(),
    choix_consultation: yup.string().required("Le choix de consultation est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control, 
    reset
  } = useForm<FormatFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateFormat, { isLoading, isSuccess, error, isError }] =
  useAddOrUpdateFormatMutation();

  const handleChangeIcone = (e) => { 
    let file = e.target.files[0];
    setValue("icone" , file)
  }


  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Format ${format ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate('/admin/formats')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.telephone) {
        Swal.fire({
          icon: "error",
          title: err?.data?.telephone[0],
          showConfirmButton: false,
          timer: 5000,
        });

      }
      else if (err?.data?.email) {
        Swal.fire({
          icon: "error",
          title: err?.data?.email[0],
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (format?.id) {
      let fields: (keyof FormatFormData)[] = [
        "nom",
        "icone",
        "description",
        "type_format",
        "prix",
        "choix_consultation",
        "slug",	
      ];
      for (let field of fields) {
          register(field);
          setValue(field, format[field])
      }

    }
  }, [format]);


  const onSubmit = async (data: FormatFormData) => {
  
    let fd = new FormData();
    
    Object.entries(data).forEach(([key, value]) => {
      if(value != "" && value != undefined)
      {
        fd.append( key, value);
      }
    });
      
    await addOrUpdateFormat({ slug: format?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeIcone,
    
  };
}

export function DeleteFormat({ item }: { item: IFormat }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteFormatMutation();
  const onDelete = useDelete<IFormat>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce format",
    successMessage: "Format supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}


export default UseAddOrUpdateFormatForm;
