import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";


function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email("L'email est invalide").required("L'email est obligatoire"),
    password: yup.string().required("Le mot de passe est obligatoire"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] = useLoginUserMutation();

  useEffect(() => {
    if (isSuccess) {

      // if (data) {
      //   socket.emit('newUser', { userName: data?.data?.prenom+' '+data?.data?.nom , socketID: socket.id });
      //   dispatch(
      //     onSetUserToken({ user: data?.data, token: data?.token })
      //   );
      // }
      if (data?.data?.user_type === "admin" || data?.data?.user_type === "superadmin" ) {
        dispatch(
          onSetUserToken({ user: data?.data, token: data?.token })
        );
        Swal.fire({
          icon: "success",
          title: "Connexion reussie!",
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 5000,
        }).then(() => {
          navigate("/admin/dashboard");
        });

      } else {
        navigate("/");
      } 
      reset();
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      setError("email", {
        message: err?.data?.message
          ? err?.data?.message
          : err?.status < 500
          ? "Email ou mot de passe incorrect"
          : `Une erreur de statut ${err?.status} est survenue.`,
      });
      // if (
      //   err?.data?.message?.includes(
      //     "Vous n'avez pas valider votre numéro de téléphone"
      //   )
      // ) 
      // {
      //   if (window.location.pathname?.includes("inscription")) {
      //     navigate("/");
      //   } 
        // else {
        //   showModal("phoneModal");
        // }
        
        
      // }
        setTimeout(() => {
          cleannerError(errors, clearErrors);
        }, 3000);
      //   Swal.fire({
      //     icon: "error",
      //     title: err?.data?.message
      //       ? err?.data?.message
      //       : err?.status < 500
      //       ? "Email ou mot de passe incorrect"
      //       : `Une erreur de statut ${err?.status} est survenue.`,
      //     showConfirmButton: false,
      //     timer: 5000,
      //   });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    // console.log("data login", data);
    await loginUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
