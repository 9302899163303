import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { ISpecialite, SpecialiteFormData } from "../../../../utils/api/specialite/specialite.type";
import { useAppSelector } from "../../../../redux/hook";
import { useAddOrUpdateSpecialiteMutation } from "../../../../utils/api/specialite/specialite.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { QueryError } from "../../../../utils/type";

function useCrudSpecialite(closeModal: () => void, item?: ISpecialite) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<SpecialiteFormData>({
		resolver: yupResolver(validationSchema),
	});
	const { user } = useAppSelector((s) => s?.user);
	const [sendData, { isLoading }] = useAddOrUpdateSpecialiteMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
		}
	}, [item]);

	const handleChangeImage = (e) => { 
		let file = e.target.files[0];
		setValue("image" , file)
	}

	const onSubmit = async (data: SpecialiteFormData) => {

		let fd = new FormData();
    
		Object.entries(data).forEach(([key, value]) => {
		if(value != "" && value != undefined)
		{
			fd.append( key, value);
		}
		});
		
		const res = await sendData({ slug: item?.slug as string, data:fd });
		if ("data" in res) {
			closeModal();
			reset();
			Swal.fire({
				icon: "success",
				title: `Specialité ${item ? "modifiée" : "ajoutée"} avec succès`,
				showConfirmButton: false,
				timer: 2000,
				iconColor: Color.themeColor,
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err.data?.message ?? "Une erreur inconnue a survenu",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		handleChangeImage,
	};
}

export default useCrudSpecialite;