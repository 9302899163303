export const RdvAdminTableDatas = [
    {
        id: 1,
        slug: "rdv-1",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "audio",
        statut: "En attente"
    },
    {
        id: 2,
        slug: "rdv-2",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "message",
        statut: "Visité"
    },
    {
        id: 3,
        slug: "rdv-3",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "video",
        statut: "Programmé"
    },
    {
        id: 4,
        slug: "rdv-4",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "audio",
        statut: "Programmé"
    },
    {
        id: 5,
        slug: "rdv-5",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "audio",
        statut: "En attente"
    },
    {
        id: 6,
        slug: "rdv-6",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "message",
        statut: "En attente"
    },
    {
        id: 7,
        slug: "rdv-7",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "video",
        statut: "Visité"
    },
    {
        id: 8,
        slug: "rdv-8",
        time: "10h30 - 11h00",
        date: "21/02/2023",
        patient: "Paul Gomis",
        praticien: "Fama Kebe",
        type: "Télémedecine",
        tarification: "Gratuit",
        format: "audio",
        statut: "En attente"
    },
]