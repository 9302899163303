import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { Address, ChangePasswordData, LoginResult } from "../auth/auth.type";
import {
  AddressFormData,
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
  UserFormData,
  UserType,
  AdminFormData,
  Admin,
  Historique
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "userById", "adminsList",'dashboard','userBySlug','userListRegister','historique'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "user/me/";
      },
    }),
    addOrEditUser: builder.mutation<Admin,{ slug?: string; data: AdminFormData | FormData }>({
      invalidatesTags: ["user", "adminsList" , "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditAdmin: builder.mutation<Admin,{ slug?: string; data: AdminFormData | FormData }>({
      invalidatesTags: ["user", "adminsList", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditUserAdresse: builder.mutation<Address,{ id?: string; data: AddressFormData }>({
      invalidatesTags: ["user", "userList", "adminsList"],
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `user/adresse/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/adresse`,
          method: "POST",
          body: data,
        };
      },
    }),
    getInfoDashbordAdmin: builder.query<any, void>({
      providesTags: ["dashboard"],
      query: () => {
        return "admin/dashboard/";
      },
    }),
  
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/change-password/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getListUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: (query) => QueryUrl("users", query),
    }),
    getListUserRegister: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userListRegister"],
      query: (query) => QueryUrl("medecin_patient_non_actifs", query),
    }),
    getListAdminUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["adminsList"],
      query: (query) => QueryUrl("user/admins/", query),
    }),
    getPraticien: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`praticiens/`, query),
      providesTags: ["userList"],
    }),
    getPatient: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`patients/`, query),
      providesTags: ["userList"],
    }),
    userById: builder.query<IUser | any, string>({
      query: (slug) => `user/admins/${slug}/`,
      providesTags: ["userById"],
    }),
    userBySlug: builder.query<IUser | any, string>({
      query: (slug) => `users/${slug}/`,
      providesTags: ["userBySlug"],
    }),
    archiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    unArchiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `reactive_user/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    deleteUser: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "userList", "adminsList"],
    }),
    activeUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `medecin/account/activation/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["userList","userListRegister"],
    }),
    getListHistorique: builder.query<PaginationResults<Historique>, TypeQuery>({
      providesTags: ["historique"],
      query: (query) => QueryUrl("historiques", query),
    }),
  })
});

export const {
  useMeQuery,
  useAddOrEditUserMutation,
  useChangePasswordMutation,
  useAddOrEditUserAdresseMutation,
  useGetListUserQuery,
  useGetListUserRegisterQuery,
  useAddOrEditAdminMutation,
  useGetListAdminUserQuery,
  useLazyUserByIdQuery,
  useLazyUserBySlugQuery,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
  useGetPraticienQuery,
  useGetPatientQuery,
  useDeleteUserMutation,
  useGetInfoDashbordAdminQuery,
  useActiveUserMutation,
  useGetListHistoriqueQuery
} = UserApi;

export function useUserFromLocation(): [
  IUser,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyUserByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, id as string, findById];
}