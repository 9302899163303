import photo from "../../../../assets/appImages/user.png";

export const PatientsAdminTableData = [
    {
        id: 1,
        slug: "patient-1",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
    {
        id: 2,
        slug: "patient-2",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
    {
        id: 3,
        slug: "patient-3",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
    {
        id: 4,
        slug: "patient-4",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
    {
        id: 5,
        slug: "patient-5",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
    {
        id: 6,
        slug: "patient-6",
        photo: photo,
        nom: "Fama Kebe",
        genre: "Femme",
        add_date: "11/02/2022",
    },
]


export const PatientsPaimentTableData = [
    {
        id: 1,
        date: "21 Janvier 2023",
        type: "Consultation",
        praticien: "Dr. Ibrahima Ndiaye",
        amount: "20 000 FCFA",
        payment_method: "visa",
    },
    {
        id: 2,
        date: "21 Janvier 2023",
        type: "Consultation",
        praticien: "Dr. Ibrahima Ndiaye",
        amount: "20 000 FCFA",
        payment_method: "visa",
    },
    {
        id: 3,
        date: "21 Janvier 2023",
        type: "Consultation",
        praticien: "Dr. Ibrahima Ndiaye",
        amount: "20 000 FCFA",
        payment_method: "visa",
    },
    {
        id: 4,
        date: "21 Janvier 2023",
        type: "Consultation",
        praticien: "Dr. Ibrahima Ndiaye",
        amount: "20 000 FCFA",
        payment_method: "mastercard",
    },
    {
        id: 5,
        date: "21 Janvier 2023",
        type: "Consultation",
        praticien: "Dr. Ibrahima Ndiaye",
        amount: "20 000 FCFA",
        payment_method: "visa",
    },
]


export const AllRdvItemData = [
    {
        id: 1,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 2,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "en cours",
    },
    {
        id: 3,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "annulé",
    },
    {
        id: 4,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 5,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 6,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 7,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 8,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
    {
        id: 9,
        praticienName: "Dr Abdoulaye Ndiaye",
        speciality: "Cardiologue",
        hour: "8H30 - 09H30",
        status: "passé",
    },
]