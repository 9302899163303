import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import visa from "../../../../assets/icons/visa.png";
import mastercard from "../../../../assets/icons/mastercard.png";
import wave from "../../../../assets/icons/wave.png";
import orange from "../../../../assets/icons/money.png";
import { PatientsPaimentTableData } from "../tables";
import { formatDate, getName , formatTime } from "../../../../utils/Utils";


function PatientsPaimentTable({paiements, isLoading}) {

const [pages, setPages] = useState(PatientsPaimentTableData)


  const paymentMethodFormatter = (cell: any, row: any) => {

    
    return (
      <div className="payment-method-format">
        { cell === "WAVE_SN_API_CASH_OUT" && <img src={wave} alt="Wave" /> }
        { cell === "ORANGE_SN_API_CASH_OUT" &&  <img src={orange} alt="Orange Money" />}
        { cell === "BANK_TRANSFER_SN_API_CASH_OUT" && <img src={mastercard} alt="mc" />} 
      </div>
    );
  };

  const typeFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          { row?.format?.choix_consultation }
        </div>
      </div>
    );
  };

  const praticienFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          {getName(row?.medecin)}
        </div>
      </div>
    );
  };

  const tarificationFormatter = (cell: any, row: any) => {
    return (
      <div className="format-formatter-container">
        <div className="">
          { row?.format?.prix } FCFA
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "type",
      text: "Type",
      formatter: typeFormatter,
    },
    {
      dataField: "praticien",
      text: "Praticien",
      formatter: praticienFormatter,
    },
    {
      dataField: "amount",
      text: "Montant",
      formatter: tarificationFormatter,
    },
    {
      dataField: "moyen_paiement",
      text: "Moyen de paiement",
      formatter: paymentMethodFormatter,
    },
  ];
  const selectRow = {
    mode: "checkbox",
  };
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={paiements?.results || []}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive paiement-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
        </>
      )}
    </>
  );
}

export default PatientsPaimentTable;
