/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  IContact,
  ContactFormData,
  ResponseContact,
  ResponseContactFormData,
} from "./contact.type";

export const ContactApi = createApi({
  reducerPath: "contactApi",
  tagTypes: ["contactList", "contactBySlug", "responseByContact"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListContactAdmin: builder.query<PaginationResults<IContact>, TypeQuery>({
      providesTags: ["contactList"],
      query: (query) => QueryUrl("contacts/", query),
    }),

    addOrEditContact: builder.mutation<IContact, ContactFormData>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(ContactApi.util.invalidateTags(["contactList"]));
        }, 1000);
      },
      query: ({ id, ...data }) => {
        if (id) {
          return {
            url: `contacts/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `contacts/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteContact: builder.mutation<IContact, string>({
      query: (id) => ({
        url: `contacts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contactList"],
    }),
    contactBySlug: builder.query<IContact | any, string>({
      query: (id) => `contacts/${id}/`,
      providesTags: ["contactBySlug"],
    }),
    sendResponseContact: builder.mutation<ResponseContact,ResponseContactFormData>({
      query: (data) => ({
        url: "contact/responses/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contactList"],
    }),
  }),
});

export const {
  useAddOrEditContactMutation,
  useGetListContactAdminQuery,
  useLazyContactBySlugQuery,
  useSendResponseContactMutation,
  useDeleteContactMutation
} = ContactApi;
