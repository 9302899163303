/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { PraticienFormData, IPraticien } from "../../../../utils/api/praticien/praticien.type";
import { useAddOrUpdatePraticienMutation, useDeletePraticienMutation,useActiveUserMutation , useValideUserMutation} from "../../../../utils/api/praticien/praticien.api";
import { Color } from "../../../../utils/theme";
import { FaTrash , FaToggleOff , FaToggleOn} from "react-icons/fa";
import { useDelete, useActive, useDesactive , useValide } from "../../../../utils/helpers";
import {  useArchiveUserMutation , useUnArchiveUserMutation } from "../../../../utils/api/user/user.api";
import { UserFormData , IUser } from "../../../../utils/api/user/user.type";
import { MdOutlineCheckBox , MdOutlineCheckBoxOutlineBlank } from "react-icons/md";


function UseAddOrUpdatePraticienForm(praticien?: IPraticien) {
console.log({praticien})
  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Le prenom est obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    email: yup.string().email("L'email est invalide").required("L'email est obligatoire"),
    telephone: yup.string().required("Le téléphone est obligatoire"),
    nationnalite: yup.string().required("La nationalité est obligatoire"),
    date_de_naissance: yup
    .date()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .max(new Date(), "La date de naissance ne peut pas être dans le futur")
    .nullable(),
    date_inscription_ordre: yup
    .date()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .max(new Date(), "La date d'inscription Ordre ne peut pas être dans le futur")
    .nullable(),
    cni: yup
    .string()
    .matches(/^\d+$/, "Le CNI ne doit contenir que des chiffres")
    // .required("Le CNI est obligatoire"),
   // cv: yup.mixed().required("Le CV est obligatoire"),
   // taux_horaire: yup.string().required("Le taux horaire est obligatoire"),

    // specialite: yup.string().required("Le specialité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset
  } = useForm<PraticienFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [phone, setPhone] = useState<string | null>(null);
  const [code, setCode] = useState<string>("");
  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdatePraticien, { isLoading, isSuccess, error, isError }] = useAddOrUpdatePraticienMutation();

  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    setValue("avatar", files)
  }

  const handleChangeCv = (e) => {
      let file = e.target.files[0];
      setValue("cv", file);
    }

  const handleReset = () => {
    if (!praticien) {
      setPhone("+221");
      reset();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Praticien ${praticien ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/praticiens')
      });
    }
    const err = error as any;
    if (isError) {
      console.log("error", err);
      if (err?.data?.telephone) {
        Swal.fire({
          icon: "error",
          title: err?.data?.telephone[0],
          showConfirmButton: false,
          timer: 3000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (praticien?.id) {
      let fields: (keyof PraticienFormData)[] = [
        "slug",
        "email",
        "prenom",
        "nom",	
        "last_login",	
        "telephone"	,
        "is_archive",
        "longitude"	,
        "latitude",	
        "adresse",
        "user_type"	,
        "civilite"	,
        "first_connexion"	,
        "token"	,
        "bio"	,
        "hopital"	,
        "maladie",	
        "delais"	,
        "deletion_id"	,
        "deletion_type"	,
        "condition",	
        "specialite",
        "nationnalite",
        "cni",
        "ordre_des_medecins",
        "cv",
        "taux_horaire",
        "date_inscription_ordre",
        "pays_ordre_des_medecins",
        "date_de_naissance",
      ];
      for (let field of fields) {
        register(field);
        if ((field === "date_de_naissance" || field === "date_inscription_ordre") && praticien[field]) {
          const dateString = praticien[field];
          const date = new Date(dateString);

          if (dateString && !isNaN(date.getTime()) && dateString !== "Invalid Date") {
            setValue(field, date.toISOString().slice(0, 10)); // formatter la date YYYY-MM-DD
          } else {
            setValue(field, "");
          }
        } else if (field == "specialite") {
          setValue(field, praticien[field]?.id)
        }
        else {
          setValue(field, praticien[field])
        }

      }
      setPhone(praticien?.telephone);
    }
  }, [praticien]);

  const handleChangePhone = (item: any, country: any) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const onSubmit = async (data: PraticienFormData) => {
    if (data.date_de_naissance) {
      const date = new Date(data.date_de_naissance);
      if (!isNaN(date.getTime())) {
        data.date_de_naissance = date.toISOString().slice(0, 10);
      } else {
        data.date_de_naissance = "";
      }
    }

    if (data.date_inscription_ordre) {
      const dateInscription = new Date(data.date_inscription_ordre);
      if (!isNaN(dateInscription.getTime())) {
        data.date_inscription_ordre = dateInscription.toISOString().slice(0, 10);
      } else {
        data.date_inscription_ordre = ""; // Clear the date if invalid
      }
    }

    if(typeof(data?.cv) == 'string')
    {
      delete data?.cv
    }

    let fd = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value != "" && value != undefined) {
        fd.append(key, value);
      }
    });
      
    fd.append("user_type", 'medecin');

    await addOrUpdatePraticien({ slug: praticien?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeAvatar,
    handleChangeCv,
    phone,
    setPhone,
    handleChangePhone,
    handleReset
  };
}

export function DeletePraticien({ item }: { item: IPraticien }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeletePraticienMutation();
  const onDelete = useDelete<IPraticien>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce praticien",
    successMessage: "Praticien supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export function ActivePraticien({ item }: { item: IPraticien }) {

  const [activeItem, {  isSuccess, isError, error }] =
    useActiveUserMutation();
  const onActive = useActive<IPraticien>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "activé ce praticien " ,
    successMessage: "Praticien activé" ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Activé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
      <FaToggleOff />
    </button>
  );
}

export function DeactivePraticien({ item }: { item: IPraticien }) {

  const [activeItem, {  isSuccess, isError, error }] =
    useActiveUserMutation();
  const onActive = useDesactive<IPraticien>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "désactivé ce praticien " ,
    successMessage: "Praticien désactivé"  ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Désactivé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
      <FaToggleOn /> 
    </button>
  );
}

export function ValiderPraticien({ item }: { item: IPraticien }) {

  const [valideItem, {  isSuccess, isError, error }] =
    useValideUserMutation();
  const onValide = useValide<IPraticien>({
    item,
    valideItem,
    isSuccess,
    isError,
    error,
    question: "validé ce praticien " ,
    successMessage: "Praticien validé" ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Validé"
      style={{ color: "#22bbc0" }}
      onClick={onValide}
    >
      {item?.is_valid ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank/>}
    </button>
  );
}

export function useToggleArchivePraticien(user: IUser) {
  
  const [editData] = user?.is_archive ? useUnArchiveUserMutation() : useArchiveUserMutation();
  const onArchive = async () => {
    let data: UserFormData = {
      is_archive: !user?.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } ce praticen ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Praticien ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}

export default UseAddOrUpdatePraticienForm;
