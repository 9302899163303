import React from "react";
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { NavLink } from "react-router-dom";
import ReactQuill from 'react-quill';
import { DeleteCondition } from "../requestForm/UseAddOrUpdateConditionForm"
import { ConditionFormData , ICondition } from "../../../../utils/api/condition/condition.type";


const SnippetItem = (
    {condition}: 
    {condition: ICondition}
  ) => {
    return(
      <div className="use-condition-item">
            <h5 className="use-condition-title pb-3">
              {condition?.id}.&nbsp;{condition?.titre}
            </h5>
            <div className="custom-condition-content bordered">
              <ReactQuill readOnly={true} value={condition?.text} />
              <div className="btn-group action-btn">
                  <button
                    className="btn btn-sm dropdown-toggle custom-btn-dots"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to={`/admin/parametres/modifier-condition/${condition?.slug}`}
                      >
                        Modifier
                      </NavLink>
                    </li>
                    <li>
                      <DeleteCondition item={condition} />
                    </li>
                  </ul>
              </div>
          </div>
        </div>
    )
}

export default SnippetItem;