/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../utils/Utils";
import { useAddOrEditUserMutation, useDeleteUserMutation,useArchiveUserMutation, useUnArchiveUserMutation, useActiveUserMutation  } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { FaTrash , FaToggleOff , FaToggleOn} from "react-icons/fa";
import { useDelete, useActive, useDesactive } from "../../../../utils/helpers";
import { UserFormData , IUser } from "../../../../utils/api/user/user.type";


function UseAddOrUpdateUserForm(praticien?: IUser) {

  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Le prenom est obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    email: yup.string().email("L'email est invalide").required("L'email est obligatoire"),
    telephone: yup.string().required("Le téléphone est obligatoire"),
    nationnalite: yup.string().required("La nationalité est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [phone, setPhone] = useState<string | null>(null);
  const [code, setCode] = useState<string>("");
  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] = useAddOrEditUserMutation();

  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    setValue("avatar", files)
  }


  const handleReset = () => {
    if (!praticien) {
      setPhone("+221");
      reset();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `User ${praticien ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/praticiens')
      });
    }
    const err = error as any;
    if (isError) {
      console.log("error", err);
      if (err?.data?.telephone) {
        Swal.fire({
          icon: "error",
          title: err?.data?.telephone[0],
          showConfirmButton: false,
          timer: 3000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (praticien?.id) {
      let fields: (keyof UserFormData)[] = [
        "slug",
        "email",
        "prenom",
        "nom",	
        "last_login",	
        "telephone"	,
        "is_archive",
        "longitude"	,
        "latitude",	
        "adresse",
        "user_type"	,
        "civilite"	,
        "first_connexion"	,
        "token"	,
        "bio"	,
        "hopital"	,
        "maladie",	
        "delais"	,
        "deletion_id"	,
        "deletion_type"	,
        "condition",	
        "specialite",
        "nationnalite",
      ];
      for (let field of fields) {
        register(field);
        if (field == "specialite") {
          setValue(field, praticien[field]?.id)
        }
         else {
          setValue(field, praticien[field])
        }

      }
      setPhone(praticien?.telephone);
    }
  }, [praticien]);

  const handleChangePhone = (item: any, country: any) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  const onSubmit = async (data: UserFormData) => {

    let fd = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value != "" && value != undefined) {
        fd.append(key, value);
      }
    });
      
    fd.append("user_type", 'medecin');

    await addOrUpdateUser({ slug: praticien?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeAvatar,
    phone,
    setPhone,
    handleChangePhone,
    handleReset
  };
}

export function DeleteUser({ item }: { item: IUser }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteUserMutation();
  const onDelete = useDelete<IUser>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce praticien",
    successMessage: "User supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export function ActiveUser({ item }: { item: IUser }) {

  const [activeItem, {  isSuccess, isError, error }] =
  useActiveUserMutation();
  const onActive = useActive<IUser>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "activé ce utilisateur " ,
    successMessage: "Utilisateur activé" ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Activé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
      {item?.is_active ? <FaToggleOn /> : <FaToggleOff />} 
    </button>
  );
}

export function DeactiveUser({ item }: { item: IUser }) {

  const [activeItem, {  isSuccess, isError, error }] =
  useActiveUserMutation();
  const onActive = useDesactive<IUser>({
    item,
    activeItem,
    isSuccess,
    isError,
    error,
    question: "désactivé ce utilisateur " ,
    successMessage: "Utilisateur désactivé"  ,
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-see"
      data-tooltip-content="Désactivé"
      style={{ color: "#22bbc0" }}
      onClick={onActive}
    >
      {item?.is_active ? <FaToggleOn /> : <FaToggleOff />} 
    </button>
  );
}

export function useToggleArchiveUser(user: IUser) {
  
  const [editData] = user?.is_archive ? useUnArchiveUserMutation() : useArchiveUserMutation();
  const onArchive = async () => {
    let data: UserFormData = {
      is_archive: !user?.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } ce praticen ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData(user.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `User ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}

export default UseAddOrUpdateUserForm;
