import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState, PaginationResults, TypeQuery,IUser } from "../user/user.type";
import { IPraticien } from "./praticien.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { AppStorage } from "../../storage";

import {
  PraticienFormData,
} from "./praticien.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const PraticienApi = createApi({
  reducerPath: "praticien",
  tagTypes: [
    "praticien",
    "praticienList",
    "praticienBySlug",
    "patientsByPraticien",
    'allPraticiensList',
    "dashboard",
    "rdvsTerminerByPraticien",
    "rdvsProchainsByPraticien",
    "rdvsPassersByPraticien",
    "exportPraticien",
    "paiementsByPraticien"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({


    // get praticien pratricien 

      getAllPraticienList: builder.query<any, void>({
        providesTags: ["allPraticiensList"],
        query: () => {
          return "medecins/";
        },
      }),
      getInfoDashbordPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => {
          return `praticien/${slug}/dashboard/`;
        },
        providesTags: ["dashboard"],
      }),
      getPraticien: builder.query<PaginationResults<IPraticien>, TypeQuery>({
        query: (query) => QueryUrl(`medecins/`, query),
        providesTags: ["praticien"],
      }),
      praticienBySlug: builder.query<IPraticien | any, string>({
        query: (slug) => `medecins/${slug}/`,
        providesTags: ["praticienBySlug"],
      }),
      rdvsTerminerByPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => `medecins/${slug}/rdvs_terminer/`,
        providesTags: ["rdvsTerminerByPraticien"],
      }),
      patientsByPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => `medecins/${slug}/patients/`,
        providesTags: ["patientsByPraticien"],
      }),
      rdvsProchainsByPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => `medecin/${slug}/prochains_rdvs/`,
        providesTags: ["rdvsProchainsByPraticien"],
      }),
      rdvsPassersByPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => `medecin/${slug}/rdvs/`,
        providesTags: ["rdvsPassersByPraticien"],
      }),
      paiementsByPraticien: builder.query<IPraticien | any, string>({
        query: (slug) => `medecin/${slug}/paiements/`,
        providesTags: ["paiementsByPraticien"],
      }),
      addOrUpdatePraticien: builder.mutation<IPraticien, { slug?: string; data: IPraticien | FormData }>({
        query: ({ slug, data }) => ({
          url: slug ? `medecins/${slug}/` : `medecins/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["praticien"],
      }),
      deletePraticien: builder.mutation<PraticienFormData, string>({
        query: (slug) => ({
          url: `users/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["praticien"],
      }),
    activeUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `medecin/account/activation/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["praticien"],
    }),

    valideUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `medecin/account/activation/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["praticien"],
    })


  }),
});

export const {
  useGetPraticienQuery,
  useLazyPraticienBySlugQuery,
  useLazyPatientsByPraticienQuery,
  useAddOrUpdatePraticienMutation,
  useDeletePraticienMutation,
  useGetAllPraticienListQuery,
  useGetInfoDashbordPraticienQuery,
  useLazyRdvsTerminerByPraticienQuery,
  useLazyRdvsProchainsByPraticienQuery,
  useLazyRdvsPassersByPraticienQuery,
  useActiveUserMutation,
  useValideUserMutation,
  useLazyPaiementsByPraticienQuery,
} = PraticienApi;
