import React,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import SousAdminsTable from '../tables/SousAdminsTable';
import {
    useGetListAdminUserQuery,
  } from "../../../../utils/api/user/user.api";
import { useDelete, usePagination, useSearch } from '../../../../utils/hooks';


const SousAdmins = () => {

    const [listItem, setListItem] =  useState<any>([]);
    const { limit, page, onChange } = usePagination(10);
    const { data, isLoading } = useGetListAdminUserQuery({
      page: page,
      limit: limit,
    });
  
    // useEffect(() => {
    //     setListItem(data?.results?.filter((x) => (x?.user_type === "admin" || x?.user_type === "superadmin")));
    // }, [data])


  return (
    <>
        <div className="top-curved-container">
            <div className="col-md-2">
                <NavLink
                    to={`/admin/parametres/ajouter-sous-admin`}
                    className="btn btn-theme-admin"
                >Ajouter un sous-admin</NavLink>
            </div>
        </div>
        <div className="all-admins-table pt-3">
            <SousAdminsTable listItem={data} isLoading={isLoading} page={page} limit={limit} onChange={onChange} />
        </div>
    </>
  )
}

export default SousAdmins