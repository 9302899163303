import React, { useState } from 'react'
import Modal from "react-modal";
import { BtnCloseModal, BtnSubmit } from '../../../common/Button';
import { Input } from '../../../common/Input';
import useCrudSpecialite from '../requestForm/useCrudSpecialite';
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { onHide } from "../../../../utils/Utils";


function AddSpecialitesModal({item }: any) {
  const closeAddSpecialite = ()=>{
    onHide('AddSpecialisteModal');
  }
    const { register, onSubmit, errors, isLoading , handleChangeImage } = useCrudSpecialite(closeAddSpecialite, item);
      return (
        <div
          className="modal fade"
          id="AddSpecialisteModal"
          aria-labelledby="AddSpecialisteModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AddSpecialisteModalLabel">
                  {`${item ? "Modifier la spécialité" : "Ajouter une spécialité"}`}
                </h5>
                <CloseModalBtn  onClick={()=>closeAddSpecialite()} />
              </div>
              <div className="modal-body justify-content-start w-100">
                <form onSubmit={onSubmit}>
                  <div className="row py-4">
                  <div className="col-md-12 col-form-modal mb-3">
                      <Input
                        type="file"
                        label="Image"
                        id="Image"
                        placeholder="Image"
                        customClassLabel={"input-modal-label-form-add"}
                        customClassInput="input-modal-form-add"
                        onChange={(e) => handleChangeImage(e)}
                        error={errors?.image}
                      />
                    </div>
                    <div className="col-md-12 col-form-modal mb-3">
                      <Input
                        type="text"
                        label="Nom "
                        id="nom"
                        placeholder="Nom"
                        customClassLabel={"input-modal-label-form-add"}
                        customClassInput="input-modal-form-add"
                        {...register("nom")}
                        error={errors?.nom}
                      />
                    </div>
                  </div>
                  <div className="row row-content-btn-action pt-3">
                    <div className="col-md-4 offset-md-8 modal-add-btn-action-container">
                      {!isLoading ? (
                        <BtnSubmit
                            label={item ? "Modiifer" : "Ajouter"}
                            isLoading={isLoading}
                          customClass="custom-btn-submit-rdv"
                        />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-page-title-admin custom-btn-submit-rdv w-100"
                        >
                          <span
                            className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Chargement...</span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
}

export default AddSpecialitesModal

