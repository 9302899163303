import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IPrestation, PrestationFormData } from "./prestations.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const PrestationApi = createApi({
    reducerPath: "prestationApi",
    tagTypes: ["prestation","prestationBySlug"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${ApiBaseUrl}/api/`,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        addOrUpdatePrestation: builder.mutation<IPrestation, { slug?: string; data: IPrestation  | FormData }>({
            query: ({ slug, data }) => ({
                url: slug ? `type_prestations/${slug}/` : `type_prestations/`,
                method: slug ? "PUT" : "POST",
                body: data,
            }),
            invalidatesTags: ["prestation"],
        }),
        getPrestations: builder.query<PaginationResults<IPrestation>, TypeQuery>({
            query: () => `type_prestations/`,
            providesTags: ["prestation"],
        }),
        prestationBySlug: builder.query<IPrestation | any, string>({
            query: (slug) => `type_prestations/${slug}/`,
            providesTags: ["prestationBySlug"],
          }),
        deletePrestation: builder.mutation<PrestationFormData, string>({
            query: (slug) => ({
                url: `type_prestations/${slug}/`,
                method: "DELETE",
            }),
            invalidatesTags: ["prestation"],
        }),
    }),
})
export const {
    useAddOrUpdatePrestationMutation,
    useLazyPrestationBySlugQuery,
    useDeletePrestationMutation,
    useGetPrestationsQuery
} = PrestationApi