import React, { useState, useEffect } from 'react';
import "./RdvAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { Input } from '../../common/Input';
import { SelectInput } from "../../common/SelectInput";
import { useLocation, useParams } from "react-router-dom";
import UseAddOrUpdateRendezVousForm from "./requestForm/UseAddOrUpdateRendezVousForm";
import ErrorMessage from "../../common/ErrorMessage";
import { useLazyRdvBySlugQuery } from "../../../utils/api/rdvs/rdvs.api";
import { useGetAllPraticienListQuery } from "../../../utils/api/praticien/praticien.api";
import { useGetAllPatientsListQuery } from "../../../utils/api/patient/patient.api";
import { useGetAllFormatsListQuery } from "../../../utils/api/format/format.api";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { GoogleAdresseType } from "../../../utils/type";
import { IPraticien } from "../../../utils/api/praticien/praticien.type";
import { IPatient } from "../../../utils/api/patient/patient.type";
import { IFormat } from "../../../utils/api/format/format.type";


function AddOrUpdateRdv() {

    const { data:praticiens } = useGetAllPraticienListQuery();
    const { data:patients } = useGetAllPatientsListQuery();
    const { data:formats } = useGetAllFormatsListQuery();
    const { slug } = useParams<{ slug: string }>();
    const [findBySlug, { data, isError }] = useLazyRdvBySlugQuery();
    const [adresse, setAdresse] = useState<any>('');
  
    useEffect(() => {
      if (slug) {
        findBySlug(slug as string);
      }
    }, [slug]);
  
    const {
      register,
      errors,
      onSubmit,
      setValue,
      isLoading,
    } = UseAddOrUpdateRendezVousForm(data);

    const selectPraticiens = praticiens?.results?.map((praticien: IPraticien) => ({ label: praticien.prenom+" "+praticien.nom, value: praticien.id }));
    const selectPatients = patients?.results?.map((patient: IPatient) => ({ label: patient.prenom+" "+patient.nom, value: patient.id }));
    const selectFormats = formats?.results?.map((format: IFormat) => ({ label: format.nom, value: format.id }));

    

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        {!!data && data ?
            <BreadCrumb 
                pageUrl='/admin/rendez-vous'
                pageName="Rendez-vous"
                secondPage="Modifier un rendez-vous"
            />
            :
            <BreadCrumb 
            pageUrl='/admin/rendez-vous'
            pageName="Rendez-vous"
            secondPage="Ajouter un rendez-vous"
            />
        }
        <div className="content-graph-admin">
            <div className="custom-form mt-5">
                <form  onSubmit={onSubmit} >
                    <div className="row">
                        <div className="col-md-6">
                            <Input 
                                label='Heure de début'
                                type='time'
                                placeholder='Heure de début'
                                {...register("heure_de_debut")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.heure_de_debut && errors?.heure_de_debut?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Heure de fin'
                                type='time'
                                placeholder='Heure de fin'
                                {...register("heure_de_fin")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.heure_de_fin && errors?.heure_de_fin?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input 
                                label='Date'
                                type='date'
                                placeholder='Date'
                                {...register("date")}
                                required
                            />
                            <ErrorMessage
                                message={errors?.date && errors?.date?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectInput label="Formats" required options={selectFormats} {...register("format")}/>
                            <ErrorMessage
                                message={errors?.format && errors?.format?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectInput label="Praticiens" required options={selectPraticiens} {...register("medecin")}/>

                            <ErrorMessage
                                message={errors?.medecin && errors?.medecin?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectInput label="Patient" required options={selectPatients} {...register("patient")}/>

                            <ErrorMessage
                                message={errors?.patient && errors?.patient?.message}
                            />
                        </div>
                        <div className="col-md-6">
                            <label 
                                htmlFor="choix_consultation"
                                className="form-label form-label-modal-custom "
                            >
                                Consultation<span className="text-danger" style={{ fontSize: 15 }}>*</span>
                            </label>
                            <select
                                className="form-control form-control-modal-custom form-select-modal-custom mb-2"
                                id="choix_consultation"
                                {...register("choix_consultation")}
                            >
                                <option value="" disabled>Veuillez le type de consultation</option>
                                <option value="a_domicile">A domicile</option>
                                <option value="telemedecine">Télémédecine</option>
                            </select>
                            <ErrorMessage
                                message={errors?.choix_consultation && errors?.choix_consultation?.message}
                            />
                        </div>

                        <div className="col-md-6">
                            
                            <label 
                                htmlFor="moyen_paiement"
                                className="form-label form-label-modal-custom "
                            >
                                Moyen de paiement<span className="text-danger" style={{ fontSize: 15 }}>*</span>
                            </label>
                            <select
                                className="form-control form-control-modal-custom form-select-modal-custom mb-2"
                                id="moyen_paiement"
                                {...register("moyen_paiement")}
                            >
                                <option value="" disabled>Veuillez choisir le moyen de paiement</option>
                                <option value="WAVE_SN_API_CASH_OUT">Wave</option>
                                <option value="ORANGE_SN_API_CASH_OUT">Orange Money</option>
                                <option value="FREE_SN_API_CASH_OUT">Free Money</option>
                                <option value="EXPRESSO_SN_API_CASH_OUT">Expresso Money</option>
                                <option value="BANK_TRANSFER_SN_API_CASH_OUT">Tranfert bancaire</option>
                                <option value="BANK_CARD_SN_API_CASH_OUT">Carte Bancaire</option>
                            </select>
                            <ErrorMessage
                                message={errors?.moyen_paiement && errors?.moyen_paiement?.message}
                            />
                        </div>
                        <div className="col-md-4">
                            <label 
                                htmlFor="statut"
                                className="form-label form-label-modal-custom "
                            >
                                Statut
                            </label>
                            <select
                                className="form-control form-control-modal-custom form-select-modal-custom mb-2"
                                id="statut"
                                {...register("statut")}
                            >
                                <option value="" disabled>Veuillez choisir le statut</option>
                                <option value="nouveau">Nouveau</option>
                                <option value="en_attente">En attente</option>
                                <option value="accepter">Accepter</option>
                                <option value="annuler">Annuler</option>
                                <option value="rejeter">Rejeter</option>
                                <option value="terminer">Terminer</option>
                            </select>
                            <ErrorMessage
                                message={errors?.statut && errors?.statut?.message}
                            />
                        </div>
                        <div className="col-md-4">
                            <Input 
                                label='Document'
                                type='file'
                                placeholder='Document'
                                {...register("document")}
                            />
                            <ErrorMessage
                                message={errors?.document && errors?.document?.message}
                            />
                        </div>
                        <div className="col-md-4">
                            <label 
                                htmlFor="choix_consultation"
                                className="form-label form-label-modal-custom "
                            >
                                Payer
                            </label>
                            <input 
                                type='checkbox'
                                placeholder='Payer'
                                {...register("paid")}
                                className="form-control-checkbox"
                            />
                            <ErrorMessage
                                message={errors?.paid && errors?.paid?.message}
                            />
                        </div>
                        <div className="col-md-12">
                            <label
                                htmlFor='motif'
                                className={`form-label form-label-modal-custom`}
                                aria-labelledby='motif'
                            >
                                Motif<span className="text-danger" style={{ fontSize: 15 }}>*</span>
                            </label>
                            <textarea className="form-control form-control-modal-custom" {...register("motif")} id="" cols={30} rows={10}></textarea>
                            <ErrorMessage
                                message={errors?.motif && errors?.motif?.message}
                            />
                        </div>
                        
                    </div>
                    <div className="col-md-3 offset-md-9 custom-action-btn-container text-end pt-5">
                        <button
                            type="submit"
                            className="btn btn-theme-admin"
                            disabled={isLoading}
                        >
                            {isLoading  ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                    role="status"
                                ></span>
                                <span>
                                    {data ? "Modification..." : "Ajout..."}
                                </span>
                            </>
                            ) : data ? (
                                "Modifer"
                            ) : (
                                "Ajouter"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdateRdv;

