import React, { useState , useEffect } from 'react';
import "./RdvAdmin.css";
import BreadCrumb from '../../shared/BreadCrumb';
import { useLazyRdvBySlugQuery  } from "../../../utils/api/rdvs/rdvs.api";
import { useParams } from "react-router-dom";
import visa from "../../../assets/icons/visa.png";
import mastercard from "../../../assets/icons/mastercard.png";
import wave from "../../../assets/icons/wave.png";
import orange from "../../../assets/icons/money.png";
import { formatDate, getName , formatTime } from "../../../utils/Utils";
import { MdOutlineCheckCircle, MdPhone } from "react-icons/md";
import { BsFillChatDotsFill } from 'react-icons/bs';
import { AiOutlineClockCircle, AiFillEye } from 'react-icons/ai';
import { RxCrossCircled } from "react-icons/rx";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { PiVideoCameraFill } from "react-icons/pi";
import moment from "moment";

function DetailRdv() {
    const { slug } = useParams<{ slug: string }>();
    const [findByRdvs, { data:rdv  }] = useLazyRdvBySlugQuery();
    
  
    useEffect(() => {
      if (slug) {
        findByRdvs(slug as string);
      }
    }, [slug]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb 
            pageUrl='/admin/rendez-vous'
            pageName="Rendez-vous"
            secondPage="Détail"
        />
        <div className="custom-detail-content">
            <div className="top-curved-container">
                <h3 className="custom-top-title">
                    Détails rendez-vous
                </h3>
            </div>
            <div className="praticien-and-patient-infos mt-2">
                <div className="pap-card">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="title">Patient</h4>
                            <p className="name">{getName(rdv?.patient)}</p>
                            <div className="attribution d-flex justify-content-start gap-4 align-items-center pt-2">
                                <p className='maladie'>
                                    <span className="sm-title">Motif</span> &nbsp;
                                    <span className="sm-value">{rdv?.motif}</span>
                                </p>
                                <p className='statut d-flex align-items-center gap-1'>
                                    <span className="sm-title">Statut</span> &nbsp;
                                    <span className="sm-value statut-value">
                                        {!!rdv?.status && rdv?.status === "en_attente" && (
                                        <p className="d-flex align-items-center p-t-12">
                                            <AiOutlineClockCircle style={{ color: '#D0775B', fontSize: 20 }} />
                                            <span>
                                            En attente <br /> 
                                            <em style={{ color: '#D0775B' }}>
                                            { moment(rdv?.date).isSame(moment().format('YYYY-MM-DD')) ? formatTime(rdv?.heure_de_debut) : "" }
                                            </em>
                                            </span>
                                        </p>
                                        )
                                        }
                                        {!!rdv?.status && rdv?.status === "terminer" && ( 
                                        <div className="d-flex align-items-center programmed">
                                            <MdOutlineCheckCircle style={{ fontSize: 20 }} />
                                            Terminé
                                        </div>
                                        )
                                        }
                                        {!!rdv?.status && rdv?.status === "accepter" && ( 
                                        <div className="d-flex align-items-center visited">
                                            <MdOutlineCheckCircle style={{ fontSize: 20 }} />
                                            Programmé
                                        </div>
                                        )
                                        }
                                        {!!rdv?.status && rdv?.status === "nouveau" && (
                                        <p className="d-flex align-items-center  nouveau mb-0">
                                            <IoAddCircleOutline style={{ color: '#22BBC0', fontSize: 20 }} />
                                            <span>
                                            Nouveau <br /> 
                                            </span>
                                        </p>
                                        )
                                        }
                                        {!!rdv?.status && rdv?.status === "annuler" && ( 
                                        <div className="d-flex align-items-center  annuler">
                                            <RxCrossCircled style={{ color: '#D0775B',fontSize: 20 }} />
                                            Annulé
                                        </div>
                                        )
                                        }
                                        {!!rdv?.status && rdv?.status === "rejeter" && ( 
                                        <div className="d-flex align-items-center annuler">
                                            <RxCrossCircled style={{ color: '#D0775B',fontSize: 20 }} />
                                            Rejeté
                                        </div>
                                        )
                                        }

                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4 className="title">Praticien</h4>
                            <p className="name">{getName(rdv?.medecin)}</p>
                            <div className="attribution d-flex justify-content-start gap-4 align-items-center pt-2">
                                <p className='speciality'>
                                    <span className="sm-title">Spécialité</span> &nbsp;
                                    <span className="sm-value">{rdv?.medecin?.specialite?.nom}</span>
                                </p>
                                {/* <p className='statut d-flex align-items-center gap-1'>
                                    <span className="sm-title">Statut</span> &nbsp;
                                    <span className="sm-value statut-value">
                                        <AiOutlineClockCircle style={{ color: '#D0775B', fontSize: 15 }} /> 
                                        En attente 05:54 
                                    </span>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="facturation-and-detail pt-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="facuturation-card colgate-card">
                            <h5 className="title pb-3">
                                Détail de la facturation
                            </h5>
                            <p>
                                <span className="sm-title">Payé via :
                                { rdv?.moyen_paiement === "WAVE_SN_API_CASH_OUT" && <img src={wave} alt="Wave" /> }
                                { rdv?.moyen_paiement === "ORANGE_SN_API_CASH_OUT" &&  <img src={orange} alt="Orange Money" />}
                                { rdv?.moyen_paiement === "BANK_TRANSFER_SN_API_CASH_OUT" && <img src={mastercard} alt="mc" />} 
                                </span>&nbsp;
                                <span className="sm-value"></span>
                            </p>
                            <p>
                                <span className="sm-title">Montant :</span>&nbsp;
                                <span className="sm-value">{ rdv?.format?.prix } FCFA</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="more-detail-card colgate-card">
                            <h5 className="title pb-3">
                                Détail du rendez-vous
                            </h5>
                            <p>
                                <span className="sm-title">Date du rendez-vous :</span>&nbsp;
                                <span className="sm-value">{rdv?.date && formatDate(rdv?.date)}</span>
                            </p>
                            <p>
                                <span className="sm-title">Heure :</span>&nbsp;
                                <span className="sm-value">{rdv?.heure_de_debut && formatTime(rdv?.heure_de_debut) } - {rdv?.heure_de_fin && formatTime(rdv?.heure_de_fin)}</span>
                            </p>
                            <p className='d-flex align-items-end gap-1'>
                                <span className="sm-title">Format :</span>&nbsp;
                                <span className="sm-value">
                                {!!rdv?.format && rdv?.format?.type_format === "audio" && (
                                <MdPhone style={{ color: '#22BBC0', fontSize: 22 }} />)
                                }
                                {!!rdv?.format && rdv?.format?.type_format === "video" && (
                                <PiVideoCameraFill style={{ color: '#22BBC0', fontSize: 22 }} />)
                                }
                                {!!rdv?.format && rdv?.format?.type_format === "texto" && (
                                <BsFillChatDotsFill style={{ color: '#F76F39', fontSize: 22 }} />)
                                }
                                {!!rdv?.format && rdv?.format?.type_format === "domicile" && (
                                <IoHomeOutline style={{ color: '#22BBC0', fontSize: 22 }} />)
                                }
                                </span>
                            </p>
                            <p>
                                <span className="sm-title">Description :</span>&nbsp;
                                <span className="sm-value">
                                    {rdv?.format?.description}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default DetailRdv;

